import React from 'react';
import PropTypes from 'prop-types';

import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';

const Transition = ({
  type,
  show,
  customClass,
  children,
}) => {
  const components = {
    collapse: Collapse,
    fade: Fade,
    slide: Slide,
  };

  const applyTransition = () => {
    const args = (type === 'slide')
      ? { direction: 'up', mountOnEnter: true, unmountOnExit: true }
      : {};

    const Wrap = components[type];

    return <Wrap in={show} { ...args }>{children}</Wrap>;
  };

  return (
    <div className={`gt-transition ${customClass}`}>
      {applyTransition()}
    </div>
  );
};

export { Transition };

Transition.propTypes = {
  type: PropTypes.string.isRequired,
  show: PropTypes.bool,
  customClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

Transition.defaultProps = {
  show: false,
  customClass: '',
};