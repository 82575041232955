import React from 'react';
import { Config } from '../../../services/Services';

import './SpecialsBanner.scss';

export const SpecialsBanner = () => (
  <div className="mp-specials-banner">
    <div className="mp-specials-banner-wrap">
      <div className="mp-specials-banner-1" />
      <div className="mp-specials-banner-2">
        <span
          role="button"
          className="explore-btn"
          onClick={() => location.href = `${Config.get('BaseUrl')}/special-offer`}
        >
          Istraži ponudu
        </span>
      </div>
    </div>
  </div>
);
