import React from 'react';

import { CLIENT_BANNERS } from '../../../../constants/Constants';

import './SidebarBanners.scss';

export const SidebarBanners = () => {
  const { MERIDIAN_BET } = CLIENT_BANNERS;
  const { GIGATRON } = CLIENT_BANNERS;
  const { STRANI_JEZICI } = CLIENT_BANNERS;
  const renderBanner = (bannerClass, url) => (
    <div className={`banner ${bannerClass}`}>
      { url
        ? (
          <a target="_blank" href={url}>
            <span className="banner-img" />
          </a>
        )
        : <span className="banner-img" />
      }
    </div>
  );

  return (
    <div className="sidebar-banners">
      { renderBanner(MERIDIAN_BET.class, MERIDIAN_BET.link) }
      { renderBanner(GIGATRON.class, GIGATRON.link) }
      { renderBanner(STRANI_JEZICI.class, STRANI_JEZICI.link) }
    </div>
  );
};
