import React, { useEffect } from 'react';

import { connect } from "react-redux";
import * as productActions from '../../../state/product/actions';

import { Config, User } from '../../../services/Services';
import { PRODUCT_LEVELS } from '../../../constants/Constants';
import { Loader } from '../_reusable/Loader/Loader';

import './MiniFavorites.scss';

const _MiniFavorites = ({ loading, favorites, loadFavorites }) => {
  const loggedIn = User.loggedIn();
  const gtUrl = Config.get('BaseUrl');
  const level = PRELOAD_DATA.level;

  useEffect(() => {
    if (loggedIn) {
      if (level && level === PRODUCT_LEVELS.FAVORITES) {
        return;
      }
      loadFavorites();
    }
  }, []);

  const goToFavorites = () => {
    if (!loggedIn) { return; }
    location.href = `${gtUrl}/favorites`;
  };

  return (
    <div
      role="button"
      className="hu-item gt-mini-favorites mini-favorites"
      onClick={goToFavorites}
    >
      <div className="mini-favorites__icon">
        <span className="gt-icon gt-icon-heart" />
        { loggedIn
          ? (
            <span className={`mini-favorites__items ${loading ? 'loading' : ''}`}>
              { loading
                ? <Loader type="fadingCircle" />
                : favorites.length
              }
            </span>
          )
          : ''
        }
      </div>
      <span className="mini-favorites__text">Omiljeno</span>
    </div>
  );
};

const mapStateToProps = ({ product }) => ({
  loading: product.loadingFavorites,
  favorites: product.favorites,
});

const mapDispatchToProps = dispatch => ({
  loadFavorites: () => dispatch(productActions.loadFavorites()),
});

const MiniFavorites = connect(mapStateToProps, mapDispatchToProps)(_MiniFavorites);

export { MiniFavorites };