import React from 'react';
import PropTypes from 'prop-types';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';


const ScrollBar = ({
  className, scrollX, scrollY, children,
}) => {
  const options = {
    suppressScrollX: !scrollX,
    suppressScrollY: !scrollY,
  };

  return (
    <PerfectScrollbar options={options} className={className}>
      { children }
    </PerfectScrollbar>
  );
};

ScrollBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  scrollX: PropTypes.bool,
  scrollY: PropTypes.bool,
};
ScrollBar.defaultProps = {
  children: null,
  className: '',
  scrollX: false,
  scrollY: false,
};

export { ScrollBar };
