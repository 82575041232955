import React from 'react';
import PropTypes from 'prop-types';

const ItemsPerPage = ({ curr, onChange }) => (
  <div className="option-items-per-page">
    <span
      className={`${(curr === 10) && 'active'}`}
      onClick={() => onChange(10)}
    >
      10
    </span>
    <span
      className={`${(curr === 20) && 'active'}`}
      onClick={() => onChange(20)}
    >
      20
    </span>
    <span
      className={`${(curr === 30) && 'active'}`}
      onClick={() => onChange(30)}
    >
      30
    </span>
    <span
      className={`${(curr === 50) && 'active'}`}
      onClick={() => onChange(50)}
    >
      50
    </span>
  </div>
);

ItemsPerPage.propTypes = {
  curr: PropTypes.number,
  onChange: PropTypes.func,
};

ItemsPerPage.defaultProps = {
  curr: 0,
  onChange: f => f,
};

export { ItemsPerPage };
