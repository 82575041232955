import React, { useState } from 'react';
import PropTypes from 'prop-types';
import fileDownload from 'js-file-download';

import { User } from '../../../../../services/Services';
import { Tooltip } from '../../../../components/_reusable/Tooltip/Tooltip';
import { Loader } from '../../../../components/_reusable/Loader/Loader';
import { ProductCommunicator } from '../../../../../Communicators/ProductCommunicator';

import './Attachment.scss';

const isAdmin = User.isAdmin();

export const Attachment = ({ file, detachFile }) => {
  const [processing, setProcessing] = useState(null);

  const downloadFile = async () => {
    if (processing) { return; }

    setProcessing('download');
    const blobData = await ProductCommunicator.downloadFile(file.id);
    fileDownload(blobData, file.fileName, file.mimeType);
    setProcessing(null);
  };

  const removeFile = async () => {
    if (processing) { return; }

    setProcessing('delete');
    await detachFile(file.id);
    setProcessing(null);
  };

  const downloadProcessing = processing && processing === 'download';
  const deleteProcessing = processing && processing === 'delete';

  return (
    <div className="attachment">
      <div className="attachment__main">
        <span className={`file-type ${file.extension}`} />
        <span className="file-name">{file.title}</span>
      </div>
      <div className={`attachment__actions ${processing ? 'disabled' : ''}`}>
        { isAdmin && (
          <Tooltip title="Obriši">
            <div role="button" className="action-remove" onClick={removeFile}>
              { deleteProcessing
                ? <Loader type="fadingCircle" />
                : <span className="gt-icon-close-circle" />
              }
            </div>
          </Tooltip>
        )}
        <Tooltip title="Preuzmi">
          <div role="button" className="action-download" onClick={downloadFile}>
            { downloadProcessing
              ? <Loader type="fadingCircle" />
              : <span className="gt-icon-download" />
            }
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

Attachment.propTypes = {
  file: PropTypes.objectOf(PropTypes.any),
  detachFile: PropTypes.func,
};

Attachment.defaultProps = {
  file: {},
  detachFile: f => f,
};
