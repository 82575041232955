import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { User, Config, Format } from '../../../../services/Services';
import { Dialog } from '../../_reusable/Dialog/Dialog';
import { Button } from '../../_reusable/Button/Button';
import { InputField } from '../../_reusable/Input/Input';
import { Transition } from '../../_reusable/Transition/Transition';
import { Loader } from '../../_reusable/Loader/Loader';
import { QuantitySetter } from '../../QuantitySetter/QuantitySetter';
import { ProductTags } from '../../ProductTags/ProductTags';

import './ProductQuickView.scss';

const ProductQuickView = ({
  show,
  onClose,
  data,
  processing,
  notify,
  purchase,
  handleFavorite,
  favoriting,
}) => {
  const NO_PICTURE_PATH = Config.get('noPicturePath');
  const logged = User.loggedIn();
  const {
    id, name, code, imagePath, price, priceWithVat, vat, stock, unit, categoryId, subCategoryId, purpose, favorite,
  } = data;

  const [quantity, setQuantity] = useState(1);

  const statusClass = (quantity && quantity > 0) ? '' : 'empty';

  const updateQuantity = (val) => {
    let newQty = 0;

    if (val) {
      newQty = (val < 0) ? quantity : val;
    }
    setQuantity(newQty);
  };

  const closePreview = () => {
    if (favoriting) { return; }
    setQuantity(0);
    onClose();
  };

  const addProductToCart = async () => {
    if (quantity !== 0) {
      await purchase(quantity);
      setQuantity(0);
    }
  };

  const handleFavoriteStatus = async () => {
    if (!favoriting) {
      await handleFavorite(id, favorite);
    }
  };

  const renderInfoItem = (itemClass, itemVal, itemLabel = null) => (
    <div className={`product-info-item ${itemClass}`} >
      { itemLabel && <span className="product-info-item-label">{`${itemLabel}`}</span> }
      { itemVal && <span className="product-info-item-value">{itemVal}</span> }
    </div>
  );

  const renderInfoItemPrice = () => (
    <div className="product-info-item price" >
      <span className="item-value without-vat">{Format.toCurrency(price)}</span>
      <span className="item-value with-vat">{`(Sa PDV: ${Format.toCurrency(priceWithVat)})`}</span>
    </div>
  );

  const renderInfoItemStock = () => {
    if (stock && stock > 0) {
      return renderInfoItem('stock', `${stock} ${unit}`, 'Na stanju:');
    }
    return renderInfoItem('no-stock', null, 'Nema na stanju');
  };

  const renderBuyNotification = () => show && (
    <Transition type="collapse" customClass="product-info-notification" show={notify} >
      <div className="notification-body">
        <div className="notification-body-message">
          <span className="gt-icon gt-icon-info" />
          <span className="message">Uspešno ste dodali proizvod u korpu</span>
        </div>
      </div>
    </Transition>
  );

  const renderAddToCart = () => {
    const onStock = !!(stock && stock > 0);

    return (
      <div className="product-info-add-to-cart">
        <QuantitySetter
          customClass="product-info-add-to-cart-inputs"
          disabled={processing}
          quantity={quantity}
          updateQuantity={updateQuantity}
        />
        <Button
          customClass="add-to-cart-btn"
          label={(
            !processing 
              ? <span className="gt-icon gt-icon-add-to-cart"></span>
              : <Loader type="fadingCircle" />
          )}
          onClick={addProductToCart}
          disabled={processing}
        />
        { renderBuyNotification() }
      </div>
    );
  };

  const renderFavoriteSection = () => (
    <div className="product-info-favorite">
      <div
        role="button"
        className={`product-info-favorite-btn ${favoriting ? 'loading' : ''}`}
        onClick={handleFavoriteStatus}
      >
        { !favoriting
            ? <span className={`gt-icon gt-icon-${favorite ? 'broken-' : '' }heart`} />
            : <Loader type="fadingCircle" />
        }
        <span className="product-info-favorite-text">
          { favorite ? 'Ukloni iz omiljenog' : 'Dodaj u omiljeno' }
        </span>
      </div>
    </div>
  );

  const formCategoryString = () => {
    const category = PRELOAD_DATA.categories.find(cat => cat.id === categoryId);
    const subcategoryHR = category.subcategories.find(cat => cat.id === subCategoryId).name

    return `${category.name} / ${subcategoryHR}`;
  };

  return (
    <Dialog
      title={name}
      show={show}
      onClose={closePreview}
    >
      <div className={`gt-product-quick-view ${statusClass}`}>
        <div className="gt-product-quick-view-section-1">
          <div className="img-wrapper">
            <img src={imagePath || NO_PICTURE_PATH} alt="product image" />
            <ProductTags product={data} />
          </div>
          <div className="product-info">
            { logged && renderInfoItemPrice() }
            { logged && renderAddToCart() }
            { logged && renderFavoriteSection() }
            { renderInfoItem('category', formCategoryString(), 'Kategorija:') }
            { logged && code && renderInfoItem('code', code, 'Šifra:') }
            { logged && vat && renderInfoItem('vat', `${vat}%`, 'PDV:') }
            { logged && unit && renderInfoItem('unit', unit, 'Jedinica mere:') }
            { !logged && purpose && renderInfoItem('purpose', purpose, 'Opis:') }
          </div>
        </div>
        { logged && (
          <div className="gt-product-quick-view-section-2">
            { purpose && purpose }
          </div>
        )}
      </div>
    </Dialog>
  );
};

ProductQuickView.propTypes = {
  show: PropTypes.bool,
  processing: PropTypes.bool,
  notify: PropTypes.bool,
  onClose: PropTypes.func,
  purchase: PropTypes.func,
  handleFavorite: PropTypes.func,
  favoriting: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

ProductQuickView.defaultProps = {
  show: false,
  processing: false,
  notify: false,
  onClose: f => f,
  purchase: f => f,
  handleFavorite: f => f,
  favoriting: false,
};

export { ProductQuickView };
