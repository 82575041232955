import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import { FormContext } from './Form';
import { Button } from '../Button/Button';

const FormFooter = ({
  actions,
  onSubmit,
  submitLabel,
}) => {
  const {
    model,
    test,
    validate,
    setLoading,
    clearFormMessages,
    setFormMessage,
    clearForm,
  } = useContext(FormContext);

  const restOfFormProps = {
    clearFormMessages,
    setFormMessage,
    clearForm,
  };

  const renderActionButtons = () => (
    <Fragment>
      { actions.map((act) => {
        return (act.type === 'button')
          ? (
            <Button
              key={act.label}
              label={act.label}
              onClick={act.action ? act.action : clearForm}
            />
          )
          : (
            <span
              key={act.label}
              className="link-btn"
              onClick={act.action ? act.action : clearForm}
            >
              {act.label}
            </span>
          );
      })}
    </Fragment>
  );

  const onSubmitClick = async () => {
    if (!validate()) {
      return;
    }
    clearFormMessages();
    setLoading(true);
    const result = await onSubmit(model, restOfFormProps);
    setLoading(false);
  };

  const onCancelClick = async () => {
    clearFormMessages();
    clearForm();
  };

  return (
    <div className="gt-form-footer">
      <Button
        label={submitLabel}
        onClick={onSubmitClick}
      />

      { renderActionButtons() }
    </div>
  );
};

FormFooter.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    action: PropTypes.func,
  })),
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
};

FormFooter.defaultProps = {
  actions: [],
  onSubmit: f => f,
  submitLabel: 'Submit',
};

export { FormFooter };