import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';

import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';

import { Button } from '../Button/Button';

import './Dialog.scss';

const DialogTransition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog = ({
  size,
  show,
  onClose,
  title,
  customClass,
  children,
  footerClose,
  customFooter,
  disableBackdropClick,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(size));

  return (
    <div className={`gt-dialog ${customClass}`} >
      <MuiDialog
        fullScreen={fullScreen}
        open={show}
        onClose={onClose}
        TransitionComponent={DialogTransition}
        aria-labelledby="responsive-dialog-title"
        disableBackdropClick={disableBackdropClick}
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="gt-dialog-title">{ title }</span>
          { onClose ? (
            <span
              role="button"
              className="gt-icon gt-icon-close-circle"
              onClick={onClose}
            />
          ) : null}
        </DialogTitle>
        <DialogContent>
          { children }
        </DialogContent>
        { customFooter && (
          <DialogActions>
            { footerClose && <Button label="Zatvori" onClick={onClose} /> }
            { customFooter() }
          </DialogActions>
          )}
      </MuiDialog>
    </div>
  );
};

Dialog.propTypes = {
  size: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  customClass: PropTypes.string,
  footerClose: PropTypes.bool,
  customFooter: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  disableBackdropClick: PropTypes.bool,
};

Dialog.defaultProps = {
  size: 'sm',
  show: false,
  onClose: f => null,
  title: '',
  customClass: '',
  footerClose: false,
  customFooter: f => f,
  children: null,
  disableBackdropClick: false,
};

export { Dialog };
