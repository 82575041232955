import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Storage } from '../../../../services/LocalStorage';
import { Tooltip } from '../Tooltip/Tooltip';

import './LayoutSwitcher.scss';

const LayoutSwitcher = ({
  name, onLayoutChange, isListView, disabled,
}) => {
  const onGridViewClick = () => {
    Storage.set(name, false);
    onLayoutChange(false);
  };

  const onListViewClick = () => {
    Storage.set(name, true);
    onLayoutChange(true);
  };

  return (
    <div className={`gt-layout-switcher ${disabled && 'disabled'}`}>
      <Tooltip title="Prikaz: KARTICE">
        <div
          className={`icon-wrap ${!isListView && 'active'}`}
          onClick={onGridViewClick}
        >
          <span className="gt-icon gt-icon-grid" />
        </div>
      </Tooltip>
      <Tooltip title="Prikaz: LISTA">
        <div
          className={`icon-wrap ${isListView && 'active'}`}
          onClick={onListViewClick}
        >
          <span className="gt-icon gt-icon-list" />
        </div>
      </Tooltip>
    </div>
  );
};

LayoutSwitcher.propTypes = {
  name: PropTypes.string.isRequired,
  isListView: PropTypes.bool,
  onLayoutChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

LayoutSwitcher.defaultProps = {
  isListView: false,
  disabled: false,
};

export { LayoutSwitcher };
