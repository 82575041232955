import React from 'react';
import PropTypes from 'prop-types';

import { InputField, FIELD_STYLE_OUTLINED } from '../../../components/_reusable/Input/Input';

const SearchByName = ({ curr, onChange }) => {
  return (
    <div className="option-search-by-name">
      <InputField
        fieldStyle={FIELD_STYLE_OUTLINED}
        label="Termin pretrage"
        value={curr || ''}
        onChange={val => onChange(val)}
      />
    </div>
  );
};

SearchByName.propTypes = {
  curr: PropTypes.string,
  onChange: PropTypes.func,
};

SearchByName.defaultProps = {
  curr: null,
  onChange: f => f,
};

export { SearchByName };
