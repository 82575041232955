import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Tooltip, TooltipPlacements } from '../../_reusable/Tooltip/Tooltip';
import { Loader } from '../../_reusable/Loader/Loader';

import './UserAccount.scss';

const UserAccount = ({
  customClass,
  data,
  onDelete,
  onEdit,
}) => {
  const {
    id, username, password, email, customer,
  } = data;

  const renderFooter = () => (
    <div className="gt-user-acc-contents-footer">
      <div className="user-options">
        <div className="user-btn edit-user-btn">
          <Tooltip
            title="Izmeni podatke"
            placement={TooltipPlacements.BOTTOM_END}
          >
            <span
              className="gt-icon gt-icon-edit"
              role="button"
              onClick={() => onEdit(data)}
            />
          </Tooltip>
        </div>
        <div className="user-btn delete-user-btn">
          <Tooltip
            title="Obriši korisnika"
            placement={TooltipPlacements.BOTTOM_END}
          >
            <span
              className="gt-icon gt-icon-delete"
              role="button"
              onClick={() => onDelete(data)}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );

  const renderDataItem = (customClass, label, value, href = null) => (
    <div className={`data-item ${customClass}`}>
      <span className="data-item__label">{label}</span>
      { href
        ? <a className="data-item__value" href={href}><span className="data-item__value">{value}</span></a>
        : <span className="data-item__value">{value}</span>
      }
    </div>
  );

  return (
    <div className="gt-user-acc">
      <div className={`gt-user-acc-contents`}>
        <div className="gt-user-acc-contents-body">
          { renderDataItem('customer', 'Komitent', customer.name) }
          <div className="gt-user-acc-contents-body-rest">
            { username && renderDataItem('username', 'Korisničko ime', username) }
            { password && renderDataItem('password', 'Lozinka', password) }
            { email && renderDataItem('email', 'E-mail', email, `mailto:${email}`) }
          </div>
        </div>
        { renderFooter() }
      </div>
    </div>
  );
};

UserAccount.propTypes = {
  customClass: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

UserAccount.defaultProps = {
  customClass: '',
  addToCart: f => f,
  onDelete: f => f,
  onEdit: f => f,
};

export { UserAccount };
