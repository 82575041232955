import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { User } from '../../../services/Services';
import { SectionHeader } from '../../components/SectionHeader/SectionHeader';
import { CarouselProduct } from '../../components/Product/CarouselProduct/CarouselProduct';
import { Carousel } from '../../components/_reusable/Carousel/Carousel';

import './SimilarProducts.scss';

const SimilarProductsComponent = ({
  customClass,
  similar,
}) => {
  const logged = User.loggedIn();
  const [slides, setSlides] = useState(5);
  const [products, setProducts] = useState(null);

  const prepareToAdd = (num) => Array(num).fill(null).map((dummy, index) => ({
    dummy: true,
    id: `dummy-${index}`,
  }));

  const populateProducts = (visible) => {
    let display = null;

    if (similar.length < visible) {
      const toAdd = prepareToAdd(visible - similar.length);
      display = [...similar].concat(toAdd);
    } else {
      display = similar.slice(0, 7);
    }

    setSlides(visible);
    setProducts(display);
  };

  const onResize = (e) => {
    const width = window.innerWidth;

    if (width >= 1000) {
      populateProducts(5);
    } else if (width >= 850 && width < 1000) {
      populateProducts(4);
    } else if (width >= 600 && width < 850) {
      populateProducts(3);
    } else if (width >= 450 && width < 600) {
      populateProducts(2);
    } else {
      populateProducts(1);
    }
  };

  useEffect(() => {
    if (!similar.length) { return; }
    onResize();
  }, [similar]);

  const renderProducts = () => products.map(product => (
    <div key={product.id} className={`similar-products__product ${logged ? 'full' : ''}`}>
      {
        product.dummy
          ? <div className="dummy-product-placeholder" />
          : <CarouselProduct data={product} />
      }
    </div>
  ));

  return products
    ? (
      <div className={`similar-products ${customClass}`}>
        <SectionHeader title="Slični proizvodi" />
        <Carousel
          className="similar-products-carousel"
          customClass="mp-similar section"
          slidesToShow={slides}
          onResize={onResize}
        >
          { renderProducts() }
        </Carousel>
      </div>
    )
    : '';
};

SimilarProductsComponent.propTypes = {
  customClass: PropTypes.string,
  similar: PropTypes.arrayOf(PropTypes.any),
};

SimilarProductsComponent.defaultProps = {
  customClass: '',
  similar: [],
};

export { SimilarProductsComponent };
