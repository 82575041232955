import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { User } from '../../../../../services/Services';
import { SectionHeader } from '../../../../components/SectionHeader/SectionHeader';
import { Button, BUTTON_ICONS } from '../../../../components/_reusable/Button/Button';
import { FileUpload } from '../FileUpload/FileUpload';
import { Attachment } from '../Attachment/Attachment';

import './ProductFiles.scss';

const isAdmin = User.isAdmin();

export const ProductFilesComponent = ({
  id, customClass, files, uploadFiles, removeFile,
}) => {
  const [open, setOpen] = React.useState(false);

  const upload = (
    <div className="uploader">
      <Button
        label="Dodaj dokument"
        size="small"
        onClick={() => setOpen(true)}
        icon={BUTTON_ICONS.NEW}
      />
      <FileUpload
        open={open}
        closeDialog={() => setOpen(false)}
        upload={uploadFiles}
      />
    </div>
  );

  const renderAttachments = () => (
    <div className="attachments">
      {files.map(file => (
        <Attachment key={file.id} file={file} detachFile={removeFile} />
      ))}
    </div>
  );

  const userView = (id && files?.length) && (
    <div className={`product-files ${customClass}`}>
      <SectionHeader title="Povezani dokumenti" />
      { renderAttachments() }
    </div>
  );

  const adminView = id && (
    <div className={`product-files ${customClass}`}>
      <SectionHeader title="Povezani dokumenti" />
      { files?.length
        ? renderAttachments()
        : <span className="empty">Trenutno nema povezanih dokumenata.</span>
      }
      { upload }
    </div>
  );

  return isAdmin ? adminView : userView;
};

ProductFilesComponent.propTypes = {
  id: PropTypes.number,
  customClass: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.any),
  uploadFiles: PropTypes.func.isRequired,
};

ProductFilesComponent.defaultProps = {
  id: null,
  customClass: '',
  files: [],
};
