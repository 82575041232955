import React from 'react';

import { SlideShow } from '../../sections/SlideShow/SlideShow';
import { SpecialsBanner } from '../../sections/SpecialsBanner/SpecialsBanner';
import { Highlights } from '../../sections/Highlights/Highlights';
import { SidebarBanners } from '../../sections/Banners/SidebarBanners/SidebarBanners';
import { MainAbout } from '../../sections/MainAbout/MainAbout';

import './MainPage.scss';

export const MainPage = () => {
  return (
    <div className="page main-page">
      <div className="main-page-first intro">
        <SlideShow customClass="mp-slide-show" />
        <SpecialsBanner />
      </div>

      <div className="main-page-second about">
        <MainAbout />
      </div>

      <div className="main-page-third specials">
        <SidebarBanners />
        <Highlights />
      </div>
    </div>
  )
};
