import React from 'react';

import MuiDrawer from '@material-ui/core/Drawer';

const Drawer = props => (
  <div className="gt-drawer">
    <MuiDrawer {...props} >
      {props.children}
    </MuiDrawer>
  </div>
); 

export { Drawer };