import { Base } from './Base';
import { http } from "../services/HttpService";

class _OrderCommunicator extends Base {
  constructor() {
    super('/order');
  }

  async getDeliveryDates() {
    return await http.jsonDelete(`${this.url}/delivery-dates`);
  }

  async makeOrder(dayOffset, comment, outletId) {
    return await http.jsonPost(`${this.url}/from-cart`, {
      dayOffset,
      comment,
      outletId,
    });
  }

  async getOrderHistory() {
    return await http.get(`${this.url}/history`);
  }
}

export const OrderCommunicator = new _OrderCommunicator();
