export * from './view/sections/Header/Header';
export * from './view/sections/Footer/Footer';

export * from './view/pages/MainPage/MainPage';
export * from './view/pages/LoginPage/LoginPage';
export * from './view/pages/CartPage/CartPage';
export * from './view/pages/ProductsPage/ProductsPage';
export * from './view/pages/ProductPage/ProductPage';
export * from './view/pages/ContactPage/ContactPage';
export * from './view/pages/AboutPage/AboutPage';
export * from './view/pages/HistoryPage/HistoryPage';
export * from './view/pages/AdminPage/AdminPage';
