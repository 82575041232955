import * as Actions from './types';

const defaultState = {
  list: [],
  loading: false,
  loadingFavorites: false,
  favorites: [],
  similar: [],
  files: [],
};

const initStateGenerator = initial => JSON.parse(JSON.stringify(initial));

export default (state = initStateGenerator(defaultState), action) => {
  const updateState = {
     [Actions.LOADING_PRODUCTS]: { loading: action.payload },
     [Actions.POPULATE_PRODUCTS_LIST]: { list: action.payload },
     [Actions.PRODUCT_WITH_ID]: { list: [action.payload] },
     [Actions.LOADING_FAVORITES]: { loadingFavorites: action.payload },
     [Actions.POPULATE_FAVORITES]: { favorites: action.payload },
     [Actions.SIMILAR_PRODUCTS]: { similar: action.payload },
     [Actions.PRODUCT_FILES]: { files: action.payload },
  };

  return {
    ...state,
    ...updateState[action.type],
  };
};
