import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';
import * as CONSTANTS from '../InputConstants';

import '../Input.scss';

const InputField = ({
  fieldStyle,
  placeholder,
  label,
  value,
  type,
  onChange,
  onKeyPress,
  autoFocus,
  onBlur,
  disabled,
  error,
  shrinkLabel,
  multiline,
  rows,
}) => {
  const labelProps = shrinkLabel ? {shrink: true} : {};

  const returnNakedInput = () => (
    <InputBase
      className="simple-input"
      placeholder={placeholder}
      onChange={onChange}
      autoFocus={autoFocus}
      onKeyPress={onKeyPress}
      onBlur={onBlur}
      value={value}
    />
  );

  const returnOutlinedInput = () => (
    <TextField
      placeholder={placeholder}
      label={label}
      variant="outlined"
      type={type}
      onChange={event => onChange(event.target.value)}
      onBlur={event => onBlur(event.target.value)}
      onKeyPress={onKeyPress}
      value={value}
      disabled={disabled}
      error={error}
      InputLabelProps={labelProps}
      multiline={multiline}
      rows={rows}
    />
  );

  const renderField = () => {
    switch (fieldStyle) {
      case CONSTANTS.FIELD_STYLE_NAKED:
        return returnNakedInput();
      case CONSTANTS.FIELD_STYLE_OUTLINED:
        return returnOutlinedInput();
      default:
        return '';
    }
  };

  return (
    <div className={`gt-input ${fieldStyle}`}>
      {renderField()}
    </div>
  );
};

InputField.propTypes = {
  fieldStyle: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  autoFocus: PropTypes.bool,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  shrinkLabel: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
};

InputField.defaultProps = {
  fieldStyle: 'naked',
  placeholder: '',
  label: '',
  value: '',
  onChange: f => f,
  onBlur: f => f,
  defaultValue: '',
  onKeyPress: f => f,
  autoFocus: false,
  type: 'text',
  disabled: false,
  error: false,
  shrinkLabel: false,
  multiline: false,
  rows: 1,
};

export { InputField };
