const preloadedDeliveryDates = PRELOAD_DATA.deliveryDates;

class OrderService {
    getDeliveryDates = async () => {
        if (preloadedDeliveryDates){
            return preloadedDeliveryDates;
        }

        return await OrderCommunicator.getDeliveryDates();
    }
}

export const Order = new OrderService();
