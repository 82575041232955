import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

import { User } from '../../../services/Services';
import * as OrderActions from "../../../state/order/actions";
import { SelectField, FIELD_STYLE_OUTLINED } from '../_reusable/Input/Input';

const _DeliveryAddress = ({
  value, disabled, setDeliveryOutlet, finished, overview,
}) => {
  const [options, setOptions] = useState([]);

  const displayValue = (val) => (
    finished ? overview : val
  );

  const loadOptions = () => User.get('outlets').map(outlet => ({
    value: outlet.id,
    label: `${outlet.name}, ${outlet.address}, ${outlet.city}`,
  }));

  useEffect(() => {
    setOptions(loadOptions());
  }, []);

  const getValue = () => {
    const realVal = displayValue(value);
    let selected = options.find(item => item.value === realVal);
    selected = selected ? selected.value : '';

    return selected;
  };

  const onChange = (event) => {
    const toSet = options.find(opt => opt.value === event.target.value);
    const selected = toSet ? toSet.value : null;

    setDeliveryOutlet(selected);
  };

  return (
    <SelectField
      fieldStyle={FIELD_STYLE_OUTLINED}
      label="Adresa isporuke"
      value={getValue()}
      options={options}
      onChange={onChange}
      disabled={disabled || finished}
    />
  );
};

_DeliveryAddress.propTypes = {
  value: PropTypes.number,
  setDeliveryOutlet: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  finished: PropTypes.bool,
  overview: PropTypes.number,
};

_DeliveryAddress.defaultProps = {
  value: null,
  disabled: false,
  finished: false,
  overview: null,
};

const mapStateToProps = ({ order }, ownProps) => ({
  value: order.outlet,
  disabled: ownProps.disabled,
  finished: ownProps.finished,
  overview: ownProps.overview,
});

const mapDispatchToProps = dispatch => ({
  setDeliveryOutlet: outletId => dispatch(OrderActions.setDeliveryOutlet(outletId)),
});

const DeliveryAddress = connect(mapStateToProps, mapDispatchToProps)(_DeliveryAddress);

export { DeliveryAddress };
