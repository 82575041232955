import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { uniq } from 'lodash';
import { Field } from '../../../_reusable/Form/Field';
import { SelectField, FIELD_STYLE_OUTLINED } from '../../../_reusable/Input/Input';

const FirstLetter = ({
  value, updateValue, name, model, customers,
}) => {
  const [letters, setLetters] = useState([]);

  const loadLetters = () => {
    if (!customers.length) { return; }

    let firsts = uniq(customers.map(single => single.name.substring(0, 1).toUpperCase()));

    firsts = firsts.map(first => ({
      value: first,
      label: first,
    }));

    setLetters(firsts);
  };

  useEffect(() => {
    loadLetters();
  }, []);

  const onLetterChange = (letter) => {
    updateValue(name, letter);
  };

  return (
    <SelectField
      fieldStyle={FIELD_STYLE_OUTLINED}
      label="Početni karakter naziva komitenta"
      value={value || ''}
      onChange={e => onLetterChange(e.target.value)}
      options={letters}
    />
  );
};

FirstLetter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateValue: PropTypes.func,
  name: PropTypes.string,
  model: PropTypes.objectOf(PropTypes.any),
  customers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

FirstLetter.defaultProps = {
  value: null,
  updateValue: f => f,
  name: '',
  model: null,
};

export const FirstLetterField = ({ customers }) => (
  <Field
    name="first"
    component={props => <FirstLetter customers={customers} {...props} />}
  />
);

FirstLetterField.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.any).isRequired,
};
