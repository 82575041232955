import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import { Config } from '../../../services/Config';
import {
  InputField,
  SelectField,
} from '../_reusable/Input/Input';

import './SearchBox.scss';
import * as queryString from "query-string";

export const SELECT_DEFAULT_VALUE = 'default';

const SearchBoxWeb = ({
  categories,
}) => {
  const [term, setTerm] = useState('');
  const [category, setCategory] = useState(SELECT_DEFAULT_VALUE);

  useEffect(() => {
      const queryParams = queryString.parse(location.search);
      setTerm(queryParams.searchQuery || '');
      setCategory(queryParams.categoryId || SELECT_DEFAULT_VALUE);
  },[]);

  const prepareOptions = () => {
    const catOptions = categories.map(item => ({value: item.id, label: item.name}));
    catOptions.unshift({value: SELECT_DEFAULT_VALUE, label: 'Izaberite kategoriju'});
    return catOptions;
  };

  const doSearch = () => {
    if(term.length < 3) return;
    const catId = category === SELECT_DEFAULT_VALUE ? null : category;
    location.href = `${Config.get('BaseUrl')}/search?searchQuery=${term}&categoryId=${catId || ''}`;
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      e.target.setAttribute("disabled", "disabled");
      e.preventDefault();
      doSearch();
    }
  };

  return (
    <div className="header-search">
      <SelectField
        fieldStyle="naked"
        value={category || SELECT_DEFAULT_VALUE}
        onChange={e => setCategory(e.target.value)}
        options={prepareOptions()}
      />
      <InputField
        fieldStyle="naked"
        placeholder="Pretraži prodavnicu..."
        value={term}
        onKeyPress={handleEnter}
        onChange={e => setTerm(e.target.value)}
      />
      <div className="search-button" onClick={doSearch}>
        <span className="gt-icon-search" />
      </div>
    </div>
  );
};

export { SearchBoxWeb };

SearchBoxWeb.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.any),
};

SearchBoxWeb.defaultProps = {
  categories: [],
};
