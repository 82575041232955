import React from 'react';
import PropTypes from 'prop-types';

import { User } from '../../../../services/Services';
import { Form } from '../../_reusable/Form/Form';
import { FormFooter } from '../../_reusable/Form/FormFooter';
import { EmailField } from './fields/EmailField';
import { TextField } from './fields/TextField';
import { UserCommunicator } from '../../../../Communicators/Communicators';

export const RegistrationForm = () => {
  const onSubmit = async (model, formProps) => {
    const result = await UserCommunicator.register({ ...model });

    if (result.error) {
      formProps.setFormMessage('error', `Greška: ${result.error.message}`);
    } else {
      formProps.clearForm();
      formProps.setFormMessage('info', 'Vaš zahtev za registraciju je prihvaćen.');
    }
    return result;
  };

  const FORM_ACTIONS = [
    {
      type: 'link',
      label: 'Obriši formu',
    },
  ];

  const renderFooter = () => {
    return (
      <FormFooter
        actions={FORM_ACTIONS}
        onSubmit={onSubmit}
        submitLabel="Registruj me"
      />
    );
  };

  return (
    <Form
      footer={renderFooter}
      customClass="forgot-pass-form"
    >
      <TextField fieldName="company" fieldLabel="Naziv firme *" />
      <TextField fieldName="vat_number" fieldLabel="PIB *" />
      <TextField fieldName="address" fieldLabel="Ulica i broj *" />
      <TextField fieldName="city" fieldLabel="Mesto *" />
      <TextField fieldName="firstName" fieldLabel="Ime *" />
      <TextField fieldName="lastName" fieldLabel="Prezime *" />
      <TextField fieldName="phone" fieldLabel="Kontakt telefon *" />
      <TextField fieldName="username" fieldLabel="Željeno korisničko ime *" />
      <TextField fieldName="password" fieldLabel="Željena lozinka *" />
      <EmailField />
    </Form>
  );
};
