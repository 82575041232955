import * as types from './types';
import * as OrderTypes from '../order/types';
import { CartCommunicator } from "../../Communicators/CartCommunicator";

const setLoading = (isLoading) => (dispatch) => {
  dispatch({
    type: types.CART_LOADING,
    payload: isLoading,
  })
};

const setUpdating = (productId, status) => (dispatch, getState) => {
  const { updating } = getState().cart;

  const newVal = status
    ? [...updating, productId]
    : updating.filter(item => item !== productId);

  dispatch({
    type: types.CART_UPDATING,
    payload: newVal,
  })
};

export const loadCart = () => async (dispatch) => {

  dispatch(setLoading(true));
  const value = await CartCommunicator.fetchAll();
  value.contents = Object.values(value.contents);
  dispatch(setLoading(false));

  dispatch({
    type: types.CART_LOAD,
    payload: value,
  });
};

export const removeItem = (productId) => async (dispatch, getState) => {
  const { updating } = getState().cart;

  if (updating.length) { return; }

  dispatch(setUpdating(productId, true));

  const value = await CartCommunicator.removeItem(productId);
  value.contents = Object.values(value.contents);

  dispatch(setUpdating(productId, false));

  dispatch({
    type: types.CART_LOAD,
    payload: value,
  });
};

export const addItem = (productId, qty) => async (dispatch) => {
  dispatch(setLoading(true));
  const value = await CartCommunicator.addItem(productId, qty);
  value.contents = Object.values(value.contents);
  dispatch(setLoading(false));

  dispatch({
    type: types.CART_LOAD,
    payload: value,
  });
};

export const bulkAddItems = (products) => async (dispatch) => {
  const items = Object.keys(products).map(prod => ({
    productId: prod,
    qty: products[prod],
  }));

  dispatch(setLoading(true));
  const value = await CartCommunicator.bulkAddItems(items);
  value.contents = Object.values(value.contents);
  dispatch(setLoading(false));

  dispatch({
    type: types.CART_LOAD,
    payload: value,
  });
};

export const changeQty = (productId, qty) => async (dispatch, getState) => {
  const { updating } = getState().cart;
  if (updating.length) { return; }

  dispatch(setUpdating(productId, true));

  try {
    const value = await CartCommunicator.changeQty(productId, qty);
    value.contents = Object.values(value.contents);

    dispatch(setUpdating(productId, false));

    dispatch({ type: types.CART_LOAD, payload: value });
  } catch (httpError) {
    dispatch(setUpdating(productId, false));
    throw httpError;
  }
};

export const loadFromOrder = (orderId) => async (dispatch) => {
  dispatch(setLoading(true));
  const cart = await CartCommunicator.loadFromOrder(orderId);
  cart.contents = Object.values(cart.contents);
  console.log("LOADED CART", cart);
  dispatch(setLoading(false));

  dispatch({
    type: types.CART_LOAD,
    payload: cart,
  });
};

export const clear = (productId) => async (dispatch) => {

  dispatch(setLoading(true));
  const value = await CartCommunicator.clear(productId);
  dispatch(setLoading(false));

  dispatch({
    type: types.CART_LOAD,
    payload: value,
  });
};