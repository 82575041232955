import React, { Fragment, useState } from 'react';
import { connect } from "react-redux";
import { Drawer } from '../_reusable/Drawer/Drawer';
import { Button } from '../_reusable/Button/Button';
import { Loader } from '../_reusable/Loader/Loader';
import { ScrollBar } from '../_reusable/ScrollBar/ScrollBar';
import { Config, Format, User } from "../../../services/Services";
import * as CartActions from "../../../state/cart/actions";

import './MiniCart.scss';

const NO_PICTURE_PATH = Config.get('noPicturePath');

const _MiniCartMobile = ({
  contents,
  total,
  loading,
  updating,
  processing,
  loadCart,
  removeItem,
  clear,
}) => {
  const [show, setShow] = useState(false);
  const gtUrl = Config.get('BaseUrl');
  const loggedIn = User.loggedIn();
  const buttonNoGo = !!(updating.length || processing);
  const isLoading = loading || updating.length;

  const toggleShow = () => setShow(!show);

  const renderCartItem = (item) => {
    const {productId, name, imagePath, price, qty} = item;
    const changing = updating.includes(productId);

    return (
      <li key={`product-${productId}`} className={`product ${changing ? 'changing' : ''}`}>
        <a href="#" title={name} className="product-image">
          <img src={imagePath ?? NO_PICTURE_PATH} alt="product image" />
        </a>
        <div className="product-details">
          <a href="#" className="product-details__name">{name}</a>
          <div className="product-details__amounts">
            <span>{qty}</span>
            <span>x</span>
            <span>{Format.toCurrency(price)}</span>
          </div>
        </div>
        <span
          role="button"
          className="product-remove gt-icon-delete"
          onClick={() => !buttonNoGo && removeItem(productId)}
        />

        { changing && <Loader type="stretchingBars" customClass="product-loader" /> }
      </li>
    );
  };

  const renderNoUserCart = () => (
    <div className="mobile-cart-contents-no-user">
      <span>Morate biti ulogovani da biste videli ili menjali sadržaj korpe.</span>
    </div>
  );

  const renderEmptyCart = () => (
    <div className="mobile-cart-contents-empty">
      <span>Vaša korpa je prazna</span>
    </div>
  );

  const clearCart = () => {
    if (buttonNoGo) { return; }

    clear();
  };

  const goToCart = () => {
    if (buttonNoGo) { return; }

    location.href = `${gtUrl}/cart`;
  };

  const renderCart = () => {
    if (!contents.length) {
      return renderEmptyCart();
    }

    return (
      <Fragment>
        <ScrollBar className="mobile-cart-contents-list" scrollY>
          <ul>
              { contents.map(item => renderCartItem(item)) }
          </ul>
        </ScrollBar>
        <div className="mobile-cart-contents-bottom">
          <div className="mobile-cart-contents-subtotal">
            <span>Ukupno:</span>
            <span>{Format.toCurrency(total)}</span>
          </div>
          <div className="mobile-cart-contents-actions">
            <Button
              label="Korpa"
              onClick={goToCart}
            />
            <Button
              label="Obriši sve"
              onClick={clearCart}
            />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderContents = () => (
    <div className="mobile-cart-contents">
      { loggedIn ? renderCart() : renderNoUserCart() }
    </div>
  );

  const renderCartContents = () => (
    <div className="mobile-cart">
      <div className="mobile-cart-topbar">
        <span className="mobile-cart-topbar__title">Mini korpa</span>
        <span
          className="gt-icon gt-icon-close-circle"
          onClick={toggleShow}
        />
      </div>
      {renderContents()}
    </div>
  );

  return (
    <div className="gt-mini-cart mini-cart-mobile">
      <div className="mini-cart">
        <div
          className="menu-item box-item mini-cart__icon"
          onClick={toggleShow}
        >
          <span className="gt-icon-shopping-bag" />
          { loggedIn
            ? (
              <span className={`mini-cart__items ${isLoading ? 'loading' : ''}`}>
                { isLoading
                  ? <Loader type="fadingCircle" />
                  : contents.length
                }
              </span>
            )
            : ''
          }
        </div>
      </div>
      <Drawer open={show} onClose={toggleShow} anchor="right">
        { renderCartContents() }
      </Drawer>
    </div>
  );
};

const mapStateToProps = ({ cart, order }) => ({
  contents: cart.contents,
  total: cart.total,
  loading: cart.loading,
  updating: cart.updating,
  processing: order.processing,
});

const mapDispatchToProps = dispatch => ({
  loadCart: async () => dispatch(CartActions.loadCart()),
  removeItem: async (productId) => dispatch(CartActions.removeItem(productId)),
  clear: async () => dispatch(CartActions.clear()),
});

const MiniCartMobile = connect(mapStateToProps, mapDispatchToProps)(_MiniCartMobile);

export { MiniCartMobile }
