import numeral from 'numeral';
import moment from 'moment';

class ConfigService {
  constructor() {
    this.config = this.loadConfig();
  }

  loadConfig() { // eslint-disable-line
    numeral.register('locale', 'eu', {
      delimiters: {
        thousands: '.',
        decimal: ',',
      },
      abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
      },
      currency: {
        symbol: 'RSD',
      },
    });

    numeral.locale('eu');

    moment.locale('sr');

    return app_gth.config || {};
  }

  get(configPropName) {
    if (!this.hasConfig(configPropName)) {
      this.printMissingConfigurationPropInfo(configPropName);
      return null;
    }

    return this.config[configPropName];
  }

  hasConfig(configPropName) {
    return this.config[configPropName];
  }

  printMissingConfigurationPropInfo(configPropName) { // eslint-disable-line
    console.info(`There is no property "${configPropName}" in global config!`); // eslint-disable-line
  }
}

export const Config = new ConfigService();
