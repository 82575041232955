import React from "react";
import PropTypes from 'prop-types';

import TextField from "@material-ui/core/TextField";

import '../Input.scss';

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;

const FieldSet = ({ children, label, disabled }) => {
  return (
    <div className={`gt-fieldset ${disabled ? 'disabled' : ''}`}>
      <TextField
        variant="outlined"
        label={label}
        multiline
        InputLabelProps={{ shrink: true }}
        InputProps={{
          inputComponent: InputComponent
        }}
        inputProps={{ children: children }}
      />
    </div>
  );
};

FieldSet.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

FieldSet.defaultProps = {
  label: '',
  disabled: false,
  children: null,
};

export { FieldSet };
