import React from 'react';
import PropTypes from 'prop-types';

import MuiButton from '@material-ui/core/Button';
import AddBoxIcon from '@material-ui/icons/AddBox';

import './Button.scss';

const BUTTON_ICONS = {
  NEW: <AddBoxIcon />,
};

const Button = ({
  label,
  variant, // outlined, contained
  disabled,
  size, // small, medium, large
  onClick,
  customClass,
  icon,
}) => {
  const other = {};

  if (icon) {
    other['startIcon'] = icon;
  }

  return (
    <div className={`gt-button ${customClass ? customClass : ''}`}>
      <MuiButton
        variant={variant}
        disableElevation
        disabled={disabled}
        size={size}
        color="primary"
        onClick={onClick}
        {...other}
      >
        {label}
      </MuiButton>
    </div>
  );
};

export { Button, BUTTON_ICONS };

Button.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  onClick: PropTypes.func,
  customClass: PropTypes.string,
  icon: PropTypes.node,
};

Button.defaultProps = {
  label: '',
  variant: 'contained',
  disabled: false,
  size: 'medium',
  onClick: f => f,
  customClass: '',
  icon: null,
};
