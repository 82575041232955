import { Config } from '../services/Config';

const gtUrl = Config.get('BaseUrl');

export const PRODUCT_LEVELS = {
  CATEGORY: 'category',
  SUBCATEGORY: 'sub-category',
  SEARCH: 'search',
  FAVORITES: 'favorites',
  SPECIALS: 'specials',
};

export const STORAGE_USER_KEY = 'gtuser';

export const MENU_ITEMS = [
  {
    label: 'Početna',
    link: `${gtUrl}`,
  },
  {
    label: 'O nama',
    link: `${gtUrl}/about`,
  },
  {
    label: 'Kontakt',
    link: `${gtUrl}/contact`,
  },
];

export const USER_MENU_ITEMS_MOBILE = [
  {
    label: 'Omiljeno',
    link: `${gtUrl}/favorites`,
  },
  {
    label: 'Istorija kupovine',
    link: `${gtUrl}/history`,
  },
];

export const USER_MENU_ITEMS_WEB = [
  {
    label: 'Istorija kupovine',
    link: `${gtUrl}/history`,
  },
];

export const ADMIN_MENU_ITEMS = [
  {
    label: 'Administracija',
    link: `${gtUrl}/admin`,
  },
];

export const ADMIN_UTILS = {
  CUSTOMERS: 'customers',
};

export const CLIENT_BANNERS = {
  MERIDIAN_BET: {
    name: 'Meridian Bet',
    class: 'meridianbet',
    link: 'https://www.bolnicabeograd.co.rs/'
  },
  GIGATRON: {
    name: 'Gigatron',
    class: 'gigatron',
    link: 'https://gigatron.rs/'
  },
  STRANI_JEZICI: {
    name: 'Institut za strane jezike',
    class: 'institutzastranejezicke',
    link: 'http://www.isj.rs/'
  }

};
