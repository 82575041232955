import * as Actions from './types';

import { ProductCommunicator } from "../../Communicators/ProductCommunicator";
import { PRODUCT_LEVELS } from "../../constants/Constants";
import {product} from "../reducers";
import * as queryString from "query-string";

export const loadFavorites = () => async (dispatch) => {
  dispatch({ type: Actions.LOADING_FAVORITES, payload: true });
  const favorites = await ProductCommunicator.getFavoriteProducts();
  dispatch({ type: Actions.LOADING_FAVORITES, payload: false });

  dispatch({ type: Actions.POPULATE_FAVORITES, payload: favorites });

  return favorites;
};

export const getProducts = (id, level) => async (dispatch, getState) => {

  dispatch({ type: Actions.LOADING_PRODUCTS, payload: true });
  let products = [];

  switch (level) {
    case PRODUCT_LEVELS.SEARCH:
      let queryParams = queryString.parse(location.search);
      products = await ProductCommunicator.getProductsBySearch(queryParams);
      break;
    case PRODUCT_LEVELS.CATEGORY:
      products = await ProductCommunicator.getProductsByCategoryId(~~id);
      break;
    case PRODUCT_LEVELS.SUBCATEGORY:
      products = await ProductCommunicator.getProductsBySubCategoryId(~~id);
      break;
    case PRODUCT_LEVELS.FAVORITES:
      products = await dispatch(loadFavorites());
      break;
    case PRODUCT_LEVELS.SPECIALS:
      products = await ProductCommunicator.getSpecialOffer();
      break;
    default:
      products = [];
  }

  dispatch({ type: Actions.POPULATE_PRODUCTS_LIST, payload: products });
  dispatch({ type: Actions.LOADING_PRODUCTS, payload: false });
};

export const getProduct = (id, categoryId, subcategoryId) => async (dispatch) => {
  dispatch({ type: Actions.LOADING_PRODUCTS, payload: true });

  let product = null;
  let similar = null;
  let files = null;

  try {
    product = await ProductCommunicator.getProduct(id);
    similar = await ProductCommunicator.getSimilarProducts(id, categoryId, subcategoryId, 10);
    files = await ProductCommunicator.getFiles(id);
  } catch (e) {
    dispatch({ type: Actions.LOADING_PRODUCTS, payload: false });
  }
 
  dispatch({ type: Actions.PRODUCT_WITH_ID, payload: product });
  dispatch({ type: Actions.SIMILAR_PRODUCTS, payload: similar });
  dispatch({ type: Actions.PRODUCT_FILES, payload: files });
  dispatch({ type: Actions.LOADING_PRODUCTS, payload: false });
};

export const addToFavorites = (productId) => async (dispatch, getState) => {
  dispatch({ type: Actions.LOADING_FAVORITES, payload: true });
  await ProductCommunicator.addToFavorites(productId);
  dispatch({ type: Actions.LOADING_FAVORITES, payload: false });
  
  const { favorites, list } = getState().product;
  const freshFavorites = [...favorites];
  const product = [...list].find(item => item.id === productId);

  product.favorite = true;
  freshFavorites.push(product);
  
  dispatch({ type: Actions.POPULATE_FAVORITES, payload: freshFavorites });
};

const afterRemoveFromFavorites = (productId, level, fresh) => (dispatch, getState) => {
  const { list } = getState().product;

  if (level === PRODUCT_LEVELS.FAVORITES) {
    dispatch({ type: Actions.POPULATE_PRODUCTS_LIST, payload: fresh });
  } else {
    const products = [...list].map(item => {
      if (item.id === productId) {
        return {...item, favorite: false};
      }
      return item;
    });
    dispatch({ type: Actions.POPULATE_PRODUCTS_LIST, payload: products });
  }
};

export const removeFromFavorites = (productId, level) => async (dispatch, getState) => {
  dispatch({ type: Actions.LOADING_FAVORITES, payload: true });
  await ProductCommunicator.removeFromFavorites(productId);
  dispatch({ type: Actions.LOADING_FAVORITES, payload: false });

  const { favorites } = getState().product;
  const freshFavorites = [...favorites].filter(item => item.id != productId);
  dispatch({ type: Actions.POPULATE_FAVORITES, payload: freshFavorites });

  dispatch(afterRemoveFromFavorites(productId, level, freshFavorites));
};

export const uploadFiles = docs => async (dispatch, getState) => {
  const { files, list } = getState().product;
  const productId = list[0].id;

  try {
    const newOnes = await Promise.all(docs.map(async doc => (
      await ProductCommunicator.uploadFile(productId, doc)
    )));

    dispatch({ type: Actions.PRODUCT_FILES, payload: [...files, ...newOnes] });
  } catch (e) {
    throw new Error(e);
  }
};

export const deleteFile = fileId => async (dispatch, getState) => {
  try {
    await ProductCommunicator.removeFile(fileId);

    const { files } = getState().product;
    const cleansedFiles = files.filter(file => file.id !== fileId);
    dispatch({ type: Actions.PRODUCT_FILES, payload: cleansedFiles });
  } catch (e) {
    throw new Error(e);
  }
};

