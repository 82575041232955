import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { User, Config, Format } from '../../../../services/Services';
import { Button } from '../../_reusable/Button/Button';
import { InputField } from '../../_reusable/Input/Input';
import { Transition } from '../../_reusable/Transition/Transition';
import { Tooltip, TooltipPlacements } from '../../_reusable/Tooltip/Tooltip';
import { Loader } from '../../_reusable/Loader/Loader';
import { ProductQuickView } from '../ProductQuickView/ProductQuickView';

import './PageProductList.scss';

const PageProductList = ({
  customClass,
  data,
  addToCart,
  quantity,
  setQuantity,
  processing,
  processingItems,
  setProcessing,
}) => {
  const NO_PICTURE_PATH = Config.get('noPicturePath');
  const logged = User.loggedIn();
  const gtUrl = Config.get('BaseUrl');
  const viewClass = 'gt-page-product-list';
  const contentsClass = `${viewClass}-contents`;
  const statusClass = (quantity > 0) ? '' : 'empty';

  const {
    id, name, imagePath, price, priceWithVat, stock, code, vat, unit
  } = data;

  const isProcessing = !!(processing && processingItems.includes(id));

  const outOfStock = !!(logged && (stock <= 0));

  const [waiting, setWaiting] = useState(false);
  const [notify, setNotify] = useState(false);
  const [preview, setPreview] = useState(false);

  useEffect(() => {
    if (notify) {
      setTimeout(() => setNotify(!notify), 2000);
    }
  }, [notify]);

  useEffect(() => {
    if (isProcessing) {
      setWaiting(true);
    }
    if (!isProcessing && waiting) {
      setWaiting(false);
      setNotify(!notify);
    }
  }, [isProcessing]);

  const updateQuantity = (val) => {
    setQuantity(id, val);
  };

  const purchase = async (howMuch) => {
    setProcessing(id);
    const result = await addToCart(id, howMuch);
    setProcessing(null);
  };

  const addProductToCart = async () => {
    if (!processing && quantity && Number(quantity) !== 0) {
      await purchase(quantity);
      setQuantity(id, null);
    }
  };

  const openPreview = () => !processing && setPreview(true);

  const closePreview = () => {
    if (processing) { return; }
    setPreview(false);
  };

  const renderBuyNotification = () => !preview && (
    <Transition type="collapse" customClass={`${contentsClass}-notification`} show={notify} >
      <div className="notification-body">
        <div className="notification-body-message">
          <span className="gt-icon gt-icon-info" />
          <span className="message">Uspešno ste dodali proizvod u korpu</span>
        </div>
      </div>
    </Transition>
  );

  const renderImgButtonPreview = () => (
    <div
      role="button"
      className="img-button-preview"
      onClick={openPreview}
    >
      <span className="gt-icon gt-icon-eye" />
    </div>
  );

  const renderFooter = () => (
    <div className={`${contentsClass}-footer`}>
      <div className="product-options">
        <InputField
          type="number"
          fieldStyle="outlined"
          label="Količina"
          value={quantity}
          onChange={updateQuantity}
          disabled={processing}
          shrinkLabel
        />
        <div className="add-to-cart-btn">
          { (isProcessing && !preview)
            ? <Loader type="fadingCircle" />
            : (
              <Tooltip
                title={(quantity > 0) ? "Stavi u korpu" : "Dodaj količinu"}
                placement={TooltipPlacements.BOTTOM_END}
              >
                <span
                  className={`gt-icon gt-icon-${(notify && !preview) ? 'success' : 'add-to-cart'}`}
                  role="button"
                  onClick={() => (!preview && !notify) && addProductToCart() }
                />
              </Tooltip>
            )
          }
        </div>
      </div>
    </div>
  );

  const renderListItem = (customClass, label, value, href = null) => (
    <div className={`list-item ${customClass}`}>
      <span className="list-item__label">{label}</span>
      { href
        ? <a href={href}><span className="list-item__value">{value}</span></a>
        : <span className="list-item__value">{value}</span>
      }
    </div>
  );

  return (
    <div className={`${viewClass} ${customClass} ${statusClass}`}>
      <ProductQuickView
        show={preview}
        onClose={closePreview}
        data={data}
        processing={processing}
        notify={notify}
        purchase={purchase}
      />
      <div className={`${contentsClass} ${quantity ? 'chosen' : ''}`}>
        <div className={`${contentsClass}-img`}>
          <div className="img-wrapper">
            <img src={imagePath || NO_PICTURE_PATH} alt="product image" />
            { renderImgButtonPreview() }
          </div>
        </div>
        <div className={`${contentsClass}-data`}>
          <div className={`${contentsClass}-body`}>
            { renderListItem('title', 'Naziv', name, `${gtUrl}/product/${id}`) }
            <div className={`${contentsClass}-body-rest`}>
              { logged && code && renderListItem('code', 'Šifra', code) }
              { logged && price && renderListItem('price', 'Cena', Format.toCurrency(price)) }
              { logged && priceWithVat && renderListItem('price-with-vat', 'Cena sa PDV', Format.toCurrency(priceWithVat)) }
              {/* logged && vat && renderListItem('vat', 'PDV', `${vat}%`) */}
              { logged && unit && renderListItem('unit', 'Jedinica mere', unit) }
            </div>
          </div>
          { logged && renderFooter() }
        </div>
      </div>
    </div>
  );
};

PageProductList.propTypes = {
  customClass: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  addToCart: PropTypes.func,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setQuantity: PropTypes.func,
  processing: PropTypes.bool,
  processingItems: PropTypes.arrayOf(PropTypes.any),
  setProcessing: PropTypes.func,
};

PageProductList.defaultProps = {
  customClass: '',
  addToCart: f => f,
  quantity: '',
  setQuantity: f => f,
  processing: false,
  processingItems: [],
  setProcessing: f => f,
};

export { PageProductList };
