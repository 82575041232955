import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './ShowMore.scss';

const ShowMore = ({
  items, toShow, setToShow, increaseBy, text,
}) => {
  const renderShowButton = () => (
    <div className="gt-show-more">
      <span className="gt-show-more-line" />
      <span
        className="gt-show-more-btn"
        onClick={() => setToShow(toShow + increaseBy)}
        role="button"
      >
        {text}
      </span>
      <span className="gt-show-more-line" />
    </div>
  );

  return (items && !!(items > toShow)) ? renderShowButton() : null;
};

ShowMore.propTypes = {
  items: PropTypes.number.isRequired,
  toShow: PropTypes.number.isRequired,
  setToShow: PropTypes.func.isRequired,
  increaseBy: PropTypes.number,
  text: PropTypes.string,
};

ShowMore.defaultProps = {
  increaseBy: 10,
  text: 'Show more',
};

export { ShowMore };
