import React from 'react';

import { PageHeader } from '../../sections/PageHeader/PageHeader';

import './AboutPage.scss';

const PARAGRAPHS = [
  `Tu smo da sa vama delimo naš profesionalizam i vaše poverenje. I dalje se borimo za ` +
    `svakog kupca i svaki proizvod. Da se sa vama i uz vas zajedno radujemo uspešno završenim ` +
    `poslovima. Očekujemo da nam se pridružite, da radimo brzo i tačno, da budemo partneri kojima ` +
    `je lepo da rade zajedno. Da budemo tim koji pobeđuje.`,
  'I dalje smo sa vama, uz vas, za vas.',
  'I dalje učimo, jer uvek može bolje.',
];

const AboutPage = () => (
  <div className="page about-page">
    <PageHeader
      name="about-page"
      title="O nama"
    />
    <div className="about-page-section-1 section">
      <div className="about-text">
        <span className="about-text-paragraph intro">Dobro došli u GT hemiju!</span>

        { PARAGRAPHS.map(paragraph => (
          <span key={paragraph} className="about-text-paragraph">{paragraph}</span>
        ))}

      </div>
      <div className="about-logo"></div>
    </div>
    <div className="about-page-section-2 section">
      <div className="section-box">
        <span className="gt-icon gt-icon-tradition" />
        <span className="section-box-header">Tradicija</span>
        <span className="section-box-description">
          Preko 25 godina smo Vaši verni poslovni partneri na koje se uvek možete osloniti i koji će uvek biti tu uz Vas.
        </span>
      </div>

      <div className="section-box">
        <span className="gt-icon gt-icon-products" />
        <span className="section-box-header">Asortiman</span>
        <span className="section-box-description">
          U našoj ponudi se nalazi preko 450 kvalitetnih proizvoda različitih najpopularnijih brendova na našem tržištu.
        </span>
      </div>

      <div className="section-box">
        <span className="gt-icon gt-icon-brands" />
        <span className="section-box-header">Brendovi</span>
        <span className="section-box-description">
          U našoj bogatoj ponudi nalaze se i proizvodi naša 4 GT Hemija brenda, kojima za Vas stvaramo dobar odnos cene i kvaliteta.
        </span>
      </div>

      <div className="section-box">
        <span className="gt-icon gt-icon-clients" />
        <span className="section-box-header">Uspeh</span>
        <span className="section-box-description">
          Već godinama uspešno sarađujemo sa preko 950 zadovoljnih klijenata. Budite i Vi deo naše uspešne poslovne priče.
        </span>
      </div>
    </div>
     <div className="about-page-section-3 section">
      <div className="section-header">
        <span className="section-header-line" />
        <span className="section-header-text">Naši sertifikati</span>
        <span className="section-header-line" />
      </div>
      <div className="section-downloads">
        <div className="download-items">
          <a target="_blank" href={`/downloads/QMS-cert-srp.pdf`}>
            <span className="item certificate-iso-9001" />
          </a>
          <a target="_blank" href={`/downloads/EMS-cert-srp.pdf`}>
            <span className="item certificate-iso-14001" />
          </a>
        </div>
      </div>
    </div>

      <div className="about-page-section-3 section">
          <div className="section-header">
              <span className="section-header-line" />
              <span className="section-header-text">Ostalo</span>
              <span className="section-header-line" />
          </div>
          <div className="section-downloads">
              <div className="download-items">
                  <a target="_blank" href={`/downloads/profil_kompanije.pdf`}>
                      <span className="item company-profile" />
                  </a>
                  <a target="_blank" href={`/downloads/katalog_proizvoda.pdf`}>
                      <span className="item product-catalogue" />
                  </a>
              </div>
          </div>
      </div>
  </div>
);

export { AboutPage };
