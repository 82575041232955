import numeral from 'numeral';
import {Config} from "./Config";


class _Format {
  toCurrency(str) {
    return numeral(str).format(Config.get('currencyFormat'))
  }
}

export const Format = new _Format();