import React, { Fragment } from 'react';

import { orderBy } from 'lodash'; 
import { Config, User } from '../../../services/Services';
import { CategoryMenuWeb } from '../../components/CategoryMenu/CategoryMenuWeb';
import { CategoryMenuMobile } from '../../components/CategoryMenu/CategoryMenuMobile';
import { SearchBoxWeb, SearchBoxMobile } from '../../components/SearchBox/SearchBox';
import { MiniCartWeb, MiniCartMobile } from '../../components/MiniCart/MiniCart';
import { MiniFavorites } from '../../components/MiniFavorites/MiniFavorites';
import { MENU_ITEMS, USER_MENU_ITEMS_WEB, ADMIN_MENU_ITEMS } from '../../../constants/Constants';
import { CategoryCommunicator } from '../../../Communicators/Communicators';

import './Header.scss';

const gtUrl = Config.get('BaseUrl');
const loggedIn = User.loggedIn();
const isAdmin = User.isAdmin();
const userName = User.get('name');

const HeaderComponent = ({ something }) => {
  const sortedCategories = orderBy(PRELOAD_DATA.categories, ['id'], ['asc']);

  const renderTopBarMenu = () => (
    <div className="topbar-menu">
      <ul>
        <li>
          { !!loggedIn
            ? <a href="#" onClick={User.logout}>Odjava</a>
            : <a href={`${gtUrl}/login`}>Prijava</a>
          }
        </li>
      </ul>
    </div>
  );

  const renderTopBarSocial = () => (
    <div className="topbar-social-links">
      <a className="facebook" href="#"><span className="gt-icon gt-icon-facebook" /></a>
      <a className="twitter" href="#"><span className="gt-icon gt-icon-twitter" /></a>
      <a className="linkedin" href="#"><span className="gt-icon gt-icon-linkedin" /></a>
    </div>
  );

  const renderGreeting = () => {
    return !loggedIn
      ? <p>Dobro došli na sajt GT Hemija!</p>
      : <p><span>Dobro došli </span><span className="greeting">{userName}</span></p>;
  };

  const renderUser = () => (
    <div className="menu-item box-item user-page">
      <span className="gt-icon-user" />
    </div>
  );

  return (
    <Fragment>
      <div className="mobile-header">
        <div className="header-inner flex-row-btw">
          <div className="header-logo">
            <a>
              <span
                className="logo"
                onClick={() => location.href = gtUrl}
              >logo</span>
            </a>
          </div>
          <CategoryMenuMobile list={sortedCategories} />
          <SearchBoxMobile />
          <MiniCartMobile />
        </div>
      </div>

      <div className="web-header">
        <div className="topbar">
          <div className="topbar-inner container">
            { renderGreeting() }
            <div className="topbar-inner-menu" >
              { renderTopBarMenu() }
              { renderTopBarSocial() }
            </div>
          </div>
        </div>

        <div className="main-header container">
          <div className="header-inner flex-row-btw">
            <div className="header-logo">
              <a href={gtUrl}><span className="logo">logo</span></a>
            </div>
            <SearchBoxWeb categories={sortedCategories} />
            <div className="header-user-utilities">
              <MiniFavorites />
              <MiniCartWeb />
            </div>
          </div>
        </div>

        <div className="main-menu">
          <div className="main-menu-inner container">
            <CategoryMenuWeb list={sortedCategories} />
            <ul className="main-nav-bar">
              { MENU_ITEMS.map((item, index) => (
                <li key={`main-nav-bar-item-${index}`}><a href={item.link}>{item.label}</a></li>
              ))}
              { loggedIn && !isAdmin && USER_MENU_ITEMS_WEB.map((item, index) => (
                <li key={`user-main-nav-bar-item-${index}`}><a href={item.link}>{item.label}</a></li>
              ))}
              { loggedIn && isAdmin && ADMIN_MENU_ITEMS.map((item, index) => (
                <li key={`user-main-nav-bar-item-${index}`}><a href={item.link}>{item.label}</a></li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export { HeaderComponent };
