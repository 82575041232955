import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { User, Config, Format } from '../../../../services/Services';
import { Button } from '../../_reusable/Button/Button';
import { InputField } from '../../_reusable/Input/Input';
import { Transition } from '../../_reusable/Transition/Transition';
import { Loader } from '../../_reusable/Loader/Loader';
import { Tooltip, TooltipPlacements } from '../../_reusable/Tooltip/Tooltip';
import { ProductQuickView } from '../ProductQuickView/ProductQuickView';
import { ProductTags } from '../../ProductTags/ProductTags';

import './PageProduct.scss';

const PageProduct = ({
  customClass,
  data,
  addToCart,
  quantity,
  setQuantity,
  processing,
  processingItems,
  setProcessing,
  handleFavorite,
}) => {
  const NO_PICTURE_PATH = Config.get('noPicturePath');
  const logged = User.loggedIn();
  const gtUrl = Config.get('BaseUrl');

  const [waiting, setWaiting] = useState(false);
  const [notify, setNotify] = useState(false);
  const [preview, setPreview] = useState(false);
  const [loadingFavorite, setLoadingFavorite] = useState(false);

  const {
    id, name, imagePath, price, priceWithVat, stock, favorite,
  } = data;

  const isProcessing = !!(processing && processingItems.includes(id));
  const outOfStock = !!(logged && (stock <= 0));
  const statusClass = (quantity > 0) ? '' : 'empty';

  useEffect(() => {
    if (notify) {
      setTimeout(() => setNotify(!notify), 2000);
    }
  }, [notify]);

  useEffect(() => {
    if (isProcessing) {
      setWaiting(true);
    }
    if (!isProcessing && waiting) {
      setWaiting(false);
      setNotify(!notify);
    }
  }, [isProcessing]);

  const updateQuantity = (val) => {
    setQuantity(id, val);
  };

  const purchase = async (howMuch) => {
    setProcessing(id);
    const result = await addToCart(id, howMuch);
    setProcessing(null);
  };

  const addProductToCart = async () => {
    if (!processing && quantity && Number(quantity) !== 0) {
      await purchase(quantity);
      setQuantity(id, null);
    }
  };

  const openPreview = () => !processing && !loadingFavorite && setPreview(true);

  const closePreview = () => {
    if (processing) { return; }
    setPreview(false);
  };

  const handleFavoriteStatus = async () => {
    if (!loadingFavorite && !processing) {
      setLoadingFavorite(true);
      await handleFavorite(id, favorite);
      setLoadingFavorite(false);
    }
  };

  const renderBuyNotification = () => !preview && (
    <Transition type="collapse" customClass="gt-page-product-contents-notification" show={notify} >
      <div className="notification-body">
        <div className="notification-body-message">
          <span className="gt-icon gt-icon-info" />
          <span className="message">Uspešno ste dodali proizvod u korpu</span>
        </div>
      </div>
    </Transition>
  );

  const renderImgButtons = () => (
    <div className="img-buttons">
      { logged && (
        <Tooltip
          placement={TooltipPlacements.TOP}
          title={favorite ? 'Ukloni iz omiljenog' : 'Dodaj u omiljeno'}
        >
          <div
            role="button"
            className={`img-buttons-btn wishlist ${favorite ? 'favorite' : ''}`}
            onClick={handleFavoriteStatus}
          >
            { loadingFavorite
              ? <Loader type="fadingCircle" />
              : <span className={`gt-icon gt-icon-${favorite ? 'broken-' : ''}heart`} />
            }
          </div>
        </Tooltip>
      )}
      <Tooltip
        placement={TooltipPlacements.TOP}
        title='Brzi pregled'
      >
        <div
          role="button"
          className="img-buttons-btn preview"
          onClick={openPreview}
        >
          <span className="gt-icon gt-icon-eye" />
        </div>
      </Tooltip>
    </div>
  );

  const renderFooter = () => (
    <div className="gt-page-product-contents-footer">
      <div className="price">
        <span className="display-price">{Format.toCurrency(price)}</span>
        {/*
          priceWithVat && <span className="old-price">{Format.toCurrency(priceWithVat)}</span>
        */}
      </div>
      <div className="product-options">
        <InputField
          type="number"
          fieldStyle="outlined"
          label="Količina"
          value={quantity}
          onChange={updateQuantity}
          disabled={processing}
          shrinkLabel
        />
        <Button
          customClass="add-to-cart-btn"
          label={(
            (isProcessing && !preview)
              ? <Loader type="fadingCircle" />
              : <span className="gt-icon gt-icon-add-to-cart"></span>
          )}
          onClick={addProductToCart}
          disabled={!!(isProcessing && !preview)}
        />
      </div>
      { renderBuyNotification() }
    </div>
  );

  return (
    <div className={`gt-page-product ${customClass} ${statusClass} ${(loadingFavorite && !preview) ? 'loading' : ''}`}>
      <ProductQuickView
        show={preview}
        onClose={closePreview}
        data={data}
        processing={processing}
        notify={notify}
        purchase={purchase}
        favoriting={loadingFavorite}
        handleFavorite={handleFavoriteStatus}
      />
      <div className="gt-page-product-contents">
        <div className="gt-page-product-contents-body">
          <div className="img-wrapper">
            <img src={imagePath || NO_PICTURE_PATH} alt="product image" />
            <ProductTags product={data} />
            { renderImgButtons() }
          </div>
          <a href={`${gtUrl}/product/${id}`}><span className="line-item name">{name}</span></a>
        </div>

        { User.loggedIn() && renderFooter() }
      </div>
    </div>
  );
};

PageProduct.propTypes = {
  customClass: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  addToCart: PropTypes.func,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setQuantity: PropTypes.func,
  processing: PropTypes.bool,
  processingItems: PropTypes.arrayOf(PropTypes.any),
  setProcessing: PropTypes.func,
  handleFavorite: PropTypes.func,
};

PageProduct.defaultProps = {
  customClass: '',
  addToCart: f => f,
  quantity: '',
  setQuantity: f => f,
  processing: false,
  processingItems: [],
  setProcessing: f => f,
  handleFavorite: f => f,
};

export { PageProduct };
