import React, { useState } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

const TabPanel = (props) => {
  const { children, value, index } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <div className={`tab-content-${index}`}>{children}</div>}
    </Typography>
  );
}

const sharedProps = index => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const TabView = (props) => {
  const {tabs, content, customClass} = props;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 return (
  <div className={`gt-tabview ${customClass ? customClass : ''}`}>
    <Tabs value={value} onChange={handleChange} aria-label="tab view">
      {tabs.map((tab, index) => (
        <Tab classes={{root: 'gt-tab-root'}} key={`tab-${index}`} label={tab} {...sharedProps(index)} />
      ))}
    </Tabs>
    {content.map((panel, index) => (
      <TabPanel key={`tab-panel-${index}`} value={value} index={index}>
        {panel}
      </TabPanel>
    ))}
  </div>
 );
};

export { TabView };