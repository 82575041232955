import { connect } from 'react-redux';

import { SimilarProductsComponent } from './SimilarProductsComponent';

const mapStateToProps = ({ product }, own) => ({
  similar: product.similar,
  customClass: own.customClass,
});

const mapDispatchToProps = dispatch => ({
  // use if needed
});

const SimilarProducts = connect(mapStateToProps, mapDispatchToProps)(SimilarProductsComponent);

export { SimilarProducts };
