import { Base } from './Base';

class _CategoryCommunicator extends Base {
  constructor() {
    super('/category');
  }

  fetchAll() {
    return super.fetchAll({
      sort: [{ field: 'id', dir: 'asc' }]
    });
  }
}

export const CategoryCommunicator = new _CategoryCommunicator();
