import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Config, User, Helper } from '../../../services/Services';
import { PRODUCT_LEVELS } from '../../../constants/Constants';
import { PageHeader, DEFAULT_OPTIONS, AVAILABLE_FILTERS } from '../../sections/PageHeader/PageHeader';
import { OptionsService } from '../../sections/PageHeader/options/Option';
import { Loader } from '../../components/_reusable/Loader/Loader';
import { ShowMore } from '../../components/_reusable/ShowMore/ShowMore';
import { Empty, EMPTY_TYPES } from '../../components/_reusable/Empty/Empty';
import { PageProduct } from '../../components/Product/PageProduct/PageProduct';
import { PageProductList } from '../../components/Product/PageProductList/PageProductList';
import { BulkAddListItem } from '../../components/Product/PageProductList/BulkAddListItem';

import './ProductsPage.scss';
import * as queryString from "query-string";

const ProductsPageComponent = ({
  products, loading, fetchProducts, addToCart, bulkAddToCart, addFavorite, removeFavorite,
}) => {
  const queryParams = queryString.parse(location.search);

  const NAME = PRELOAD_DATA.category ? PRELOAD_DATA.category.name : 'Pretraga za pojam "' + (queryParams.searchQuery || '') + '"';
  const CATEGORY_ID = PRELOAD_DATA.category ? PRELOAD_DATA.category.id : null;
  const LEVEL = PRELOAD_DATA.level;
  const logged = User.loggedIn();
  const NO_PRODUCTS_PER_PAGE = 20;

  const getPageName = () => {
    switch (LEVEL) {
      case PRODUCT_LEVELS.FAVORITES:
        return 'Omiljeni proizvodi';
      case PRODUCT_LEVELS.SPECIALS:
        return 'Specijalna ponuda';
      default:
        return NAME;
    }
  };

  const [display, setDisplay] = useState({
    ...DEFAULT_OPTIONS,
    content: products,
    offset: NO_PRODUCTS_PER_PAGE,
    chunk: NO_PRODUCTS_PER_PAGE,
    listView: false,
  });

  const [quantity, setQuantity] = useState({});
  const [processing, setProcessing] = useState([]);

  useEffect(() => {
    if (!logged && LEVEL === PRODUCT_LEVELS.FAVORITES) {
      Helper.redirectToHome();
      return;
    }

    fetchProducts(CATEGORY_ID, LEVEL);
  }, []);

  useEffect(() => {
    setDisplay({ ...display, content: products });
  }, [products]);

  const processedContent = OptionsService.apply(display.content, display);

  const updateProductsDisplayed = (productsNo) => setDisplay({
    ...display,
    offset: productsNo,
  });

  const updateQuantity = (id, val) => {
    let productQty = quantity[id] || null;
    const trueVal = Number(val);

    if (val && trueVal !== 0) {
      productQty = (trueVal < 0) ? productQty : trueVal;
    } else {
      productQty = null;
    }

    if (productQty) {
      setQuantity({ ...quantity, [id]: productQty });
    } else {
      const quantityUpdate = { ...quantity };
      delete quantityUpdate[id];
      setQuantity(quantityUpdate);
    }
  };

  const updateProcessing = (prodId) => {
    let proc = [];

    if (prodId) {
      proc.push(prodId);
    }
    setProcessing(proc);
  };

  const addProductsToCart = async () => {
    const products = Object.keys(quantity).map(prod => Number(prod));

    if (products.length) {
      setProcessing(products);
      await bulkAddToCart(quantity);
      setProcessing([]);
      setQuantity({});
    }
  };

  const handleFavorite = async (id, favorite) => {
    if (favorite) {
      await removeFavorite(id, LEVEL);
    } else {
      await addFavorite(id, LEVEL);
    }
  };

  const renderProducts = () => {
    const { offset, listView } = display;
    const PageProductComponent = listView ? PageProductList : PageProduct;
    
    return processedContent.slice(0, offset).map(product => (
      <div key={product.code} className={`page-products__product ${logged ? 'full' : ''}`}>
        <PageProductComponent
          data={product}
          addToCart={addToCart}
          handleFavorite={handleFavorite}
          quantity={quantity[product.id] || ''}
          setQuantity={updateQuantity}
          processing={!!processing.length}
          processingItems={processing}
          setProcessing={updateProcessing}
          customClass={!logged ? 'trimmed' : ''}
        />
      </div>
    ));
  }

  const renderBulkToCart = () => (logged && display.listView)
    ? (
      <BulkAddListItem
        addToCart={addProductsToCart}
        data={quantity}
        processing={!!processing.length}
      />
    )
    : '';

  const renderContents = () => {
    const { offset, chunk, listView } = display;

    return processedContent.length
      ? (
        <Fragment>
          <div className={`page-products ${listView ? 'list' : 'grid'}`}>
            { renderProducts() }
            { renderBulkToCart() }
          </div>
          <ShowMore
            items={processedContent.length}
            toShow={offset}
            setToShow={updateProductsDisplayed}
            increaseBy={chunk}
            text="Prikaži još proizvoda"
          />
        </Fragment>
      )
      : <Empty type={EMPTY_TYPES.SEARCH} text="Nema traženih proizvoda" />;
  };

  const pageOptions = {
    items: [
      AVAILABLE_FILTERS.PAGINATION,
      AVAILABLE_FILTERS.SORT_BY_PRICE,
      AVAILABLE_FILTERS.PRICE_RANGE,
      AVAILABLE_FILTERS.SEARCH,
    ],
    custom: {
      [AVAILABLE_FILTERS.PAGINATION]: 'Proizvoda po strani',
    },
  };

  return (
    <div className="page products-page">
      <PageHeader
        layoutSwitch
        name="products-page"
        title={getPageName()}
        display={display}
        updateDisplay={setDisplay}
        disabled={loading}
        options={pageOptions}
      />
      { loading 
        ? <Loader type="bouncingDots" customClass="products-loader" />
        : renderContents()
      }
    </div>
  );
};

ProductsPageComponent.propTypes = {
  products: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
  fetchProducts: PropTypes.func,
  addToCart: PropTypes.func,
  bulkAddToCart: PropTypes.func,
  addFavorite: PropTypes.func,
  removeFavorite: PropTypes.func,
};

ProductsPageComponent.defaultProps = {
  products: [],
  loading: false,
  fetchProducts: f => f,
  addToCart: f => f,
  bulkAddToCart: f => f,
  addFavorite: f => f,
  removeFavorite: f => f,
};

export { ProductsPageComponent };
