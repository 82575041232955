import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Field } from '../../../_reusable/Form/Field';
import { InputField } from '../../../_reusable/Input/Input';

const Username = ({
  value, updateValue, name,
}) => {
  return (
    <InputField
      fieldStyle="outlined"
      label="Korisničko ime *"
      value={value || ''}
      onChange={val => updateValue(name, val)}
    />
  );
};

Username.propTypes = {
  value: PropTypes.string,
  updateValue: PropTypes.func,
  name: PropTypes.string,
};

Username.defaultProps = {
  value: null,
  updateValue: f => f,
  name: '',
};

/* TEST VALIDACIJA - KORISTICEMO ZA e-mail */
// const usernameValidator = (user) => {
//   const format = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
//   return format.test(user);
// };

export const UsernameField = () => (
  <Field
    name="username"
    component={props => <Username {...props} />}
    // validationFunc={usernameValidator}
    required
  />
);
