import * as types from './types';

const initialState = {
  deliveryDates: [],
  dayOffset: 1,
  comment: '',
  outlet: null,
  processing: false,
  loadingHistory: false,
  history: [],
};

const initialStateGenerator = () => JSON.parse(JSON.stringify(initialState));

export default (state = initialStateGenerator(), action) => {
  switch (action.type) {
    case types.DELIVERY_DATES_LOAD:
      return {...state, deliveryDates: action.payload};
    case types.SET_DELIVERY_DAY_OFFSET:
      return {...state, dayOffset: action.payload};
    case types.SET_DELIVERY_OUTLET:
      return {...state, outlet: action.payload};
    case types.SET_COMMENT:
      return {...state, comment: action.payload};
    case types.ORDER_PROCESSING:
      return {...state, processing: action.payload};
    case types.SET_LOADING_ORDER_HISTORY:
      return {...state, loadingHistory: action.payload};
    case types.LOAD_ORDER_HISTORY:
      return {...state, history: action.payload};
    default:
      return state;
  }
};