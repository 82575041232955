export class HttpError {

  errorObj;

  constructor(errorObj) {
    this.errorObj = errorObj;
  };

  getStatusCode = () => {
    return this.errorObj.statusCode
  };

  getMessage = () => {
    return this.errorObj.message
  };
}