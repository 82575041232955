import { Base } from './Base';
import { http } from "../services/HttpService";

class _CartCommunicator extends Base {
  constructor() {
    super('/cart');
  }

  async removeItem(productId) {
    return await http.jsonDelete(`${this.url}/${productId}`);
  }

  async addItem(productId, qty) {
    return await http.jsonPost(`${this.url}`, {
      productId,
      qty,
    });
  }

  async bulkAddItems(items) {
    return await http.jsonPost(`${this.url}/bulk`, {
      items,
    });
  }

  async changeQty(productId, qty) {
    return await http.jsonPut(`${this.url}`, {
      productId,
      qty,
    });
  }

  async loadFromOrder(orderId) {
    return await http.jsonPost(`${this.url}/load-from-order/${orderId}`);
  }

  async clear() {
    return await http.jsonDelete(`${this.url}`);
  }
}

export const CartCommunicator = new _CartCommunicator();
