import React from 'react';
import PropTypes from 'prop-types';

import { User } from '../../../../services/Services';
import { Form } from '../../_reusable/Form/Form';
import { FormFooter } from '../../_reusable/Form/FormFooter';
import { EmailField } from './fields/EmailField';
import { UserCommunicator } from '../../../../Communicators/Communicators';

export const ForgotPasswordForm = ({ onBackToLogin }) => {
  const onSubmit = async (model, formProps) => {
    const result = await UserCommunicator.restore({ ...model });

    if (result.error) {
      formProps.setFormMessage('error', `Greška: ${result.error.message}`);
    } else {
      formProps.clearForm();
      formProps.setFormMessage('info', 'Nova šifra je poslata na uneti email.');
    }
    return result;
  };

  const FORM_ACTIONS = [
    {
      type: 'link',
      label: 'Nazad na prijavu',
      action: onBackToLogin,
    },
  ];

  const renderFooter = () => {
    return (
      <FormFooter
        actions={FORM_ACTIONS}
        onSubmit={onSubmit}
        submitLabel="Pošalji mi šifru"
      />
    );
  };

  return (
    <Form
      footer={renderFooter}
      customClass="forgot-pass-form"
    >
      <EmailField />
    </Form>
  );
};

ForgotPasswordForm.propTypes = {
  onBackToLogin: PropTypes.func,
};

ForgotPasswordForm.defaultProps = {
  onBackToLogin: f => f,
};
