import React from 'react';
import PropTypes from 'prop-types';

import { User } from '../../../../services/Services';
import { Form } from '../../_reusable/Form/Form';
import { FormFooter } from '../../_reusable/Form/FormFooter';
import { NameField } from './fields/NameField';
import { EmailField } from './fields/EmailField';
import { SubjectField } from './fields/SubjectField';
import { MessageField } from './fields/MessageField';
import { CaptchaField } from './fields/CaptchaField';
import { ContactCommunicator } from '../../../../Communicators/Communicators';

export const ContactForm = () => {
  const resetCaptcha = () => {
    if (window.grecaptcha) {
      grecaptcha.reset();
    }
  };

  const onSubmit = async (model, formProps) => {
    try {
      const result = await ContactCommunicator.send({...model});

      formProps.clearForm();
      formProps.setFormMessage('info', 'Uspešno ste se poslali poruku.');
      resetCaptcha();

      return result;
    } catch (error) {
      formProps.setFormMessage('error', `Greška: ${error.getMessage()}`);
      resetCaptcha();
    }
  };

  const renderFooter = () => {
    return (
      <FormFooter
        onSubmit={onSubmit}
        submitLabel="Pošalji"
      />
    );
  };

  return (
    <Form
      footer={renderFooter}
      customClass="contact-form"
    >
      <NameField />
      <EmailField />
      <SubjectField />
      <MessageField />
      <CaptchaField />
    </Form>
  );
};
