import { Base } from './Base';
import {http} from "../services/HttpService";

class _ContactCommunicator extends Base {
  constructor() {
    super('/contact');
  }

  async send(data) {
    return await http.jsonPost(`${this.url}`, data);
  }
}

export const ContactCommunicator = new _ContactCommunicator();
