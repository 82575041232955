import { connect } from 'react-redux';
import { HeaderComponent } from './HeaderComponent';

const mapStateToProps = (state) => ({
  // something: state.something,
});

const mapDispatchToProps = dispatch => ({
  // fetchProjectBudgetBuckets: pId => dispatch(projectOperations.fetchProjectBudgetBuckets(pId)),
});

const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);

export { Header };
