import React from 'react';
import PropTypes from 'prop-types';

import { SelectField, FIELD_STYLE_OUTLINED } from '../../../components/_reusable/Input/Input';

const SortingItems = ({ curr, onChange }) => {
  const DEFAULT_VALUE = 'default';

  const SORTING_OPTIONS = [
    {value: DEFAULT_VALUE, label: 'Bez sortiranja'},
    {value: 'asc', label: 'Cena rastuće'},
    {value: 'desc', label: 'Cena opadajuće'},
  ];

  return (
    <div className="option-sorting-items">
      <SelectField
        fieldStyle={FIELD_STYLE_OUTLINED}
        value={curr || DEFAULT_VALUE}
        onChange={e => onChange(e.target.value)}
        options={SORTING_OPTIONS}
      />
    </div>
  );
};

SortingItems.propTypes = {
  curr: PropTypes.string,
  onChange: PropTypes.func,
};

SortingItems.defaultProps = {
  curr: null,
  onChange: f => f,
};

export { SortingItems };
