import { connect } from 'react-redux';
import * as productActions from '../../../state/product/actions';
import * as cartActions from '../../../state/cart/actions';

import { ProductsPageComponent } from './ProductsPageComponent';

const mapStateToProps = ({ product, cart }) => ({
  products: product.list,
  loading: product.loading,
});

const mapDispatchToProps = dispatch => ({
  fetchProducts: (id, level) => dispatch(productActions.getProducts(id, level)),
  addFavorite: (productId, level) => dispatch(productActions.addToFavorites(productId, level)),
  removeFavorite: (productId, level) => dispatch(productActions.removeFromFavorites(productId, level)),
  addToCart: (productId, qty) => dispatch(cartActions.addItem(productId, qty)),
  bulkAddToCart: (products) => dispatch(cartActions.bulkAddItems(products)),
});

const ProductsPage = connect(mapStateToProps, mapDispatchToProps)(ProductsPageComponent);

export { ProductsPage };
