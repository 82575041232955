import React from 'react';
import PropTypes from 'prop-types';

import { SelectField, FIELD_STYLE_OUTLINED } from '../../../components/_reusable/Input/Input';

const OrderStatusFilter = ({ curr, onChange }) => {
  const DEFAULT_VALUE = 'default';

  const STATUSES_OPTIONS = [
    { value: DEFAULT_VALUE, label: 'Svi statusi' },
    { value: 0, label: 'Nepoznato' },
    { value: 1, label: 'Kreirano' },
    { value: 2, label: 'Izmenjeno' },
    { value: 3, label: 'Priprema' },
    { value: 4, label: 'Pakuje se' },
    { value: 5, label: 'Spakovano' },
    { value: 6, label: 'Spremno' },
    { value: 7, label: 'Transport' },
    { value: 8, label: 'Isporučeno' },
    { value: 9, label: 'Stornirano' },
    { value: 10, label: 'Obrisano' },
    { value: 11, label: 'Realizovano' },
  ];

  return (
    <div className="option-order-status-items">
      <SelectField
        fieldStyle={FIELD_STYLE_OUTLINED}
        value={curr || DEFAULT_VALUE}
        onChange={e => onChange(e.target.value)}
        options={STATUSES_OPTIONS}
      />
    </div>
  );
};

OrderStatusFilter.propTypes = {
  curr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

OrderStatusFilter.defaultProps = {
  curr: null,
  onChange: f => f,
};

export { OrderStatusFilter };
