import React from 'react';
import PropTypes from 'prop-types';

import { Form } from '../../_reusable/Form/Form';
import { FormFooter } from '../../_reusable/Form/FormFooter';
import { EmailField } from './fields/EmailField';
import { TextField } from './fields/TextField';
import { CustomerField } from './fields/CustomerField';
import { FirstLetterField } from './fields/FirstLetterField';
import { CustomerCommunicator } from '../../../../Communicators/Communicators';

const AccountFormMapper = data => ({
  id: data.id ?? null,
  username: data.username ?? null,
  password: data.password ?? null,
  customerId: data?.customer?.id ?? null,
  email: data.email ?? null,
  first: data.customer.name ? data.customer.name.substring(0, 1).toUpperCase() : null,
});

export const AccountForm = ({
  onComplete,
  onSubmitClick,
  customers,
  model,
}) => {
  const submitLabel = (model && model.id) ? 'Potvrdi izmenu' : 'Registruj nalog';

  const onSubmit = async (model, formProps) => {
    onSubmitClick();
    let result = null;

    try {
      if (model && model.id) {
        result = await CustomerCommunicator.editUser(model, model.id);
      } else {
        result = await CustomerCommunicator.addUser(model);
      }

      result.customerName = result.customer.name;

      setTimeout(() => onComplete('success', result), 100);
    } catch (e) {
      setTimeout(() => onComplete('error'), 100);
    }

    return null;
  };

  const renderFooter = () => {
    return (
      <FormFooter
        onSubmit={onSubmit}
        submitLabel={submitLabel}
      />
    );
  };

  return (
    <Form
      footer={renderFooter}
      customClass="account-form"
      model={model ? AccountFormMapper(model) : null}
    >
      <FirstLetterField customers={customers} />
      <CustomerField customers={customers} />
      <TextField fieldName="username" fieldLabel="Korisničko ime *" />
      <TextField fieldName="password" fieldLabel="Lozinka *" />
      <EmailField />
    </Form>
  );
};

AccountForm.propTypes = {
  onComplete: PropTypes.func,
  onSubmitClick: PropTypes.func,
  customers: PropTypes.arrayOf(PropTypes.any),
  model: PropTypes.objectOf(PropTypes.any),
};

AccountForm.defaultProps = {
  onComplete: f => f,
  onSubmitClick: f => f,
  customers: [],
  model: null,
};
