import React from 'react';
import PropTypes from 'prop-types';

import { Config, Format } from '../../../services/Services';

const NO_PICTURE_PATH = Config.get('noPicturePath');

const HighlightedProduct = ({ customClass, products, logged }) => {
  const product = (products && products.length)
    ? products[Math.floor(Math.random()*products.length)]
    : null;

  return product
    ? (
      <div className={`highlighted-product ${customClass}`}>
        <div className="highlighted-product-details">
          <div className="highlighted-product-header">
            <h2>{product.name}</h2>
          </div>
          <p className="highlighted-product-about">
            {`${product?.purpose ?? ''} ${product?.instructions ?? ''}`}
          </p>
          { logged
            ? (
              <div className="highlighted-product-price" >
                <span className="item-value without-vat">{Format.toCurrency(product.price)}</span>
                <span className="item-value with-vat">{`(Sa PDV: ${Format.toCurrency(product.priceWithVat)})`}</span>
              </div>
            )
            : ''
          }
        </div>
        <div className="highlighted-product-image">
          <img src={product.imagePath || NO_PICTURE_PATH} alt="product image" />
        </div>
      </div>
    )
    : '';
};

HighlightedProduct.propTypes = {
  customClass: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.any),
  logged: PropTypes.bool,
};

HighlightedProduct.defaultProps = {
  customClass: '',
  products: null,
  logged: false,
};

export { HighlightedProduct };
