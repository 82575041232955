import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { store } from './react/state/store';
import { User } from './react/services/Services';

import '../image/slides/banner-0.jpg';
import '../image/slides/banner-1.jpg';
import '../image/slides/banner-2.jpg';
import '../image/special-offer.png';
import '../image/special-offer-long.png';

import {
  Header,
  Footer,
  AdminPage,
  MainPage,
  LoginPage,
  CartPage,
  ProductsPage,
  ProductPage,
  ContactPage,
  AboutPage,
  HistoryPage,
} from "./react/index";

const renderDomContents = () => {
  document.getElementById("gt-header") && ReactDOM.render(
    <Provider store={store}>
      <Header />
    </Provider>,
    document.getElementById("gt-header")
  );

  document.getElementById("gt-footer") && ReactDOM.render(
    <Provider store={store}>
      <Footer />
    </Provider>,
    document.getElementById("gt-footer")
  );

  document.getElementById("gt-main-page") && ReactDOM.render(
    <Provider store={store}>
      <MainPage />
    </Provider>,
    document.getElementById("gt-main-page")
  );

  document.getElementById("gt-admin-page") && ReactDOM.render(
    <Provider store={store}>
      <AdminPage />
    </Provider>,
    document.getElementById("gt-admin-page")
  );

  document.getElementById("gt-login-page") && ReactDOM.render(
    <Provider store={store}>
      <LoginPage />
    </Provider>,
    document.getElementById("gt-login-page")
  );

  document.getElementById("gt-cart-page") && ReactDOM.render(
    <Provider store={store}>
      <CartPage />
    </Provider>,
    document.getElementById("gt-cart-page")
  );

  document.getElementById("gt-category-page") && ReactDOM.render(
      <Provider store={store}>
        <ProductsPage />
      </Provider>,
      document.getElementById("gt-category-page")
  );

  document.getElementById("gt-product-page") && ReactDOM.render(
      <Provider store={store}>
        <ProductPage />
      </Provider>,
      document.getElementById("gt-product-page")
  );

  document.getElementById("gt-history-page") && ReactDOM.render(
      <Provider store={store}>
        <HistoryPage />
      </Provider>,
      document.getElementById("gt-history-page")
  );

  document.getElementById("gt-contact-page") && ReactDOM.render(
      <Provider store={store}>
        <ContactPage />
      </Provider>,
      document.getElementById("gt-contact-page")
  );

  document.getElementById("gt-about-page") && ReactDOM.render(
      <Provider store={store}>
        <AboutPage />
      </Provider>,
      document.getElementById("gt-about-page")
  );

  document.getElementById("gt-search-page") && ReactDOM.render(
      <Provider store={store}>
        <ProductsPage />
      </Provider>,
      document.getElementById("gt-search-page")
  );
};

document.addEventListener('readystatechange', event => {
  if (event.target.readyState === 'interactive') {
    User.checkSession();
  }
  else if (event.target.readyState === 'complete') {
    renderDomContents();
  }
});
