import React from 'react';

import './MainAbout.scss';

export const MainAbout = () => (
  <div className="main-about">
    <div className="main-about-shipping">
      <div className="inner">
        <span className="gt-icon gt-icon-shipping" />
        <div className="inner-text">
          <h3>Brza realizacija</h3>
          <p>za sve narudžbine</p>
        </div>
      </div>
    </div>

    <div className="main-about-discount">
      <div className="inner">
        <span className="gt-icon gt-icon-discount" />
        <div className="inner-text">
          <h3>Povoljne cene</h3>
          <p>za sve proizvode</p>
        </div>
      </div>
    </div>

    <div className="main-about-support">
      <div className="inner">
        <span className="gt-icon gt-icon-support" />
        <div className="inner-text">
          <h3>Pouzdana podrška</h3>
          <p>za sve klijente</p>
        </div>
      </div>
    </div>

    <div className="main-about-map">
      <div className="inner">
        <span className="gt-icon gt-icon-map" />
        <div className="inner-text">
          <h3>Blizu smo</h3>
          <p>za svaku saradnju</p>
        </div>
      </div>
    </div>
  </div>
);
