import { connect } from 'react-redux';

import * as productOperations from '../../../../../state/product/actions';
import { ProductFilesComponent } from './ProductFilesComponent';

const mapStateToProps = ({ product }, own) => ({
  files: product.files,
  customClass: own.customClass,
  id: own.id,
});

const mapDispatchToProps = dispatch => ({
  uploadFiles: files => dispatch(productOperations.uploadFiles(files)),
  removeFile: fileId => dispatch(productOperations.deleteFile(fileId)),
});

const ProductFiles = connect(mapStateToProps, mapDispatchToProps)(ProductFilesComponent);

export { ProductFiles };
