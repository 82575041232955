import React, { useState, Fragment } from 'react';

import { User } from '../../../services/Services';
import { PageHeader } from '../../sections/PageHeader/PageHeader';
import { Customers } from '../../sections/Customers/Customers';
import { ScrollToTop } from '../../components/_reusable/ScrollToTop/ScrollToTop';
import { Loader } from '../../components/_reusable/Loader/Loader';
import { Empty, EMPTY_TYPES } from '../../components/_reusable/Empty/Empty';

import { ADMIN_UTILS } from '../../../constants/Constants';

import './AdminPage.scss';

const isAdmin = User.isAdmin();

const AdminPage = () => {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(null);

  const changeActiveUtil = (util) => {
    if (util === active) { return; }

    setLoading(true);
    setActive(util);
  };

  const stopLoading = () => setLoading(false);

  const getChosenUtil = () => {
    switch (active) {
      case ADMIN_UTILS.CUSTOMERS:
        return <Customers stopLoading={stopLoading} />;
      default:
        return null;
    }
  };

  const renderUtil = () => (
    <Fragment>
      { loading
        ? <Loader type="bouncingDots" customClass="admin-loader" />
        : null
      }
      <div className={`util ${loading ? 'loading' : ''}`} >
        { getChosenUtil() }
      </div>
    </Fragment>
  );

  const renderUtilButton = (id, label, icon) => (
    <div
      role="button"
      className={`util-btn ${active === id ? 'active' : ''}`}
      onClick={() => changeActiveUtil(id)}
    >
      <span className={`gt-icon gt-icon-${icon}`} />
      <span className="util-btn-label">{label}</span>
    </div>
  );

  const utilButtons = (
    <div className="utils-btns">
      { renderUtilButton(ADMIN_UTILS.CUSTOMERS, 'Korisnici', 'user') }
    </div>
  );

  const greeting = (
    <div className="admin-page-greeting">
      <span>Dobro došli! Izaberite opciju za administraciju.</span>
    </div>
  );

  const content = active ? renderUtil() : greeting;

  return isAdmin
    ? (
      <div className="page admin-page">
        <PageHeader
          name="admin-page"
          title="Administracija"
        />
        <div className="admin-page-section-1 section">
          { utilButtons }
        </div>
        <div className="admin-page-section-2 section">
          { content }
        </div>

        <ScrollToTop />
      </div>
    )
    : (<Empty type={EMPTY_TYPES.AUTH} text="Nemate ovlašćenja da pristupite ovoj stranici!" />);
};

export { AdminPage };
