import React, { useState } from 'react';

import { Config } from '../../../services/Config';
import { InputField } from '../_reusable/Input/Input';
import { Transition } from '../_reusable/Transition/Transition';

import './SearchBox.scss';

const SearchBoxMobile = () => {
  const [term, setTerm] = useState('');
  const [show, setShow] = useState(false);
  const [hide, setHide] = useState(true);

  const toggleSearch = () => {
    const display = !show;
    setShow(display);
    setTerm('');

    if (display) {
      setHide(false);
    } else {
      setTimeout(() => setHide(true), 300);
    }
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      e.target.setAttribute("disabled", "disabled");
      e.preventDefault();
      doSearch();
    }
  };

  const doSearch = () => {
    if(term.length < 3) return;

    console.log(`Searching for "${term}"...`);
    location.href = `${Config.get('BaseUrl')}/search?searchQuery=${term}`;
  };

  const renderSearch = () => {
    if (hide) {
      return <span />;
    }

    return (
      <div className="search-panel-inner">
        <div className="search-button" onClick={doSearch}>
          <span className="gt-icon-search" />
        </div>
        <InputField
          fieldStyle="naked"
          placeholder="Pretraži prodavnicu..."
          value={term}
          onChange={e => setTerm(e.target.value)}
          onKeyPress={handleEnter}
          autoFocus={true}
        />
      </div>
    );
  };

  return (
    <div className="mobile-search menu-item search-store">
      <div className="search-store-icon icon-wrapper box-item" onClick={toggleSearch}>
        <span className="gt-icon-search" />
      </div>
      <Transition type="collapse" customClass="search-panel" show={show} >
        { renderSearch() }
      </Transition>
    </div>
  );
};

export { SearchBoxMobile };
