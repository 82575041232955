import {Config, http} from '../services/Services';

class _UsersCommunicator {
  constructor() {
    this.url = `${Config.get('ApiBaseUrl')}`;
  }

  async login(model) {
    return await http.jsonPost(`${this.url}/login`, model);
  }

  async restore(model) {
    return await http.jsonPost(`${this.url}/restore-password`, model);
  }

  async register(model) {
    return await http.jsonPost(`${this.url}/registration`, model);
  }
}

export const UserCommunicator = new _UsersCommunicator();
