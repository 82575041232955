import React, { Fragment, useState, useEffect } from 'react';

import { User } from '../../../services/Services';
import { ProductCommunicator } from '../../../Communicators/ProductCommunicator';
import { HighlightedProducts } from './HighlightedProducts';
import { HighlightedProduct } from './HighlightedProduct';

import './Highlights.scss';

const logged = User.loggedIn();

export const Highlights = () => {
  const [specials, setSpecials] = useState(null);

  const fetchSpecials = async () => {
    const spec = await ProductCommunicator.getSpecialOffer();
    setSpecials(spec);
  };

  useEffect(() => {
    fetchSpecials();
  }, []);

  return (
    <Fragment>
      <HighlightedProducts products={specials} logged={logged} />
      {/* <div className="single-highlighted">
        <div className="section-header">
          <span className="section-header-line" />
          <span className="section-header-text">Ponuda dana</span>
          <span className="section-header-line" />
        </div>
        <HighlightedProduct customClass="highlight-one" products={specials} logged={logged} />
        <HighlightedProduct customClass="highlight-two" products={specials} logged={logged} />
      </div> */}
    </Fragment>
  );
};
