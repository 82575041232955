import React, { Fragment } from 'react';

import { PageHeader } from '../../sections/PageHeader/PageHeader';
import { SectionHeader } from '../../components/SectionHeader/SectionHeader';
import { GtMap } from '../../components/Map/Map';
import { ContactForm } from '../../components/_forms/ContactForm/ContactForm';

import './ContactPage.scss';

const ContactPage = () => {
  const renderDetails = () => (
    <Fragment>
      <div className="details-section time">
        <SectionHeader title="Radno vreme" />
        <div className="details-section-items">
          <div className="item">
            <span>Radnim danima:</span>
            <span>08:00 - 15:00h</span>
          </div>
          <div className="item">
            <span>Subota i nedelja:</span>
            <span>Neradni dani</span>
          </div>
        </div>
      </div>
      <div className="details-section phone">
        <SectionHeader title="Fiksni telefoni" />
        <div className="details-section-items">
          <span className="item">+381 (0)11 2177017</span>
          <span className="item">+381 (0)11 2177018</span>
          <span className="item">+381 (0)11 7180536</span>
        </div>
      </div>
      <div className="details-section mobile">
        <SectionHeader title="Mobilni telefoni" />
        <div className="details-section-items">
          <span className="item">+381 (0)63 342051</span>
          <span className="item">+381 (0)63 1063399</span>
          <span className="item">+381 (0)63 418382</span>
        </div>
      </div>
      <div className="details-section mail">
        <SectionHeader title="Email" />
        <div className="details-section-items">
          <div className="item">
            <span>Prodaja:</span>
            <a href="mailto:prodaja@gthemija.com">
              <span className="text-line">prodaja@gthemija.com</span>
            </a>
          </div>
          <div className="item">
            <span>Komercijala:</span>
            <a href="mailto:komercijala@gthemija.com">
              <span className="text-line">komercijala@gthemija.com</span>
            </a>
          </div>
          <div className="item">
            <span>Office:</span>
            <a href="mailto:office@gthemija.com">
              <span className="text-line">office@gthemija.com</span>
            </a>
          </div>
        </div>
      </div>
      <div className="details-section location">
        <SectionHeader title="Lokacija" />
        <div className="details-section-items">
          <div className="item">
            <span className="text-line">Kliknite <a target="_blank" href="https://goo.gl/maps/SAsp4of7ZTvYP5Cs5">OVDE</a> i pronađite nas na Google mapama.</span>
          </div>
        </div>
      </div>
    </Fragment>
  );

  const renderContactForm = () => (
    <Fragment>
      <span className="form-name">Kontakt forma</span>
      <ContactForm />
    </Fragment>
  );

  return (
    <div className="page contact-page">
      <PageHeader
        name="contact-page"
        title="Kontakt"
      />
      <div className="contact-page-company">
        <span className="company-name">GT Hemija d.o.o.</span>
        <span className="company-address">Auto put za Zagreb 20, 11080 Zemun</span>
      </div>
      <div className="contact-page-main">
        <div className="contact-page-main-details">{renderDetails()}</div>
        <div className="contact-page-main-form">{renderContactForm()}</div>
      </div>

      <GtMap />
    </div>
  );
};

export { ContactPage };
