import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { User } from '../../../services/Services';
import { LayoutSwitcher } from '../../components/_reusable/LayoutSwitcher/LayoutSwitcher';
import { Transition } from '../../components/_reusable/Transition/Transition';
import { Option } from './options/Option';
import { ItemsPerPage } from './options/ItemsPerPage';
import { SortingItems } from './options/SortingItems';
import { PriceRange } from './options/PriceRange';
import { DateRange } from './options/DateRange';
import { SearchByName } from './options/SearchByName';
import { OrderStatusFilter } from './options/OrderStatus';
import { PageHeaderUpdater } from './PageHeaderUpdater';

import './PageHeader.scss';

export const DEFAULT_OPTIONS = {
  offset: 10,
  chunk: 10,
  listView: false,
  content: [],
};

export const AVAILABLE_FILTERS = {
  PAGINATION: 'PAGINATION',
  SEARCH: 'SEARCH',
  SORT_BY_PRICE: 'SORT_BY_PRICE',
  PRICE_RANGE: 'PRICE_RANGE',
  DATE_RANGE: 'DATE_RANGE',
  ORDER_STATUS: 'ORDER_STATUS',
};

const PageHeader = ({
  name,
  title,
  customClass,
  options,
  layoutSwitch,
  items,
  display,
  updateDisplay,
  disabled,
}) => {
  const loggedIn = User.loggedIn();
  const pageName = name.toLowerCase().replace(/\s+/g, '-');
  const updater = new PageHeaderUpdater(display, updateDisplay);

  const [visible, setVisible] = useState(false);

  const CONTENT_FILTERS = {
    PAGINATION: {
      label: 'Paginacija',
      key: 'chunk',
      component: ItemsPerPage,
      update: updater.changeLoadChunk,
      condition: true,
    },
    SEARCH: {
      label: 'Pretraga',
      key: 'search',
      component: SearchByName,
      update: updater.changeSearch,
      condition: true,
    },
    SORT_BY_PRICE: {
      label: 'Sortiraj',
      key: 'sortByPrice',
      component: SortingItems,
      update: updater.changeSortingByPrice,
      condition: loggedIn,
    },
    PRICE_RANGE: {
      label: 'Cenovni rang',
      key: 'priceRange',
      component: PriceRange,
      update: updater.changePriceRange,
      condition: loggedIn,
    },
    DATE_RANGE: {
      label: 'Vremenski period',
      key: 'dateRange',
      component: DateRange,
      update: updater.changeDateRange,
      condition: loggedIn,
    },
    ORDER_STATUS: {
      label: 'Status porudžbine',
      key: 'orderStatus',
      component: OrderStatusFilter,
      update: updater.changeOrderStatus,
      condition: loggedIn,
    },
  };

  const changeLayout = isList => !disabled && updater.changeLayout(isList);

  const renderLayoutSwitch = () => (
    <Fragment>
      <span className="gt-vertical-separator" />
      <LayoutSwitcher
        name={pageName}
        onLayoutChange={changeLayout}
        isListView={display.listView}
        disabled={disabled}
      />
    </Fragment>
  );

  const renderPageOptionsToggler = () => (
    <div className={`gt-page-header-main-options ${disabled ? 'disabled' : ''}`}>
      <div
        className={`options-toggler ${visible && 'active'}`}
        onClick={() => !disabled && setVisible(!visible)}
      >
        <span className="gt-icon gt-icon-filter" />
        <span className="options-toggler-label">Opcije</span>
      </div>

      { layoutSwitch && renderLayoutSwitch() }
    </div>
  );

  const renderSingleOption = (option, custom) => {
    const { label, key, update, condition } = CONTENT_FILTERS[option];
    const FilterComponent = CONTENT_FILTERS[option].component;
    const realLabel = (custom && custom[option]) ? custom[option] : label;

    return !!condition && (
      <Option key={key} label={realLabel}>
        <FilterComponent curr={display[key]} onChange={update} />
      </Option>
    );
  };

  const renderOptions = () => (
    <Transition type="collapse" customClass="gt-page-header-options" show={visible} >
      <div className="gt-page-header-options-list">
        { options.items && options.items.map(option => renderSingleOption(option, options.custom)) }
      </div>
    </Transition>
  );

  return (
    <div className={`gt-page-header ${pageName}`} >
      <div className="gt-page-header-main" >
        <h2 className="gt-page-header-main-title">{title}</h2>
        { options && renderPageOptionsToggler() }
      </div>
      { options && renderOptions() }
    </div>
  );
};

PageHeader.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  customClass: PropTypes.string,
  options: PropTypes.objectOf(PropTypes.any),
  layoutSwitch: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  display: PropTypes.objectOf(PropTypes.any),
  updateDisplay: PropTypes.func,
  disabled: PropTypes.bool,
};

PageHeader.defaultProps = {
  title: '',
  customClass: '',
  options: null,
  layoutSwitch: false,
  items: null,
  display: { ...DEFAULT_OPTIONS },
  updateDisplay: f => f,
  disabled: false,
};

export { PageHeader };
