import { Base } from './Base';
import { http, Config } from '../services/Services';

const baseUrl = Config.get('ApiBaseUrl');

class _CustomerCommunicator {
  constructor() {
    this.customerUrl = `${baseUrl}/customer`;
    this.userUrl = `${baseUrl}/user`;
  }

  async fetchCustomers() {
    return await http.get(this.customerUrl);
  }

  async fetchUsers() {
    return await http.get(this.userUrl);
  }

  async addUser(model) {
    return await http.jsonPost(this.userUrl, { ...model });
  }

  async editUser(model, userId) {
    return await http.jsonPut(`${this.userUrl}/${userId}`, model);
  }

  async deleteUser(userId) {
    return await http.jsonDelete(`${this.userUrl}/${userId}`);
  }
}

export const CustomerCommunicator = new _CustomerCommunicator();
