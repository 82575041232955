import React, { useEffect, useState, Fragment } from 'react';

import { User, Format, Config } from '../../../services/Services';
import { Loader } from '../../components/_reusable/Loader/Loader';
import { PageHeader } from '../../sections/PageHeader/PageHeader';
import { QuantitySetter } from '../../components/QuantitySetter/QuantitySetter';
import { Tooltip, TooltipPlacements } from '../../components/_reusable/Tooltip/Tooltip';
import { InputField, FIELD_STYLE_OUTLINED } from '../../components/_reusable/Input/Input';
import { Empty, EMPTY_TYPES } from '../../components/_reusable/Empty/Empty';

import { Button } from "../../components/_reusable/Button/Button";
import { DeliveryDates } from "../../components/Delivery/DeliveryDates";
import { DeliveryAddress } from "../../components/Delivery/DeliveryAddress";

import './CartPage.scss'

const NO_PICTURE_PATH = Config.get('noPicturePath');

const CartPageComponent = ({
  loading,
  updating,
  processing,
  contents,
  total,
  totalWithoutVat,
  totalVat,
  comment,
  deliveryDates,
  dayOffset,
  loadCart,
  removeItem,
  clear,
  changeQty,
  setComment,
  makeOrder,
  cartOverview,
}) => {
  const [finished, setFinished] = useState(false);
  const [items, setItems] = useState([]);
  const [overview, setOverview] = useState([]);
  const [message, setMessage] = useState(null);

  const contentsClass = 'cart-products__product-contents';
  const gtUrl = Config.get('BaseUrl');
  const buttonNoGo = !!(updating.length || processing);

  useEffect(() => {
    setItems([...contents]);
  }, [contents]);

  const displayValue = (val, key) => (
    finished ? overview[key] : val
  );

  const changeItemQty = async (productId, qty) => {
    if (buttonNoGo || Number(qty) < 1) { return; }

    const fresh = JSON.parse(JSON.stringify(contents));
    const newItems = fresh.map((item) => {
      if (item.productId === productId){
        item.qty = qty;
      }
      return item;
    });

    try {
      await changeQty(productId, qty || 0);
      setItems(newItems);
    } catch (httpError) {
      //
    }
  };

  const renderDataItem = (customClass, label, value, href = null) => (
    <div className={`list-item ${customClass}`}>
      <span className="list-item__label">{label}</span>
      { href
        ? <a href={href}><span className="list-item__value">{value}</span></a>
        : <span className="list-item__value">{value}</span>
      }
    </div>
  );

  const renderCartItem = (item) => {
    const { productId, name, imagePath, price, priceWithVat, qty, totalPriceWithVat } = item;
    const changing = updating.includes(productId);

    return (
      <div className={`cart-products__product ${changing ? 'changing' : ''}`} key={productId}>
        <div className="cart-products__product-contents">
          <div className={`${contentsClass}-img`}>
            <div className="img-wrapper">
              <img src={imagePath || NO_PICTURE_PATH} alt="product image" />
            </div>
          </div>
          <div className={`${contentsClass}-data`}>
            { renderDataItem('title', 'Naziv', name, `${gtUrl}/product/${productId}`) }

            <div className={`${contentsClass}-data-rest`}>
              { renderDataItem('price', 'Cena', Format.toCurrency(price)) }
              { renderDataItem('vat', 'PDV', Format.toCurrency(priceWithVat - price)) }

              {!finished
                ? (
                  <QuantitySetter
                    customClass="list-item qty"
                    quantity={qty}
                    updateQuantity={newQty => changeItemQty(productId, newQty)}
                  />
                )
                : renderDataItem('quantity', 'Količina', qty)
              }

              { renderDataItem('totalprice', 'Ukupno', Format.toCurrency(totalPriceWithVat)) }
            </div>

            {!finished && (
              <Tooltip
                title="Ukloni iz korpe"
                placement={TooltipPlacements.BOTTOM_END}
              >
                <span
                  role="button"
                  className="gt-icon-delete"
                  onClick={() => !buttonNoGo && removeItem(productId)}
                />
              </Tooltip>
            )}
          </div>
        </div>

        { changing && <Loader type="stretchingBars" customClass="qty-loader" /> }
      </div>
    );
  };

  const renderTotals = () => (
    <div className="cart-products__totals">
      <div className="cart-products__totals-items">
        <div className="cart-products__totals-item">
          <span>Osnovica:</span>
          <span>{ Format.toCurrency(displayValue(totalWithoutVat, 'totalWithoutVat')) }</span>
        </div>
        <div className="cart-products__totals-item">
          <span>PDV:</span>
          <span>{ Format.toCurrency(displayValue(totalVat, 'totalVat')) }</span>
        </div>
        <div className="cart-products__totals-item">
          <span>Ukupno:</span>
          <span>{ Format.toCurrency(displayValue(total, 'total')) }</span>
        </div>
      </div>
      {!finished && (
        <Button
          label="Obriši korpu"
          customClass="delete-cart-btn"
          onClick={() => !buttonNoGo && clear()}
        />
      )}
    </div>
  );

  const renderNotification = (message) => {
    return (
      <div className={`cart-notification ${message.type}`}>
        <div className="cart-notification-message">
          <span className={`gt-icon gt-icon-${message.type}`} />
          <span className="message">{message.text}</span>
        </div>
      </div>
    );
  };

  const submitOrder = async () => {
    setOverview(JSON.parse(JSON.stringify(cartOverview)));
    
    try {
      await makeOrder();
      setMessage({type: 'info', text: 'Porudzbina poslata'});
      setFinished(true);
    } catch (httpError) {
      setMessage({type: 'error', text: `Greška: ${httpError.getMessage()}`});
    }
  };

  const renderOrderingPart = () => (
    <div className="cart-order">
      <div className="cart-order-details">
        <div className="cart-order-details-main">
          <DeliveryDates disabled={processing} finished={finished} overview={overview.dayOffset} />
          <DeliveryAddress disabled={processing} finished={finished} overview={overview.outlet} />
        </div>

        <InputField
          fieldStyle={FIELD_STYLE_OUTLINED}
          label="Komentar"
          placeholder="Unesite Vašu napomenu i/ili način plaćanja koji želite"
          value={displayValue(comment, 'comment')}
          onChange={val => setComment(val)}
          multiline
          rows={9}
          disabled={processing || finished}
        />
      </div>

      <div className="cart-order-action">
        {!finished && (
          <Button
            label="Poruči"
            customClass="order-btn"
            onClick={() => !buttonNoGo && submitOrder()}
          />
        )}

        { message && renderNotification(message) }
      </div>
    </div>
  );

  const renderBody = () => {
    if (!contents.length && !finished) {
      return <Empty type={EMPTY_TYPES.CART} text="Vaša korpa je trenutno prazna" />;
    }

    const cartItems = !finished ? items : overview.contents;

    return (
      <Fragment>
        <div className="cart-products">
          { cartItems.map(item => renderCartItem(item)) }
          { renderTotals() }
        </div>

        { renderOrderingPart() }
      </Fragment>
    );
  };

  return (
    <div className="page cart-page">
      { User.loggedIn()
        ? (
          <Fragment>
            <PageHeader
              name="cart-page"
              title="Vaša korpa"
            />
            { loading 
              ? <Loader type="bouncingDots" customClass="products-loader" />
              : renderBody()
            }
          </Fragment>
        )
        : <Empty type={EMPTY_TYPES.AUTH} text="Molimo, ulogujte se" />
      }
    </div>
  );
};

export { CartPageComponent };
