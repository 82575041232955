import React from 'react';

import { Loader } from '../../_reusable/Loader/Loader';
import './CarouselProduct.scss';

export const DummyProduct = (props) => {
  return (
    <div className={`gt-carousel-product dummy`}>
      <div className="gt-carousel-product-contents">
        <div className="gt-product-loading">
          <Loader type="stretchingBars" />
        </div>
      </div>
    </div>
  );
};
