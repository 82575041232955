import { Config } from './Config';

class HelperService {
  getCategoryNameFromId(categoryId) {
    const category = PRELOAD_DATA.categories
      .filter(item => item.id === categoryId);

    return category[0].name;
  }

  redirectToHome() {
    location.href = Config.get('BaseUrl');
  }
}

export const Helper = new HelperService();
