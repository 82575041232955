import React, { useState, Fragment } from 'react';

import { User, Config } from '../../../services/Services';
import { Drawer } from '../_reusable/Drawer/Drawer';
import { TabView } from '../_reusable/TabView/TabView';
import { Accordion } from '../_reusable/Accordion/Accordion';
import { orderBy } from 'lodash';
import { Slug } from "../../../services/SlugService";
import { MENU_ITEMS, USER_MENU_ITEMS_MOBILE, ADMIN_MENU_ITEMS } from '../../../constants/Constants';

import './CategoryMenu.scss';

const CategoryMenuMobile = ({ list }) => {
  const [show, setShow] = useState(false);
  const gtUrl = Config.get('BaseUrl');
  const logged = User.loggedIn();
  const isAdmin = User.isAdmin();

  const toggleShow = () => setShow(!show);

  const renderMainMenu = () => (
    <div className="tab-content main-menu">
      <ul className="nav">
        { MENU_ITEMS.map((item, index) => (
          <li key={`main-nav-item-${index}`}><a href={item.link}>{item.label}</a></li>
        ))}
        { logged && !isAdmin && USER_MENU_ITEMS_MOBILE.map((item, index) => (
          <li key={`main-nav-user-item-${index}`}><a href={item.link}>{item.label}</a></li>
        ))}
        { logged && isAdmin && ADMIN_MENU_ITEMS.map((item, index) => (
          <li key={`main-nav-user-item-${index}`}><a href={item.link}>{item.label}</a></li>
        ))}
      </ul>
    </div>
  );

  const renderCategoriesList = (items) => {
    const sorted = orderBy(items, ['id'], ['asc']);

    return (
      <ul className="nav submenu">
        {sorted.map((item, index) => (
          <li key={`categories-item-${index}`}>
            <span className="submenu-arrow gt-icon-arrow-right" />
            <a
              href={`${gtUrl}/sub-category/${item.id}${Slug.addTrailSlug(item.name)}`}
            >
              {item.name} <span>({item.productCount})</span>
            </a>
          </li>
        ))}
      </ul>
    );
  };

  const prepareAccordion = () => {
    const sorted = orderBy(list, ['id'], ['asc']);

    return sorted.map(category => ({
      label: category.name,
      body: renderCategoriesList(category.subcategories),
    }));
  };

  const renderCategoriesMenu = () => (
    <div className="tab-content categories-menu">
      <Accordion 
        content={prepareAccordion()}
      />
    </div>
  );

  const renderLoginLink = () => {
    return logged
      ? (
        <span
          role="button"
          className="mobile-menu-topbar__title"
          onClick={() => User.logout()}
        >Odjavite se</span>
      )
      : (
        <span
          role="button"
          className="mobile-menu-topbar__title"
          onClick={() => location.href = `${gtUrl}/login`}
        >Prijavite se</span>
      )
  };

  return (
    <Fragment>
      <div
        className="menu-item box-item menu-categories"
        onClick={toggleShow}
      >
        <span className="gt-icon-menu" />
      </div>
      <Drawer open={show} onClose={toggleShow} >
        <div className="mobile-menu">
          <div className="mobile-menu-topbar">
            { renderLoginLink() }
            <span
              className="gt-icon gt-icon-close-circle"
              onClick={toggleShow}
            />
          </div>
          <TabView
            tabs={['Meni', 'Kategorije']}
            content={[
              renderMainMenu(),
              renderCategoriesMenu(),
            ]}
          />
        </div>
      </Drawer>
    </Fragment>
  );
};

export { CategoryMenuMobile };