import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Dialog } from '../../_reusable/Dialog/Dialog';
import { Button } from '../../_reusable/Button/Button';
import { Loader } from '../../_reusable/Loader/Loader';
import { CustomerCommunicator } from '../../../../Communicators/Communicators';
import { AccountForm } from '../../_forms/AccountForm/AccountForm';

import './UserActionDialog.scss';

const DIALOG_TITLE = {
  edit: 'Izmena podataka korisnika',
  delete: 'Brisanje korisnika',
};

const UserActionDialog = ({
  show,
  action,
  onActionDone,
  onClose,
  customers,
}) => {
  const actionType = action ? Object.keys(action)[0] : null;
  const actionData = action ? Object.values(action)[0] : null;
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(null);

  const onCloseDialog = () => {
    if (processing) { return; }

    onClose();
    setSuccess(null);
  };

  const dialogAction = (
    <div className="dialog-action">
      <Button
        customClass="close-dialog-btn"
        label="Zatvori"
        onClick={() => onCloseDialog()}
        disabled={processing}
      />
    </div>
  );

  const renderSuccessMessage = () => (
    <Fragment>
      <span className="success">
        { actionType === 'edit'
          ? 'Uspešno ste izmenili podatke korisnika.'
          : 'Uspešno ste obrisali korisnika.'
        }
      </span>
      { dialogAction }
    </Fragment>
  );

  const renderErrorMessage = () => (
    <Fragment>
      <span className="error">
        Akcija nije uspela! Zatvorite prozor i pokušajte ponovo.
      </span>
      { dialogAction }
    </Fragment>
  );

  const deleteAccount = async () => {
    setProcessing(true);

    try {
      const deleted = await CustomerCommunicator.deleteUser(actionData.id);
      setProcessing(false);
      setSuccess(true);
      onActionDone('delete', actionData);
    } catch(e) {
      setProcessing(false);
      setSuccess(false);
    }
  };

  const onEditComplete = (status, result) => {
    if (status === 'error') {
      setSuccess(false);
    } else {
      setSuccess(true);
      onActionDone('edit', result);
    }
    setProcessing(false);
  };

  const editActionBody = (
    <AccountForm
      customers={customers}
      model={actionData}
      onComplete={onEditComplete}
      onSubmitClick={() => setProcessing(true)}
    />
  );

  const deleteActionBody = (
    actionData && actionData.id
      ? (
        <Fragment>
          <span className="are-you-sure">Da li ste sigurni da želite da obrišete korisnika sa sledećim podacima?</span>
          <div className={`account-data ${processing ? 'processing' : ''}`}>
            <span className="account-data__label">Komitent</span>
            <span className="account-data__value">{actionData.customer.name}</span>

            <span className="account-data__label">Korisničko ime</span>
            <span className="account-data__value">{actionData.username}</span>

            <span className="account-data__label">Lozinka</span>
            <span className="account-data__value">{actionData.password}</span>

            <span className="account-data__label">Email</span>
            <span className="account-data__value">{actionData.email}</span>

            { processing
              ? <Loader type="fadingCircle" customClass="delete-loader" />
              : null
            }
          </div>
          <div className="account-action">
            <Button
              customClass="delete-acc-btn"
              label="Obriši korisnika"
              onClick={deleteAccount}
              disabled={processing}
            />
          </div>
        </Fragment>
      )
      : null
  );

  const noActionBody = (
    <Fragment>
      <span className="are-you-sure">Izabrali ste nepostojeću akciju.</span>
    </Fragment>
  );

  const renderDialogBody = () => {
    if (!actionType || !actionData) {
      return null;
    }

    switch (actionType) {
      case 'edit':
        return editActionBody;
        break;
      case 'delete':
        return deleteActionBody;
        break;
      default:
        return noActionBody;
    }
  }

  const renderMessage = () => (
    success ? renderSuccessMessage() : renderErrorMessage()
  );

  return (
    <Dialog
      title={DIALOG_TITLE[actionType] ?? 'Nepostojeća akcija'}
      show={show}
      onClose={onCloseDialog}
    >
      <div className={`gt-acc-action-dialog ${actionType ?? ''}`}>
        { success === null ? renderDialogBody() : renderMessage() }
      </div>
    </Dialog>
  );
};

UserActionDialog.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.objectOf(PropTypes.any),
  onActionDone: PropTypes.func,
  onClose: PropTypes.func,
  customers: PropTypes.arrayOf(PropTypes.any),
};

UserActionDialog.defaultProps = {
  show: false,
  action: null,
  onActionDone: f => f,
  onClose: f => f,
  customers: [],
};

export { UserActionDialog };
