import slugify from 'react-slugify';

class SlugService {
    /**
     * Adds trailing slug to url, if provided
     * @param str
     * @returns {string|null}
     */
    addTrailSlug(str = null) {
        if (null === str) {
            return null;
        }

        return '/' + slugify(str)
    }


}

export const Slug = new SlugService();
