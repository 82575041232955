import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Field } from '../../../_reusable/Form/Field';
import { InputField } from '../../../_reusable/Input/Input';

const Text = ({
  value, updateValue, name, label,
}) => {
  return (
    <InputField
      fieldStyle="outlined"
      label={label}
      value={value || ''}
      onChange={val => updateValue(name, val)}
    />
  );
};

Text.propTypes = {
  value: PropTypes.string,
  updateValue: PropTypes.func,
  name: PropTypes.string,
};

Text.defaultProps = {
  value: null,
  updateValue: f => f,
  name: '',
};

export const TextField = ({ fieldName, fieldLabel }) => (
  <Field
    name={fieldName}
    component={props => <Text label={fieldLabel} {...props} />}
    required
  />
);

TextField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
};
