import { connect } from 'react-redux';
import * as productActions from '../../../state/product/actions';
import * as cartActions from '../../../state/cart/actions';

import { ProductPageComponent } from './ProductPageComponent';

const mapStateToProps = ({ product, cart }) => ({
  product: product.list.length ? product.list[0] : null,
  loading: product.loading,
});

const mapDispatchToProps = dispatch => ({
  fetchProduct: (id, categoryId, subcategoryId) => dispatch(productActions.getProduct(id, categoryId, subcategoryId)),
  addFavorite: (productId, level) => dispatch(productActions.addToFavorites(productId, level)),
  removeFavorite: (productId, level) => dispatch(productActions.removeFromFavorites(productId, level)),
  addToCart: (productId, qty) => dispatch(cartActions.addItem(productId, qty)),
});

const ProductPage = connect(mapStateToProps, mapDispatchToProps)(ProductPageComponent);

export { ProductPage };
