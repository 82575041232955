import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '../../../components/_reusable/Tooltip/Tooltip';
import { InputField, FIELD_STYLE_OUTLINED } from '../../../components/_reusable/Input/Input';

const PriceRange = ({ curr, onChange }) => {
  const [temp, setTemp] = useState({ from: '', to: '' });
  const [errors, setErrors] = useState({ from: false, to: false });

  const resetError = (which) => {
    if (!errors[which]) { return; }
    setErrors({ ...errors, [which]: false });
  };

  const updateRange = (which, val) => {
    resetError(which);
    setTemp({ ...temp, [which]: val });
  };

  const applyRange = () => {
    const { from, to } = temp;
    const isNum = /^[0-9]+$/;

    if (!isNum.test(from) || !isNum.test(to)) {
      setErrors({ from: !isNum.test(from), to: !isNum.test(to) });
      return;
    }
    if (Number(to) < Number(from)) {
      setErrors({ ...errors, to: true });
      return;
    }

    onChange(temp);
  };

  const resetRange = () => {
    setTemp({ from: '', to: '' });
    onChange(null);
  };

  return (
    <div className="option-price-range">
      <InputField
        fieldStyle={FIELD_STYLE_OUTLINED}
        label="Od"
        value={temp.from || ''}
        onChange={val => updateRange('from', val)}
        error={errors.from}
        disabled={!!curr.from}
      />
      <span className="option-price-range-divider">-</span>
      <InputField
        fieldStyle={FIELD_STYLE_OUTLINED}
        label="Do"
        value={temp.to || ''}
        onChange={val => updateRange('to', val)}
        error={errors.to}
        disabled={!!curr.to}
      />
      { (curr && curr.from && curr.to )
        ? (
          <Tooltip title="Poništi">
            <span 
              className="gt-icon gt-icon-close-circle"
              role="button"
              onClick={resetRange}
            />
          </Tooltip>
        )
        : (
          <Tooltip title="Potvrdi">
            <span 
              className="gt-icon gt-icon-right-arrow"
              role="button"
              onClick={applyRange}
            />
          </Tooltip>
        )
      }
    </div>
  );
};

PriceRange.propTypes = {
  curr: PropTypes.shape({
    from: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    to: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  onChange: PropTypes.func,
};

PriceRange.defaultProps = {
  curr: { from: null, to: null },
  onChange: f => f,
};

export { PriceRange };
