import React from 'react';
import PropTypes from 'prop-types';

import { Field } from '../../../_reusable/Form/Field';
import { InputField } from '../../../_reusable/Input/Input';

const Password = ({
  value,
  updateValue,
  name,
  onEnter,
  model,
  clearFormMessages,
  setFormMessage,
  clearForm,
  setLoading,
  validate,
}) => {
  const restOfFormProps = {
    clearFormMessages,
    setFormMessage,
    clearForm,
  };

  const submitOnEnter = async (e) => {
    if (e.key === 'Enter' || e.charCode === 13) {
      if (!validate()) {
        return;
      }

      e.preventDefault();
      e.target.blur();

      clearFormMessages();
      setLoading(true);
      await onEnter(model, restOfFormProps);
      setLoading(false);
    }
  };

  return (
    <InputField
      fieldStyle="outlined"
      label="Lozinka *"
      value={value || ''}
      onChange={val => updateValue(name, val)}
      onKeyPress={submitOnEnter}
      type="password"
    />
  );
};

Password.propTypes = {
  value: PropTypes.string,
  updateValue: PropTypes.func,
  name: PropTypes.string,
  onEnter: PropTypes.func,
  model: PropTypes.objectOf(PropTypes.any),
  clearFormMessages: PropTypes.func,
  setFormMessage: PropTypes.func,
  clearForm: PropTypes.func,
  setLoading: PropTypes.func,
  validate: PropTypes.func,
};

Password.defaultProps = {
  value: null,
  updateValue: f => f,
  name: '',
  onEnter: f => f,
  model: {},
  clearFormMessages: f => f,
  setFormMessage: f => f,
  clearForm: f => f,
  setLoading: f => f,
  validate: f => f,
};

export const PasswordField = ({ onEnter }) => (
  <Field
    name="password"
    component={props => <Password {...props} onEnter={onEnter} />}
    required
  />
);
