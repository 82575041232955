import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SectionHeader } from '../../components/SectionHeader/SectionHeader';
import { Carousel } from '../../components/_reusable/Carousel/Carousel';
import { CarouselProduct } from '../../components/Product/CarouselProduct/CarouselProduct';
import { DummyProduct } from '../../components/Product/CarouselProduct/DummyProduct';

const slidesLength = () => {
  const width = window.innerWidth;

  if (width >= 1000) {
    return 5;
  } else if (width >= 850 && width < 1000) {
    return 4;
  } else if (width >= 600 && width < 850) {
    return 3;
  } else if (width >= 450 && width < 600) {
    return 2;
  } else {
    return 1;
  }
};

const HighlightedProducts = ({ customClass, products, logged }) => {
  const [slides, setSlides] = useState(slidesLength());

  const onResize = (e) => {
    const toSet = slidesLength();
    setSlides(toSet);
  };

  const renderDummies = () => {
    return Array(30).fill(null).map((dummy, index) => (
      <div key={`dummy-${index}`} className={`highlighted-products__product ${logged ? 'full' : ''}`}>
        <DummyProduct />
      </div>
    ));
  };

  const renderProducts = () => {
    const toPresent = products.slice(0, 30);

    return toPresent.length
      ? toPresent.map(product => (
          <div key={product.id} className={`highlighted-products__product ${logged ? 'full' : ''}`}>
            <CarouselProduct data={product} hideTags />
          </div>
        ))
      : null;
  };

  return (
      <div className={`highlighted-products ${customClass}`}>
        <SectionHeader title="Izdvajamo iz ponude" />
        <Carousel
            className="highlighted-products-carousel"
            customClass="mp-highlighted section"
            slidesToShow={slides}
            rows={3}
            autoplay
            autoplaySpeed={6000}
            onResize={onResize}
        >
          { products ? renderProducts() : renderDummies() }
        </Carousel>
      </div>
  )
};

HighlightedProducts.propTypes = {
  customClass: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.any),
  logged: PropTypes.bool,
};

HighlightedProducts.defaultProps = {
  customClass: '',
  products: null,
  logged: false,
};

export { HighlightedProducts };
