import React from 'react';

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

import './SocialShare.scss';

export const SOCIAL_SHARE_TYPES = {
  MINI: 'mini',
};

export const SOCIAL_EMPTY_CONFIG = {
  FACEBOOK: { quote: '', hashtag: '' },
  TWITTER: { title: '', via: 'GT Hemija', hashtags: [] },
  LINKEDIN: { title: '', summary: '', source: 'www.gthemija.com' },
  VIBER: { title: '', separator: ' '},
  WHATSAPP: { title: '', separator: ' '},
  EMAIL: { subject: '', body: '', separator: ' '},
};

export const SocialShare = ({ url, config }) => {
  const conf = config ?? SOCIAL_EMPTY_CONFIG;

  return (
    <div className="gt-social-share mini">
      <FacebookShareButton url={url} openShareDialogOnClick {...conf?.FACEBOOK} >
        <FacebookIcon size={25} round={true} />
      </FacebookShareButton>

      <TwitterShareButton url={url} openShareDialogOnClick {...conf?.TWITTER} >
        <TwitterIcon size={25} round={true} />
      </TwitterShareButton>

      <LinkedinShareButton url={url} openShareDialogOnClick {...conf?.LINKEDIN} >
        <LinkedinIcon size={25} round={true} />
      </LinkedinShareButton>

      <ViberShareButton url={url} openShareDialogOnClick {...conf?.VIBER} >
        <ViberIcon size={25} round={true} />
      </ViberShareButton>

      <WhatsappShareButton url={url} openShareDialogOnClick {...conf?.WHATSAPP} >
        <WhatsappIcon size={25} round={true} />
      </WhatsappShareButton>

      <EmailShareButton url={url} openShareDialogOnClick {...conf?.EMAIL} >
        <EmailIcon size={25} round={true} />
      </EmailShareButton>
    </div>
  );
};
