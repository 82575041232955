import React from 'react';
import PropTypes from 'prop-types';

import './Empty.scss';

export const EMPTY_TYPES = {
  CART: 'cart',
  SEARCH: 'search',
  AUTH: 'auth',
  HISTORY: 'history',
  USER: 'user',
};

const Empty = ({ text, type }) => (
  <div className="gt-empty">
    <span className={`gt-empty__image ${type}`} />
    <h2>{text}</h2>
  </div>
);

Empty.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
};

Empty.defaultProps = {
  type: EMPTY_TYPES.CART,
};

export { Empty };
