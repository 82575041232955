import React from 'react';
import PropTypes from 'prop-types';

import Slider from 'infinite-react-carousel';

import './Carousel.scss';

const Carousel = ({
  children,
  customClass,
  dots,
  className,
  type,
  slidesToShow,
  rows,
  autoplay,
  autoplaySpeed,
  onResize,
}) => {
  const sliderProps = {
    dots: dots,
    className: className,
    prevArrow: <button><span className="gt-icon gt-icon-prev" /></button>,
    nextArrow: <button><span className="gt-icon gt-icon-next" /></button>,
    slidesToShow,
    autoplay,
    autoplaySpeed,
    onResize,
    rows,
  };

  return (
    <div className={`gt-carousel ${customClass} ${type}`}>
      <Slider {...sliderProps} >
        { children }
      </Slider>
    </div>
  );
};

Carousel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  dots: PropTypes.bool,
  rows: PropTypes.number,
  customClass: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  slidesToShow: PropTypes.number,
  autoplay: PropTypes.bool,
  autoplaySpeed: PropTypes.number,
  onResize: PropTypes.func,
};

Carousel.defaultProps = {
  children: null,
  dots: false,
  rows: 1,
  customClass: '',
  className: '',
  type: '',
  slidesToShow: 1,
  autoplay: false,
  autoplaySpeed: 3000,
  onResize: f => f,
};

export { Carousel };
