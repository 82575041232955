export class PageHeaderUpdater {
  constructor(display, updateFunc) {
    this.display = display;
    this.update = updateFunc;
    this.changeLoadChunk = this.changeLoadChunk.bind(this);
    this.changeSortingByPrice = this.changeSortingByPrice.bind(this);
    this.changePriceRange = this.changePriceRange.bind(this);
    this.changeDateRange = this.changeDateRange.bind(this);
    this.changeSearch = this.changeSearch.bind(this);
    this.changeOrderStatus = this.changeOrderStatus.bind(this);
  }

  changeLayout(isList) {
    this.update({
      ...this.display,
      listView: isList,
    });
  }

  changeLoadChunk(num) {
    this.update({
      ...this.display,
      chunk: num,
      offset: (this.display.offset < num) ? num : this.display.offset,
    });
  }

  changeSortingByPrice(sortVal) {
    const newDisplay = { ...this.display };
    delete newDisplay.sortByPrice;

    if (sortVal && sortVal !== 'default') {
      newDisplay.sortByPrice = sortVal;
    }

    this.update({ ...newDisplay });
  }

  changePriceRange = (range) => {
    const newDisplay = { ...this.display };
    delete newDisplay.priceRange;

    if (range) {
      newDisplay.priceRange = range;
    }

    this.update({ ...newDisplay });
  }

  changeDateRange = (range) => {
    const newDisplay = { ...this.display };
    delete newDisplay.dateRange;

    if (range) {
      newDisplay.dateRange = range;
    }

    this.update({ ...newDisplay });
  }

  changeOrderStatus(statusVal) {
    const newDisplay = { ...this.display };
    delete newDisplay.orderStatus;

    if (statusVal !== null && statusVal !== 'default') {
      newDisplay.orderStatus = String(statusVal);
    }

    this.update({ ...newDisplay });
  }

  changeSearch(term) {
    const newDisplay = { ...this.display };

    if (term && term.length) {
      newDisplay.search = term;
    } else {
      delete newDisplay.search;
    }

    this.update({ ...newDisplay });
  }
}
