import { Config, http } from '../services/Services';

const baseUrl = Config.get('ApiBaseUrl');

class Base {
  constructor(path) {
    this.url = baseUrl + path;
  }

  async fetchAll(options = {}) {
    return await http.get(this.url, { ...options });
  }

  async fetch(id) {
    return await http.get(`${this.url}/${id}`);
  }
}

export { Base };
