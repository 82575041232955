import React, { useState, Fragment } from 'react';

import { User, Format } from '../../../services/Services';
import { LoginForm } from '../../components/_forms/LoginForm/LoginForm';
import { ForgotPasswordForm } from '../../components/_forms/ForgotPasswordForm/ForgotPasswordForm';
import { RegistrationForm } from '../../components/_forms/RegistrationForm/RegistrationForm';

import './LoginPage.scss';

const logged = User.loggedIn();
const debt = User.get('debt');

export const LoginPage = () => {
  const [login, setLogin] = useState(true);
  const [forgot, setForgot] = useState(false);

  const renderLoginForm = () => {
    return !forgot
      ? <LoginForm onForgotPass={() => setForgot(true)} />
      : <ForgotPasswordForm onBackToLogin={() => setForgot(false)} />
  };

  const renderForms = () => (
    <Fragment>
      <div className="available-forms">
        <span
          className={`${login ? 'active' : ''}`}
          onClick={() => setLogin(true)}
        >Prijavite se</span>
        <span
          className={`${!login ? 'active' : ''}`}
          onClick={() => setLogin(false)}
        >Registrujte se</span>
      </div>
      { login
        ? renderLoginForm()
        : <RegistrationForm />
      }
    </Fragment>
  );

  const renderUserMessage = () => (
    <Fragment>
      <h2 className="login-message">Trenutno ste prijavljeni na naš sajt.</h2>
      <div className="debt-message-wrapper">
        <div className="debt-message">
          <span className="gt-icon gt-icon-info" />
          <span>Poštovani, Vaše dugovanje iznosi</span>
          <span>{Format.toCurrency(debt)}</span>
          <span>Molimo Vas da izmirite dugovanje u najkraćem mogućem roku.</span>
        </div>
      </div>
    </Fragment>
  );

  return (
    <div className="page login-page">
      { logged
        ? renderUserMessage()
        : renderForms()
      }
    </div>
  );
};