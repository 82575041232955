import React from 'react';
import PropTypes from 'prop-types';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import * as CONSTANTS from '../InputConstants';

import '../Input.scss';

const SelectField = ({
  fieldStyle,
  value,
  onChange,
  options,
  label,
  disabled,
}) => {
  const renderOptions = () => options.map(option => (
    <MenuItem
      value={option.value}
      key={`option-${option.value}`}
    >
      {option.label}
    </MenuItem>
  ));

  const returnNakedSelect = () => (
    <Select
      id="simple-select"
      value={value}
      onChange={onChange}
    >
      {renderOptions()}
    </Select>
  );

  const returnOutlinedSelect = () => (
    <TextField
      id="outlined-select"
      select
      label={label}
      value={value}
      onChange={onChange}
      variant={CONSTANTS.FIELD_STYLE_OUTLINED}
      disabled={disabled}
    >
      {renderOptions()}
    </TextField>
  );

  const renderField = () => {
    switch (fieldStyle) {
      case CONSTANTS.FIELD_STYLE_NAKED:
        return returnNakedSelect();
      case CONSTANTS.FIELD_STYLE_OUTLINED:
        return returnOutlinedSelect();
      default:
        return '';
    }
  };

  return (
    <div className={`gt-select ${fieldStyle} ${!label ? 'no-label' : ''}`}>
      { renderField() }
    </div>
  );
};

SelectField.propTypes = {
  fieldStyle: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
};

SelectField.defaultProps = {
  fieldStyle: 'naked',
  label: '',
  value: '',
  onChange: f => f,
  disabled: false,
};

export { SelectField };
