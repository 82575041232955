import * as Types from './types';
import { Order } from "../../services/OrderService";
import { OrderCommunicator } from "../../Communicators/OrderCommunicator";
import * as CartTypes from '../cart/types';

export const loadDeliveryDates = () => async (dispatch) => {
  const value = await Order.getDeliveryDates();

  dispatch({
    type: Types.DELIVERY_DATES_LOAD,
    payload: value,
  });
};

export const setDeliveryDayOffset = (value) => async (dispatch) => {
  dispatch({
    type: Types.SET_DELIVERY_DAY_OFFSET,
    payload: value,
  });
};

export const setDeliveryOutlet = (value) => (dispatch) => {
  dispatch({
    type: Types.SET_DELIVERY_OUTLET,
    payload: value,
  });
};

export const setComment = (value) => async (dispatch) => {
  dispatch({
    type: Types.SET_COMMENT,
    payload: value,
  });
};

export const makeOrder = () => async (dispatch, getState) => {
  const { dayOffset, comment, outlet } = getState().order;

  dispatch({ type: Types.ORDER_PROCESSING, payload: true });

  try {
    await OrderCommunicator.makeOrder(dayOffset, comment, outlet);
  } catch (httpError) {
    dispatch({ type: Types.ORDER_PROCESSING, payload: false });
    throw httpError;
  }

  dispatch({
    type: CartTypes.CART_RESET
  });

  dispatch({ type: Types.ORDER_PROCESSING, payload: false });
};

export const loadOrderHistory = () => async (dispatch, getState) => {
  dispatch({ type: Types.SET_LOADING_ORDER_HISTORY, payload: true });

  try {
    const history = await OrderCommunicator.getOrderHistory();
    dispatch({ type: Types.LOAD_ORDER_HISTORY, payload: history });
  } catch(httpError) {
    dispatch({ type: Types.SET_LOADING_ORDER_HISTORY, payload: false });
    throw httpError;
  }
  
  dispatch({ type: Types.SET_LOADING_ORDER_HISTORY, payload: false });
};
