import axios from 'axios';
import qs from 'qs';
import { Config } from './Config';
import { User } from '../services/Services';
import {HttpError} from "../../Error/HttpError";

class HttpService {
  constructor(config, axiosLib, qsLib) {
    this.config = config;
    this.axios = axiosLib;
    this.qs = qsLib;
    this.middleware = [];
  }

  addMiddleware(middleware) {
    this.middleware.push(middleware);
  }

  get(url, params = {}) {
    return this.sendRequest(url, 'GET', { params });
  }

  downloadGet(url, params = {}) {
    return this.sendRequest(url, 'GET', { params, responseType: 'blob' });
  }

  jsonDelete(url, params = {}, data = {}) {
    const headers = {
      'Content-Type': 'application/json',
    };

    return this.sendRequest(url, 'DELETE', { params, data, headers });
  }

  delete(url, params = {}, data = {}) {
    return this.sendRequest(url, 'DELETE', { params, data });
  }

  post(url, data = {}, params = {}, options = {}) {
    return this.sendRequest(url, 'POST', { data, params, ...options });
  }

  jsonPost(url, data = {}, params = {}, options = {}) {
    const combinedOptions = {
      ...options,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return this.sendRequest(url, 'POST', { data, params, ...combinedOptions });
  }

  jsonPut(url, data = {}, params = {}, options = {}) {
    const combinedOptions = {
      ...options,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return this.sendRequest(url, 'PUT', { data, params, ...combinedOptions });
  }

  put(url, data = {}, params = {}, options = {}) {
    return this.sendRequest(url, 'PUT', { data, params, ...options });
  }

  patch(url, data = {}, params = {}, options = {}) {
    return this.sendRequest(url, 'PATCH', { data, params, ...options });
  }

  jsonPatch(url, data = {}, params = {}, options = {}) {
    const combinedOptions = {
      ...options,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return this.sendRequest(url, 'PATCH', { data, params, ...combinedOptions });
  }

  sendRequest(url, method, passedOptions) {
    const headers = {
      ...this.getBaseHeaders(),
      ...passedOptions.headers,
    };

    const options = {
      ...passedOptions,
      method,
      params: {
        ...passedOptions.params,
      },
      data: (headers['Content-Type'] === 'application/json')
        ? { ...passedOptions.data }
        : this.qs.stringify({ ...passedOptions.data }),
      headers,
    };

    let data = { url, ...options };
    if (this.middleware.length > 0) {
      this.middleware.forEach((middleware) => {
        data = middleware.handle(data);
      });
    }

    const result = {
      url,
      ...data,
    };

    if (headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      result.paramsSerializer = params => this.qs.stringify(params);
    }

    return this.axios(result)
      .then(response => (response && response.data ? response.data : null))
      .catch(({ response }) => {
        let errorObj = {};
        if (response && response.data) {
          errorObj = { ...response.data };
        }

        if (response) errorObj.statusCode = response.status;

        throw new HttpError(errorObj);
      });
  }

  getBaseHeaders() {
    const userToken = User.getAccessToken();

    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    if(null !== userToken)
    {
      headers['x-auth-token'] = userToken;
    }

    return headers;
  }
}

export const http = new HttpService(Config, axios, qs);
