import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Field } from '../../../_reusable/Form/Field';
import { InputField, FIELD_STYLE_OUTLINED } from '../../../_reusable/Input/Input';

const Message = ({
  value, updateValue, name,
}) => {
  return (
    <InputField
      fieldStyle={FIELD_STYLE_OUTLINED}
      label="Poruka *"
      value={value || ''}
      onChange={val => updateValue(name, val)}
      multiline
      rows={9}
    />
  );
};

Message.propTypes = {
  value: PropTypes.string,
  updateValue: PropTypes.func,
  name: PropTypes.string,
};

Message.defaultProps = {
  value: null,
  updateValue: f => f,
  name: '',
};

export const MessageField = () => (
  <Field
    name="body"
    component={props => <Message {...props} />}
    required
  />
);
