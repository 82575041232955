import React, { Fragment } from 'react';

import { BrandsCarousel } from '../../components/BrandsCarousel/BrandsCarousel';
import { ScrollToTop } from '../../components/_reusable/ScrollToTop/ScrollToTop';
import { Config } from '../../../services/Services';

import './Footer.scss';

const FooterCategories = () => PRELOAD_DATA.categories.map(cat => (
  <a key={cat.id} href={`${Config.get('BaseUrl')}/category/${cat.id}`}>
    <span>{cat.name}</span>
  </a>
));

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Fragment>
      <BrandsCarousel />

      <section className="divider"></section>

      <section className="sitemap container">
        <div className="gthemija">
          <div className="footer-logo">
            <a href={`${Config.get('BaseUrl')}`}><span className="logo">logo</span></a>
          </div>
          <div className="line">
            <span>Sredstva za čišćenje i održavanje higijene.</span>
          </div>
          <div className="line multispan">
            <span>Adresa:</span>
            <span>Auto put za Zagreb 20, 11080 Zemun</span>
          </div>
          <div className="social-links">
            <a className="facebook" href="#"><span className="gt-icon gt-icon-facebook" /></a>
            <a className="twitter" href="#"><span className="gt-icon gt-icon-twitter" /></a>
            <a className="linkedin" href="#"><span className="gt-icon gt-icon-linkedin" /></a>
          </div>
        </div>
        <div className="product-categories">
          <h5>Kategorije</h5>
          <FooterCategories />
        </div>
        <div>
          <h5>Kontakt telefoni</h5>
          <span>+381 (0)11 2177017</span>
          <span>+381 (0)11 2177018</span>
          <span>+381 (0)11 7180536</span>
          <span>+381 (0)63 342051</span>
          <span>+381 (0)63 1063399</span>
          <span>+381 (0)63 418382</span>
        </div>
        <div>
          <h5>Kontakt e-mail</h5>
          <a href="mailto:prodaja@gthemija.com"><span className="text-line">Prodaja</span></a>
          <a href="mailto:komercijala@gthemija.com"><span className="text-line">Komercijala</span></a>
          <a href="mailto:office@gthemija.com"><span className="text-line">Office</span></a>
        </div>
        <div>
          <h5>Radno vreme</h5>
          <div className="line multispan">
            <span>Radnim danima:</span>
            <span>08:00 - 15:00h</span>
          </div>
          <div className="line multispan">
            <span>Subota:</span>
            <span>Neradan dan</span>
          </div>
          <div className="line multispan">
            <span>Nedelja:</span>
            <span>Neradan dan</span>
          </div>
        </div>
      </section>

      <section className="copyright">
        <span>{`© ${currentYear} GT Hemija. Sva prava zadržana.`}</span>
      </section>

      <ScrollToTop />
    </Fragment>
  );
}

export { Footer };
