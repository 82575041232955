import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { orderBy } from 'lodash';

import './Option.scss';

export const Option = ({ label, children }) => (
  <div className="gt-page-header-options__option">
    <span className="option-label">{ label }</span>
    { children && children }
  </div>
);

Option.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

Option.defaultProps = {
  label: '',
  children: null,
};

/*
* Class applying all sorting and filtering
*/
class OptionsHandler {
  static pipe = (...fns) => x => fns.reduce((v, f) => f(v), x);

  static filterByPrice = (entry) => {
    const [data, options] = [...entry];
    let processed = data ? [...data] : [];

    if (options.priceRange) {
      const { from, to } = options.priceRange;
      processed = processed.filter(item => item.price >= Number(from) && item.price <= Number(to));
    }

    return [processed, options];
  }

  static filterByDate = (entry) => {
    const [data, options] = [...entry];
    let processed = data ? [...data] : [];

    if (options.dateRange) {
      const { from, to } = options.dateRange;
      processed = processed.filter((item) => {
        const creationDate = moment.unix(item.createdAt);
        return !!(creationDate >= from && creationDate <= to);
      });
    }

    return [processed, options];
  }

  static filterBySearch = (entry) => {
    const [data, options] = [...entry];
    let processed = [...data];

    if (options.search) {
      processed = processed.filter(item => item.name.toLowerCase().includes(options.search.toLowerCase()));
    }

    return [processed, options];
  }

  static sortByPrice = (entry) => {
    const [data, options] = [...entry];
    let processed = [...data];

    if (options.sortByPrice) {
      processed = orderBy(processed, ['price'], [options.sortByPrice]);
    }

    return [processed, options];
  }

  static filterByOrderStatus = (entry) => {
    const [data, options] = [...entry];
    let processed = [...data];

    if (options.orderStatus) {
      processed = processed.filter(item => Number(item.statusId) === Number(options.orderStatus));
    }

    return processed;
  }

  apply = (data, options) => OptionsHandler.pipe(
    OptionsHandler.filterByPrice,
    OptionsHandler.filterByDate,
    OptionsHandler.filterBySearch,
    OptionsHandler.sortByPrice,
    OptionsHandler.filterByOrderStatus,
  )([data, options]);
};

export const OptionsService = new OptionsHandler();
