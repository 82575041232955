import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../_reusable/Button/Button';
import { InputField } from '../_reusable/Input/Input';

import './QuantitySetter.scss';

const QuantitySetter = ({
  customClass,
  disabled,
  quantity,
  updateQuantity,
}) => {
  return (
    <div className={`gt-quantity ${customClass}`}>
      <Button
        customClass="gt-quantity-control"
        label={<span className="gt-icon gt-icon-minus"></span>}
        onClick={() => updateQuantity(quantity - 1)}
        disabled={disabled}
      />
      <InputField
        type="number"
        fieldStyle="outlined"
        label="Količina"
        value={quantity}
        onChange={updateQuantity}
        disabled={true}
      />
      <Button
        customClass="gt-quantity-control"
        label={<span className="gt-icon gt-icon-plus"></span>}
        onClick={() => updateQuantity(quantity + 1)}
        disabled={disabled}
      />
    </div>
  );
};

QuantitySetter.propTypes = {
  customClass: PropTypes.string,
  disabled: PropTypes.bool,
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateQuantity: PropTypes.func,
};

QuantitySetter.defaultProps = {
  customClass: '',
  disabled: false,
  quantity: 0,
  updateQuantity: f => f,
};

export { QuantitySetter };
