import React from 'react';
import PropTypes from 'prop-types';

import './SectionHeader.scss';

const SectionHeader = ({
  customClass,
  textPosition,
  title,
}) => {
  return (
    <div className={`gt-section-header ${customClass}`}>
      <div className={`header-title text-${textPosition}`}>
        <h2>{title}</h2>
      </div>
    </div>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string,
  customClass: PropTypes.string,
  textPosition: PropTypes.string,
};

SectionHeader.defaultProps = {
  title: '',
  customClass: '',
  textPosition: 'left',
};

export { SectionHeader };
