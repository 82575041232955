import React, { useState } from 'react';
import { Config } from '../../../services/Config';
import Collapse from '@material-ui/core/Collapse';
import { orderBy } from 'lodash';
import { Slug } from "../../../services/SlugService";
import './CategoryMenu.scss';

const CategoryMenuWeb = ({ list }) => {
  const [show, setShow] = useState(false);
  const gtUrl = Config.get('BaseUrl');

  const toggleShow = () => setShow(!show);

  const renderSubmenu = items => (
    <div className="popup-wrapper">
      <div className="popup-submenu">
        {renderMenu(items, true, 'sub-category')}
      </div>
    </div>
  ); 

  const renderMenu = (items, sub = false, location = 'category') => {
    const sorted = orderBy(items, ['id'], ['asc']);
    return (
      <ul className={`nav ${sub ? 'submenu' : ''}`}>
        {sorted.map((item, index) => (
          <li key={`nav-item-${sub ? 'submenu-' : ''}${index}`}>
            {sub ? <span className="submenu-arrow gt-icon-arrow-right" /> : ''}
            <a 
              className={`${!sub ? 'menu-label' : ''}`}
              href={`${gtUrl}/${location}/${item.id}${Slug.addTrailSlug(item.name)}`}
            >
              {item.name} <span>({item.productCount})</span>
            </a>
            {item.subcategories ? renderSubmenu(item.subcategories) : ''}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="main-category-list" onMouseEnter={toggleShow} onMouseLeave={toggleShow}>
      <a className="cat-label">Kategorije</a>
      <div className="categs">
        <Collapse in={show}>
          <div className="cat-list">
            {renderMenu(list)}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export { CategoryMenuWeb };