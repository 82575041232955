import React from 'react';

import { Config } from '../../../services/Services';
import HPlatform, { HMap, HMapMarker } from 'react-here-map';

import './Map.scss';

const COORDS_LATITUDE = 44.8340683;
const COORDS_LONGITUDE = 20.3617283;

// I think this ICON thing doesn't work, but it's here just in case
const ICON = '<svg width="24" height="24" ' +
  'xmlns="http://www.w3.org/2000/svg">' +
  '<rect stroke="white" fill="#1b468d" x="1" y="1" width="22" ' +
  'height="22" /><text x="12" y="18" font-size="12pt" ' +
  'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
  'fill="white">GT</text></svg>';

const GtMap = () => {
  const coords = { lat: COORDS_LATITUDE, lng: COORDS_LONGITUDE };

  const renderHereMap = (
    <HPlatform
      app_id={`${Config.get('gthHerePub')}`}
      app_code={`${Config.get('gthHerePriv')}`}
      zoomLevel={15}
      interactive
      useHTTPS
      includeUI
    >
      <HMap
        mapOptions={{ center: coords, zoom: 15 }}
      >
        <HMapMarker coords={coords} icon={ICON} />
      </HMap>
    </HPlatform>
  );

  return (
    <div className="gt-map">
      { renderHereMap }
    </div>
  );
};

export { GtMap };