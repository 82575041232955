import React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Radio } from "@material-ui/core";

import '../Input.scss';

/*
** RADIO FIELD
*/

const RadioField = ({
  value, label, disabled,
}) => {
  return (
    <div className="gt-radio">
      <FormControlLabel
        value={value}
        control={<Radio color="primary" />}
        label={label}
        disabled={disabled}
      />
    </div>
  );
};

RadioField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

RadioField.defaultProps = {
  value: null,
  label: '',
  disabled: false,
};

/*
** RADIO GROUP FIELD
*/

const RadioGroupField = ({
  value, name, row, onChange, children,
}) => {
  return (
    <div className="gt-radio-group">
      <RadioGroup
        row
        aria-label={name}
        name={name}
        value={value}
        onChange={onChange}
      >
        { children }
      </RadioGroup>
    </div>
  );
};

RadioGroupField.propTypes = {
  row: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

RadioGroupField.defaultProps = {
  row: false,
  name: 'radiogroup',
  value: null,
  onChange: f => f,
  children: null,
};

export { RadioField };

export { RadioGroupField };
