import React from 'react';
import PropTypes from 'prop-types';

import { User, Config, Format } from '../../../../services/Services';
import { ProductTags } from '../../ProductTags/ProductTags';
import { Slug } from "../../../../services/SlugService";

import './CarouselProduct.scss';

const CarouselProduct = ({
  customClass,
  data,
  hideTags,
}) => {
  const NO_PICTURE_PATH = Config.get('noPicturePath');
  const logged = User.loggedIn();
  const gtUrl = Config.get('BaseUrl');

  const {
    id, name, imagePath, price, priceWithVat, stock, favorite,
  } = data;

  const renderFooter = () => (
    <div className="gt-carousel-product-contents-footer">
      <div className="price">
        <span className="display-price">{Format.toCurrency(price)}</span>
        {/*
          priceWithVat && <span className="old-price">{Format.toCurrency(priceWithVat)}</span>
        */}
      </div>
    </div>
  );

  return (
    <div className={`gt-carousel-product ${customClass}`}>
      <div className="gt-carousel-product-contents">
        <div className="gt-carousel-product-contents-body">
          <div className="img-wrapper">
            <a href={`${gtUrl}/product/${id}${Slug.addTrailSlug(name)}`}>
            <img src={imagePath || NO_PICTURE_PATH} alt="product image" />
            { !hideTags && <ProductTags product={data} /> }
            </a>
          </div>
          <a href={`${gtUrl}/product/${id}${Slug.addTrailSlug(name)}`}><span className="line-item name">{name}</span></a>
        </div>

        { logged && renderFooter() }
      </div>
    </div>
  );
};

CarouselProduct.propTypes = {
  customClass: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  hideTags: PropTypes.bool,
};

CarouselProduct.defaultProps = {
  customClass: '',
  hideTags: false,
};

export { CarouselProduct };
