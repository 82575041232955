import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Loader } from '../../_reusable/Loader/Loader';

import './BulkAddListItem.scss';

const BulkAddListItem = ({
  addToCart,
  data,
  processing,
}) => {
  const [notify, setNotify] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const forCart = Object.keys(data).length;
  const baseClass = 'page-products-bulk';
  const statusClass = (forCart > 0) ? '' : 'empty';
  const isProcessing = !!(processing && waiting);

  useEffect(() => {
    if (notify) {
      setTimeout(() => setNotify(!notify), 2000);
    }
  }, [notify]);

  useEffect(() => {
    if (!processing && waiting) {
      setWaiting(false);
      setNotify(!notify);
    }
  }, [processing]);

  const addAll = () => {
    setWaiting(true);
    addToCart();
  };

  return (
    <div key="bulk-add" className={`${baseClass} ${statusClass}`}>
      <div className={`${baseClass}-content`}>
        <div className={`${baseClass}-content-data`}>
          <div className="info">
            <div className="info-message">
              <span className="gt-icon gt-icon-info" />
              <span className="message">
                { !forCart
                  ? 'Niste odabrali nijedan proizvod'
                  : `Odabrali ste ${forCart} proizvod${forCart > 1 ? 'a' : ''}`
                }
              </span>
            </div>
          </div>
        </div>
        <div
          className={`${baseClass}-content-button`}
          role="button"
          onClick={addAll}
        >
          { isProcessing
            ? <Loader type="fadingCircle" />
            : <span className={`gt-icon gt-icon-${notify ? 'success' : 'add-to-cart'}`} />
          }
          {/* <span className={`gt-icon gt-icon-${notify ? 'success' : 'add-to-cart'}`} /> */}
          <span>Stavi u korpu</span>
        </div>
      </div>
    </div>
  );
};

BulkAddListItem.propTypes = {
  addToCart: PropTypes.func,
  data: PropTypes.objectOf(PropTypes.any),
  processing: PropTypes.bool,
};

BulkAddListItem.defaultProps = {
  addToCart: f => f,
  data: {},
  processing: false,
};

export { BulkAddListItem };
