import React, { useState } from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const Accordion = ({ customClass, content }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={`gt-accordion ${customClass ? customClass : ''}`}>
      {content.map((item, index) => {
        const { label, body, actions } = item;

        return (
          <ExpansionPanel key={`panel${index}`} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              { typeof label === 'string'
                ? <Typography>{label}</Typography>
                : label
              }
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              { body }
            </ExpansionPanelDetails>
            { actions && (
              <ExpansionPanelActions>
                { actions }
              </ExpansionPanelActions>
            )}
          </ExpansionPanel>
        );
      })}
    </div>
  );
};

export { Accordion };