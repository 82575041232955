import { connect } from 'react-redux';
import * as cartActions from "../../../state/cart/actions";
import * as orderActions from "../../../state/order/actions";

import { CartPageComponent } from './CartPageComponent';

const mapStateToProps = ({ cart, order }) => ({
  loading: cart.loading,
  updating: cart.updating,
  contents: cart.contents,
  total: cart.total,
  totalWithoutVat: cart.totalWithoutVat,
  totalVat: cart.totalVat,
  comment: order.comment,
  deliveryDates: order.deliveryDates,
  dayOffset: order.dayOffset,
  processing: order.processing,
  cartOverview: {...cart, ...order},
});

const mapDispatchToProps = dispatch => ({
  loadCart: async () => dispatch(cartActions.loadCart()),
  removeItem: async (productId) => dispatch(cartActions.removeItem(productId)),
  clear: async () => dispatch(cartActions.clear()),
  changeQty: async (productId, qty) => dispatch(cartActions.changeQty(productId, qty)),
  setComment: async (comment) => dispatch(orderActions.setComment(comment)),
  makeOrder: async () => dispatch(orderActions.makeOrder()),
});

const CartPage = connect(mapStateToProps, mapDispatchToProps)(CartPageComponent);

export { CartPage };
