import React, { createContext, Component } from 'react';
import PropTypes from 'prop-types';

import { Loader } from '../Loader/Loader';
import { FormFooter } from './FormFooter';
import './Form.scss';

export const FormContext = createContext({});

export class Form extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
    footer: PropTypes.func,
    customClass: PropTypes.string,
    model: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    children: null,
    footer: null,
    customClass: '',
    model: null,
  };

  constructor(props) {
    super(props);

    this.validators = {};

    this.updateValue = this.updateValue.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.subscribeValidator = this.subscribeValidator.bind(this);
    this.unsubscribeValidator = this.unsubscribeValidator.bind(this);
    this.validate = this.validate.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.setFormMessage = this.setFormMessage.bind(this);
    this.clearFormMessages = this.clearFormMessages.bind(this);

    this.state = {
      model: {},
      formMessages: [],
      fieldErrors: {},
      loading: false,
      updateValue: this.updateValue,
      clearForm: this.clearForm,
      subscribeValidator: this.subscribeValidator,
      unsubscribeValidator: this.unsubscribeValidator,
      validate: this.validate,
      setFieldErrors: this.setFieldErrors,
      setFormMessage: this.setFormMessage,
      clearFormMessages: this.clearFormMessages,
      setLoading: this.setLoading,
    };
  }

  componentDidMount() {
    if (this.props.model) {
      this.setState({ model: this.props.model });
    }
  }

  updateValue(name, value, errorName) {
    this.setState((state) => {
      const fieldErrors = { ...state.fieldErrors };
      delete fieldErrors[name];

      return { model: { ...state.model, [name]: value }, fieldErrors };
    });
  }

  clearForm() {
    this.setState({
      model: {},
      formMessages: [],
      fieldErrors: {},
      loading: false,
    });
  }

  subscribeValidator(key, validator) {
    if (typeof validator !== 'function' || typeof key !== 'string') {
      return;
    }

    this.validators[key] = validator;
  }

  unsubscribeValidator(key) {
    delete this.validators[key];
  }

  validate() {
    return Object.keys(this.validators)
      .map(validatorKey => this.validators[validatorKey]())
      .reduce((isValid, current) => isValid && current, true);
  }

  setFieldErrors(field, errors) {
    this.setState((prevState) => {
      const fieldErrors = { ...prevState.fieldErrors };

      if ((!Array.isArray(errors) || !errors.length) && fieldErrors[field]) {
        delete fieldErrors[field];
        return { fieldErrors };
      }

      fieldErrors[field] = errors;

      return { fieldErrors };
    });
  }

  setFormMessage(type, message) {
    this.setState((prevState) => {
      const formMessages = prevState.formMessages.slice();
      formMessages.unshift({ type, message });
      return { formMessages };
    });
  }

  clearFormMessages() {
    this.setState({ formMessages: [] });
  }

  setLoading(isLoading) {
    this.setState({ loading: isLoading });
  }

  renderFormMessages() {
    const { formMessages } = this.state;

    if (!formMessages.length) {
      return;
    }

    return (
      <div className="gt-form-messages">
        { formMessages.map((fm, index) => {
          return (
            <div key={`fm.type-${index}`} className={`message ${fm.type}`}>
              <span className={`gt-icon-${fm.type === 'error' ? 'warning' : 'info'}`} />
              <span>{fm.message}</span>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { children, footer, customClass } = this.props;
    const { loading } = this.state;
    const classes = `${customClass}${loading ? ' loading' : ''}`;

    return (
      <FormContext.Provider value={this.state}>
        <div className={`gt-form-wrapper ${classes}`}>
          <div className="gt-form">
            { children }
            { footer && typeof footer === 'function' && footer() }
          </div>
          { this.state.loading && <Loader /> }
          { this.renderFormMessages() }
        </div>
      </FormContext.Provider>
    );
  }
}
