import React from 'react';
import PropTypes from 'prop-types';

import { User } from '../../../../services/Services';
import { Form } from '../../_reusable/Form/Form';
import { FormFooter } from '../../_reusable/Form/FormFooter';
import { UsernameField } from './fields/UsernameField';
import { PasswordField } from './fields/PasswordField';
import { UserCommunicator } from '../../../../Communicators/Communicators';

export const LoginForm = ({ onForgotPass }) => {
  const onSubmit = async (model, formProps) => {
    try {
      const result = await UserCommunicator.login({...model});

      formProps.clearForm();
      formProps.setFormMessage('info', 'Uspešno ste se ulogovali na sajt.');

      User.login(result);

      return result;

    } catch (error) {
      formProps.setFormMessage('error', `Greška: ${error.getMessage()}`);
    }
  };

  const FORM_ACTIONS = [
    {
      type: 'link',
      label: 'Zaboravili ste lozinku?',
      action: onForgotPass,
    },
  ];

  const renderFooter = () => {
    return (
      <FormFooter
        actions={FORM_ACTIONS}
        onSubmit={onSubmit}
        submitLabel="Prijavi me"
      />
    );
  };

  return (
    <Form
      footer={renderFooter}
      customClass="login-form"
    >
      <UsernameField />
      <PasswordField onEnter={onSubmit} />
    </Form>
  );
};

LoginForm.propTypes = {
  onForgotPass: PropTypes.func,
};

LoginForm.defaultProps = {
  onForgotPass: f => f,
};
