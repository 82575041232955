import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import MomentUtils from "@date-io/moment";
import "moment/locale/sr";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as CONSTANTS from '../InputConstants';

import '../Input.scss';

const DatePickerField = ({
  id,
  fieldStyle,
  value,
  onChange,
  label,
  variant,
  disabled,
  minDate,
  maxDate,
  disableToolbar,
}) => {
  moment.locale('sr');

  const returnNakedDatePicker = () => null;

  const returnOutlinedDatePicker = () => (
    <DatePicker
      id={`date-picker-${id}`}
      autoOk
      // disableFuture
      animateYearScrolling
      label={label}
      format="L"
      inputVariant={CONSTANTS.FIELD_STYLE_OUTLINED}
      value={value}
      onChange={onChange}
      disabled={disabled}
      variant={variant}
      minDate={minDate}
      maxDate={maxDate}
      disableToolbar={disableToolbar}
    />
  );

  const renderField = () => {
    switch (fieldStyle) {
      case CONSTANTS.FIELD_STYLE_NAKED:
        return returnNakedDatePicker();
      case CONSTANTS.FIELD_STYLE_OUTLINED:
        return returnOutlinedDatePicker();
      default:
        return '';
    }
  };

  return (
    <div className={`gt-date-picker ${fieldStyle} ${!label ? 'no-label' : ''}`}>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        { renderField() }
      </MuiPickersUtilsProvider>
    </div>
  );
};

DatePickerField.propTypes = {
  id: PropTypes.string.isRequired,
  fieldStyle: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  variant: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  disableToolbar: PropTypes.bool,
};

DatePickerField.defaultProps = {
  fieldStyle: 'naked',
  label: '',
  value: '',
  variant: 'dialog',
  onChange: f => f,
  disabled: false,
  minDate: new Date('2000-01-01'),
  maxDate: new Date(),
  disableToolbar: false,
};

export { DatePickerField };
