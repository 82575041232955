import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { orderBy } from 'lodash';
import { CustomerCommunicator } from '../../../Communicators/Communicators';
import { Empty, EMPTY_TYPES } from '../../components/_reusable/Empty/Empty';
import { ShowMore } from '../../components/_reusable/ShowMore/ShowMore';
import { Loader } from '../../components/_reusable/Loader/Loader';
import { Button } from '../../components/_reusable/Button/Button';
import { InputField, FIELD_STYLE_OUTLINED } from '../../components/_reusable/Input/Input';
import { UserAccount } from '../../components/Admin/UserAccount/UserAccount';
import { UserActionDialog } from '../../components/Admin/UserActionDialog/UserActionDialog';
import { NewUserDialog } from '../../components/Admin/NewUserDialog/NewUserDialog';

import './Customers.scss';

const ACC_TO_SHOW = 50;

const Customers = ({ stopLoading }) => {
  const [customers, setCustomers] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [offset, setOffset] = useState(ACC_TO_SHOW);
  const [search, setSearch] = useState('');
  const [accountAction, setAccountAction] = useState(null);
  const [newUser, setNewUser] = useState(false);

  const filtered = search.length
    ? accounts.filter(acc => acc.customer.name.toLowerCase().includes(search.toLowerCase()))
    : accounts;

  const loadData = async () => {
    const customersList = await CustomerCommunicator.fetchCustomers();
    
    let accountsList = await CustomerCommunicator.fetchUsers();
    accountsList = accountsList.map(acc => ({ ...acc, customerName: acc.customer.name }));

    setCustomers(orderBy(customersList, ['name'], ['asc']));
    setAccounts(orderBy(accountsList, ['customerName'], ['asc']));
    stopLoading();
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderActions = () => (
    <div className="util-customers-actions">
      <div className="action-search">
        <InputField
          fieldStyle={FIELD_STYLE_OUTLINED}
          label="Pretraga po komitentu"
          value={search || ''}
          onChange={val => setSearch(val)}
        />
      </div>
      <div className="action-add-new">
        <Button
          customClass="add-new-btn"
          label="Dodaj korisnika"
          onClick={() => setNewUser(true)}
          disabled={false}
        />
      </div>
    </div>
  );

  const editUser = (data) => {
    setAccountAction({ edit: data });
  };

  const deleteUser = (data) => {
    setAccountAction({ delete: data });
  };

  const refreshAccountsList = (action, data) => {
    let fresh = JSON.parse(JSON.stringify(accounts));

    switch (action) {
      case 'add':
        fresh.push(data);
        fresh = orderBy(fresh, ['customerName'], ['asc']);
        break;
      case 'edit':
        const position = accounts.findIndex(acc => acc.id === data.id);
        fresh.splice(position, 1, data);
        break;
      case 'delete':
        fresh = accounts.filter(acc => acc.id !== data.id);
        break;
      default:
        break;
    }

    setAccounts(fresh);
  };

  const renderAccounts = () => (
    !filtered.length
      ? (<Empty type={EMPTY_TYPES.USER} text="Nema korisnika koji odgovaraju pretrazi." />)
      : filtered.slice(0, offset).map(acc => (
        <UserAccount
          key={acc.id}
          data={acc}
          onEdit={editUser}
          onDelete={deleteUser}
        />
      ))
  );

  const renderContents = () => {
    if (!accounts.length) {
      return <Empty type={EMPTY_TYPES.USER} text="Nema registrovanih korisnika." />;
    }

    return (
      <div className="util-customers">
        { renderActions() }
        { renderAccounts() }

        <UserActionDialog
          show={!!accountAction}
          action={accountAction}
          onActionDone={refreshAccountsList}
          onClose={() => setAccountAction(null)}
          customers={customers}
        />

        <NewUserDialog
          show={newUser}
          onActionDone={refreshAccountsList}
          onClose={() => setNewUser(false)}
          customers={customers}
        />

        <ShowMore
          items={filtered.length}
          toShow={offset}
          setToShow={productsNo => setOffset(productsNo)}
          increaseBy={ACC_TO_SHOW}
          text="Prikaži još korisnika"
        />
      </div>
    );
  };

  return (accounts && customers) ? renderContents() : null;
};

Customers.propTypes = {
  stopLoading: PropTypes.func.isRequired,
};

export { Customers };
