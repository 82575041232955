import React from 'react';
import PropTypes from 'prop-types';

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/src/core/styles.scss';
import AwesomeSliderStyles from 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

import './SlideShow.scss';

const AutoplaySlider = withAutoplay(AwesomeSlider);
const NO_OF_SLIDES = 3;

const SlideShow = ({
  customClass,
}) => {
  const displaySlides = () => {
    const slides = [];
    for (let i = 0; i < NO_OF_SLIDES; i++) {
      slides.push((
        <div key={`slide-${i}`}><div className={`slide slide-no-${i}`}></div></div>
      ));
    }
    return slides;
  };

  return (
    <div className={`gt-slide-show ${customClass}`}>
      <AutoplaySlider
        play={true}
        cancelOnInteraction={true} // should stop playing on user interaction
        interval={5000}
        cssModule={AwesomeSliderStyles}
      >
        { displaySlides() }
      </AutoplaySlider>
    </div>
  );
};

SlideShow.propTypes = {
  customClass: PropTypes.string,
};

SlideShow.defaultProps = {
  customClass: '',
};

export { SlideShow };