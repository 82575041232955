import moment from 'moment';
import { Storage } from './LocalStorage';
import { Config } from './Config';

export const STORAGE_USER_KEY = 'gtuser';

class UserService {
  login(value) {
    const session = {
      name: (value.customer && value.customer.length) ? value.customer[0].name : value.username,
      token: value.accessToken,
      roles: value.roles || [],
      timestamp: moment(),
      customer: value.customer,
      outlets: (value.customer && value.customer.length) ? value.customer[0].outlet : [],
      debt: (value.customer && value.customer.length) ? value.customer[0].debt : null,
    };

    Storage.set(STORAGE_USER_KEY, JSON.stringify(session));

    if (session.debt) {
      location.reload(true);
    } else if (session.roles.includes("ROLE_SUPER_ADMIN")){
      location.href = `${Config.get('BaseUrl')}/admin`;
    } else {
      location.href = Config.get('BaseUrl');
    }
  }

  logout() {
    Storage.delete(STORAGE_USER_KEY);
    location.reload(true);
  }

  loggedIn() {
    const gtuser = Storage.get(STORAGE_USER_KEY);
    return !!(gtuser && JSON.parse(gtuser)['token']);
  }

  get(what) {
    const gtuser = Storage.get(STORAGE_USER_KEY);

    if (!gtuser || !['name', 'token', 'timestamp', 'roles', 'outlets', 'debt'].includes(what)) {
      return null;
    }

    return JSON.parse(gtuser)[what];
  }

  getAccessToken() {
    return this.get('token');
  }

  hasRole(role) {
    const roles = this.get('roles');

    return !(!roles || !roles.includes(role));
  }

  isAdmin() {
    return this.hasRole("ROLE_SUPER_ADMIN");
  }

  checkSession() {
    const session = this.get('timestamp');
    if (!session) {
      return;
    }

    const difference = moment().diff(session, 'hours', true);
    if (Math.floor(difference) >= 24) {
      Storage.delete(STORAGE_USER_KEY);
    }
  }
}

export const User = new UserService();
