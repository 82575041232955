import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Field } from '../../../_reusable/Form/Field';
import { SelectField, FIELD_STYLE_OUTLINED } from '../../../_reusable/Input/Input';

const Subject = ({
  value, updateValue, name,
}) => {
  const options = [
    { value: 'Molba za ponudu', label: 'Molba za ponudu' },
    { value: 'Poslovni predlog', label: 'Poslovni predlog' },
    { value: 'Pitanje u vezi sajta', label: 'Pitanje u vezi sajta' },
  ];

  return (
    <SelectField
      fieldStyle={FIELD_STYLE_OUTLINED}
      label="Tema *"
      value={value || ''}
      options={options}
      onChange={e => updateValue(name, e.target.value)}
    />
  );
};

Subject.propTypes = {
  value: PropTypes.string,
  updateValue: PropTypes.func,
  name: PropTypes.string,
};

Subject.defaultProps = {
  value: null,
  updateValue: f => f,
  name: '',
};

export const SubjectField = () => (
  <Field
    name="subject"
    component={props => <Subject {...props} />}
    required
  />
);
