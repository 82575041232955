import React, { useEffect } from 'react';
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import moment from "moment";
import * as OrderActions from "../../../state/order/actions";
import { FieldSet, RadioGroupField, RadioField } from '../_reusable/Input/Input';

import './Delivery.scss';

const _DeliveryDates = ({
  deliveryDates, dayOffset, loadDeliveryDates, setDeliveryDayOffset, disabled, finished, overview,
}) => {
  useEffect(() => {
    loadDeliveryDates();
  }, []);

  useEffect(() => {
    if (deliveryDates.length) {
      setDeliveryDayOffset(deliveryDates[0].dayOffset);
    }
  }, [deliveryDates]);

  const displayValue = (val) => (
    finished ? overview : val
  );

  const setDayOffset = (e) => {
    const value = parseInt(e.target.value);
    setDeliveryDayOffset(value);
  };

  return (
    <div className="gt-delivery-dates">
      <FieldSet label="Datum isporuke" disabled={disabled || finished}>
        <RadioGroupField row name="position" value={displayValue(dayOffset)} onChange={setDayOffset}>
          { deliveryDates.map(item => (
            <RadioField
              key={item.timestamp}
              value={parseInt(item.dayOffset)}
              label={moment.unix(item.timestamp).format('D.M.YYYY')}
              disabled={disabled || finished}
            />
          ))}
        </RadioGroupField>
      </FieldSet>
    </div>
  );
};

_DeliveryDates.propTypes = {
  deliveryDates: PropTypes.array.isRequired,
  dayOffset: PropTypes.number.isRequired,
  loadDeliveryDates: PropTypes.func.isRequired,
  setDeliveryDayOffset: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  finished: PropTypes.bool,
  overview: PropTypes.number,
};

_DeliveryDates.defaultProps = {
  disabled: false,
  finished: false,
  overview: null,
};

const mapStateToProps = ({ order }, ownProps) => ({
  deliveryDates: order.deliveryDates,
  dayOffset: order.dayOffset,
  disabled: ownProps.disabled,
  finished: ownProps.finished,
  overview: ownProps.overview,
});

const mapDispatchToProps = dispatch => ({
  loadDeliveryDates: async () => dispatch(OrderActions.loadDeliveryDates()),
  setDeliveryDayOffset: async (dayOffsetValue) => dispatch(OrderActions.setDeliveryDayOffset(dayOffsetValue)),
});

const DeliveryDates = connect(mapStateToProps, mapDispatchToProps)(_DeliveryDates);

export { DeliveryDates }
