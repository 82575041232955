import React, { useState, useEffect } from 'react';

import { Carousel } from '../_reusable/Carousel/Carousel';

import './BrandsCarousel.scss';

const BrandsCarousel = () => {
  const [slides, setSlides] = useState(null);

  const onResize = (e) => {
    const width = window.innerWidth;

    if (width >= 900) {
      setSlides(10);
    } else if (width >= 750 && width < 900) {
      setSlides(8);
    } else if (width >= 500 && width < 750) {
      setSlides(7);
    } else if (width < 500) {
      setSlides(4);
    } else {
      setSlides(1);
    }
  };

  useEffect(() => {
    onResize();
  }, []);

  const renderBrands = () => {
    return Array(15).fill(null).map((brand, index) => (
      <div key={`brand-${index}`}>
        <span className="gt-brand" />
      </div>
    ));
  };

  return slides && (
    <div className="gt-brands">
      <Carousel
        className="gt-brands-carousel container"
        customClass="mp-bla"
        slidesToShow={slides}
        autoplay
        onResize={onResize}
      >
        { renderBrands() }
      </Carousel>
    </div>
  );
};

export { BrandsCarousel };
