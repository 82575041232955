import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Config } from '../../../../../services/Services';
import ReCAPTCHA from "react-google-recaptcha";
import { Field } from '../../../_reusable/Form/Field';

const Captcha = ({
  value, updateValue, name,
}) => {
  useEffect(() => {
    let unset = null;
    if (value) {
      unset = setTimeout(() => updateValue(name, null), 120000);
    }

    return () => {
      clearTimeout(unset);
    };
  }, [value]);

  return (
    <ReCAPTCHA
      sitekey={`${Config.get('gthRcapPub')}`}
      onChange={val => updateValue(name, val)}
    />
  );
};

Captcha.propTypes = {
  value: PropTypes.string,
  updateValue: PropTypes.func,
  name: PropTypes.string,
};

Captcha.defaultProps = {
  value: null,
  updateValue: f => f,
  name: '',
};

export const CaptchaField = () => (
  <Field
    name="g-recaptcha-response"
    component={props => <Captcha {...props} />}
    required
  />
);
