import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { User, Config, Format } from '../../../services/Services';
import { PageHeader } from '../../sections/PageHeader/PageHeader';
import { SimilarProducts } from '../../sections/SimilarProducts/SimilarProducts';
import { Loader } from '../../components/_reusable/Loader/Loader';
import { Empty, EMPTY_TYPES } from '../../components/_reusable/Empty/Empty';
import { Button } from '../../components/_reusable/Button/Button';
import { Transition } from '../../components/_reusable/Transition/Transition';
import { SectionHeader } from '../../components/SectionHeader/SectionHeader';
import { QuantitySetter } from '../../components/QuantitySetter/QuantitySetter';
import { SocialShare, SOCIAL_EMPTY_CONFIG } from '../../components/SocialShare/SocialShare';
import { ProductTags } from '../../components/ProductTags/ProductTags';
import { ProductFiles } from './components/ProductFiles/ProductFiles';

import './ProductPage.scss';
import {Snackbar} from "@material-ui/core";

const gtUrl = Config.get('BaseUrl');
const logged = User.loggedIn();

const prepareSocialShareConfig = (product) => ({
  ...SOCIAL_EMPTY_CONFIG,
  FACEBOOK: {
    quote: `${product?.name ?? ''} | ${product?.purpose ?? ''}`,
    hashtag: '#gthemija'
  },
  TWITTER: {
    title: `${product?.name ?? ''}`,
    via: 'GTHemija',
    hashtags: ['#gthemija'],
  },
  LINKEDIN: {
    title: `${product?.name ?? ''}`,
    summary: `${product?.purpose ?? ''}`,
    source: 'www.gthemija.com',
  },
  VIBER: {
    title: `${product?.name ?? ''}`,
  },
  WHATSAPP: {
    title: `${product?.name ?? ''}`,
  },
  EMAIL: {
    subject: `GT HEMIJA proizvod | ${product?.name ?? ''}`,
    body: `${product?.purpose ?? ''}`,
  },
});

const ProductPageComponent = ({
  product, loading, fetchProduct, addToCart, addFavorite, removeFavorite,
}) => {
  const NO_PICTURE_PATH = Config.get('noPicturePath');
  const ID = PRELOAD_DATA.product.id;
  const CATEGORY_ID = PRELOAD_DATA.product.categoryId;
  const SUBCATEGORY_ID = PRELOAD_DATA.product.subCategoryId;

  const [quantity, setQuantity] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [favoriting, setFavoriting] = useState(false);
  const [notify, setNotify] = useState(false);

  useEffect(() => {
    fetchProduct(ID, CATEGORY_ID, SUBCATEGORY_ID);
  }, []);

  useEffect(() => {
    if (notify) {
      setTimeout(() => setNotify(!notify), 2000);
    }
  }, [notify]);

  const {
    id,
    name,
    code,
    imagePath,
    price,
    priceWithVat,
    vat,
    stock,
    unit,
    categoryId,
    subCategoryId,
    purpose,
    warning,
    instructions,
    composition,
    favorite,
    minOrderQty,
  } = (product && !Array.isArray(product)) ? { ...product } : {};

  const updateQuantity = (val) => {
    let newQty = 0;

    if (val) {
      newQty = (val < 0) ? quantity : val;
    }
    setQuantity(newQty);
  };

  const addProductToCart = async () => {
    if (!processing && quantity && Number(quantity) !== 0) {
      setProcessing(true);
      await addToCart(id, quantity);
      setProcessing(false);
      setNotify(true);
      setQuantity(0);
    }
  };

  const handleFavorite = async () => {
    if (favoriting) { return; }

    setFavoriting(true);

    if (favorite) {
      await removeFavorite(id);
    } else {
      await addFavorite(id);
    }

    setFavoriting(false);
  };

  const renderBuyNotification = () => (
    <Transition type="collapse" customClass="product-info-notification" show={notify} >
      <div className="notification-body">
        <div className="notification-body-message">
          <span className="gt-icon gt-icon-info" />
          <span className="message">Uspešno ste dodali proizvod u korpu</span>
        </div>
      </div>
    </Transition>
  );

  const renderInfoItem = (itemClass, itemVal, itemLabel = null) => (
    <div className={`product-info-item ${itemClass}`} >
      { itemLabel && <span className="product-info-item-label">{`${itemLabel}`}</span> }
      { itemVal && <span className="product-info-item-value">{itemVal}</span> }
    </div>
  );

  const renderInfoItemPrice = () => (
    <div className="product-info-item price" >
      <span className="item-value without-vat">{Format.toCurrency(price)}</span>
      <span className="item-value with-vat">{`(Sa PDV: ${Format.toCurrency(priceWithVat)})`}</span>
    </div>
  );

  const renderMinOrderQty = () => (
      <div className={`product-info-min-order-qty`}>
        <span className={`gt-icon-warning`} />
        <span>
          Minimalna količina za poručivanje je {minOrderQty}
        </span>
      </div>
  );

  const renderAddToCart = () => (
      <div className="product-info-add-to-cart">
        <QuantitySetter
            customClass="product-info-add-to-cart-inputs"
            disabled={processing}
            quantity={quantity}
        updateQuantity={updateQuantity}
      />
      <Button
        customClass="add-to-cart-btn"
        label={(
          <Fragment>
            { !processing
              ? <span className="gt-icon gt-icon-add-to-cart"></span>
              : <Loader type="fadingCircle" />
            }
            <span className="gt-icon-label">Dodaj u korpu</span>
          </Fragment>
        )}
        onClick={addProductToCart}
        disabled={processing}
      />
      { renderBuyNotification() }
    </div>
  );

  const renderFavoriteSection = () => (
    <div className="product-info-favorite">
      <div
        role="button"
        className={`product-info-favorite-btn ${favoriting ? 'loading' : ''}`}
        onClick={handleFavorite}
      >
        { !favoriting
            ? <span className={`gt-icon gt-icon-${favorite ? 'broken-' : '' }heart`} />
            : <Loader type="fadingCircle" />
        }
        <span className="product-info-favorite-text">
          { favorite ? 'Ukloni iz omiljenog' : 'Dodaj u omiljeno' }
        </span>
      </div>
    </div>
  );

  const renderShareSection = () => (
    <div className="product-info-share">
      <span className="product-info-share-text">
        Podeli proizvod:
      </span>
      <SocialShare
        url={`${gtUrl}/product/${id}`}
        config={prepareSocialShareConfig(product)}
      />
    </div>
  );

  const formCategoryString = () => {
    const category = PRELOAD_DATA.categories.find(cat => cat.id === categoryId);
    const subcategoryHR = category.subcategories.find(cat => cat.id === subCategoryId).name

    return (
      <Fragment>
        <a href={`${gtUrl}/category/${categoryId}`}>{category.name}</a>
        <span> / </span>
        <a href={`${gtUrl}/sub-category/${subCategoryId}`}>{subcategoryHR}</a>
      </Fragment>
    );
  };

  const renderProductSpecifications = () => (
    <Fragment>
      { purpose && purpose.length && renderInfoItem('purpose-spec', purpose, 'Svrha upotrebe / dejstvo:') }
      { instructions && instructions.length && renderInfoItem('instructions-spec', instructions, 'Instrukcije za upotrebu:') }
      { composition && composition.length && renderInfoItem('composition-spec', composition, 'Sastav:') }
      { warning && warning.length && renderInfoItem('warning-spec', warning, 'Upozorenje:') }
    </Fragment>
  );

  const renderProduct = () => (
    <div className={`product-page__product ${!logged ? 'trimmed' : ''}`}>
      <div className="product-page__product-section-1">
        <div className="img-wrapper">
          <img src={imagePath || NO_PICTURE_PATH} alt="product image" />
          <ProductTags product={product} />
        </div>
        <div className="product-info">
          { renderInfoItem('name', name) }
          { logged && renderInfoItemPrice() }
          { logged && renderAddToCart() }
          { logged && minOrderQty && renderMinOrderQty() }
          { logged && renderFavoriteSection() }
          { renderInfoItem('category', formCategoryString(), 'Kategorija:') }
          { logged && code && renderInfoItem('code', code, 'Šifra:') }
          { logged && vat && renderInfoItem('vat', `${vat}%`, 'PDV:') }
          { logged && unit && renderInfoItem('unit', unit, 'Jedinica mere:') }
          { logged && purpose && purpose.length && renderInfoItem('purpose', purpose, 'Opis:') }

          { !logged && renderProductSpecifications() }
          { renderShareSection() }
        </div>
      </div>

      { logged && (
        <Fragment>
          <div className="product-page__product-section-2">
            <SectionHeader title="Specifikacija proizvoda" />
            { renderProductSpecifications() }
          </div>

          <ProductFiles id={id} customClass="product-page__product-section-3" />
        </Fragment>
      )}

      <div className="product-page__product-section-4">
        <SimilarProducts />
      </div>
    </div>
  );

  const renderContents = () => {
    return (product && !Array.isArray(product))
      ? renderProduct()
      : <Empty type={EMPTY_TYPES.SEARCH} text="Traženi proizvod ne postoji" />;
  };

  return (
    <div className="page product-page">
      <PageHeader
        name="product-page"
        title="Prikaz proizvoda"
      />
      { loading
        ? <Loader type="bouncingDots" customClass="products-loader" />
        : renderContents()
      }
    </div>
  );
};

ProductPageComponent.propTypes = {
  product: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool,
  fetchProduct: PropTypes.func,
  addToCart: PropTypes.func,
  addFavorite: PropTypes.func,
  removeFavorite: PropTypes.func,
};

ProductPageComponent.defaultProps = {
  product: null,
  loading: false,
  fetchProduct: f => f,
  addToCart: f => f,
  addFavorite: f => f,
  removeFavorite: f => f,
};

export { ProductPageComponent };
