import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Field } from '../../../_reusable/Form/Field';
import { InputField, FIELD_STYLE_OUTLINED } from '../../../_reusable/Input/Input';

const Name = ({
  value, updateValue, name,
}) => {
  return (
    <InputField
      fieldStyle={FIELD_STYLE_OUTLINED}
      label="Ime i prezime *"
      value={value || ''}
      onChange={val => updateValue(name, val)}
    />
  );
};

Name.propTypes = {
  value: PropTypes.string,
  updateValue: PropTypes.func,
  name: PropTypes.string,
};

Name.defaultProps = {
  value: null,
  updateValue: f => f,
  name: '',
};

export const NameField = () => (
  <Field
    name="name"
    component={props => <Name {...props} />}
    required
  />
);
