import React from 'react';
import PropTypes from 'prop-types';

import { User } from '../../../services/Services';

import './ProductTags.scss';

export const ProductTags = ({ product }) => {
  const logged = User.loggedIn();
  const { favorite, special } = product;

  return (
    <div className="gt-product-tags">
      <div className="gt-product-tags-left">
        { (logged && !!special) && <span className="special">Akcija</span> }
      </div>
      <div className="gt-product-tags-right">
        { (logged && favorite) && <span className="favorite">Omiljeno</span> }
      </div>
    </div>
  );
};

ProductTags.propTypes = {
  product: PropTypes.objectOf(PropTypes.any),
};

ProductTags.defaultProps = {
  product: {},
};
