import React, {useState, Fragment, useEffect} from 'react';
import { connect } from "react-redux";
import { Config, Format, User } from '../../../services/Services';
import { Transition } from '../_reusable/Transition/Transition';
import { ScrollBar } from '../_reusable/ScrollBar/ScrollBar';
import { Button } from '../_reusable/Button/Button';
import { Loader } from '../_reusable/Loader/Loader';
import * as CartActions from "../../../state/cart/actions";

import './MiniCart.scss';

const NO_PICTURE_PATH = Config.get('noPicturePath');

const _MiniCartWeb = ({
  contents,
  total,
  loading,
  updating,
  processing,
  loadCart,
  removeItem,
  clear,
}) => {
  const [show, setShow] = useState(false);
  const gtUrl = Config.get('BaseUrl');
  const loggedIn = User.loggedIn();
  const buttonNoGo = !!(updating.length || processing);
  const isLoading = loading || updating.length;

  useEffect(() => {
    if (loggedIn) {
      loadCart();
    }
  }, []);

  const toggleCart = () => setShow(!show);

  const renderCartItem = (item) => {
    const {productId, name, price, qty, imagePath} = item;
    const changing = updating.includes(productId);

    return (
      <li key={`product-${productId}`} className={`product ${changing ? 'changing' : ''}`}>
        <a href="#" title={name} className="product-image">
          <img src={imagePath || NO_PICTURE_PATH} alt="product image" />
        </a>
        <div className="product-details">
          <a href="#" className="product-details__name">{name}</a>
          <div className="product-details__amounts">
            <span>{qty}</span>
            <span>x</span>
            <span>{Format.toCurrency(price)}</span>
          </div>
        </div>
        <span
          role="button"
          className="product-remove gt-icon-delete"
          onClick={() => !buttonNoGo && removeItem(productId)}
        />

        { changing && <Loader type="stretchingBars" customClass="product-loader" /> }
      </li>
    );
  };

  const renderNoUserCart = () => (
    <div className="mini-cart__contents-no-user">
      <span>Morate biti ulogovani da biste videli ili menjali sadržaj korpe.</span>
    </div>
  );

  const renderEmptyCart = () => (
    <div className="mini-cart__contents-empty">
      <span>Vaša korpa je prazna</span>
    </div>
  );

  const clearCart = async () => {
    if (buttonNoGo) { return; }

    await clear();
    setShow(false);
  };

  const goToCart = () => {
    if (buttonNoGo) { return; }

    location.href = `${gtUrl}/cart`;
  };

  const renderContents = () => {
    if (!contents.length) {
      return renderEmptyCart();
    }

    return (
      <Fragment>
        <ScrollBar className="mini-cart__contents-list" scrollY>
          <ul>
            { contents.map(item => renderCartItem(item)) }
          </ul>
        </ScrollBar>
        <div className="mini-cart__contents-subtotal">
          <span>Ukupno:</span>
          <span>{Format.toCurrency(total)}</span>
        </div>
        <div className="mini-cart__contents-actions">
          <Button
            label="Korpa"
            onClick={goToCart}
          />
          <Button
            label="Obriši sve"
            onClick={clearCart}
          />
        </div>
      </Fragment>
    );
  };

  const renderCart = () => (
    <div className="mini-cart__contents">
      { loggedIn ? renderContents() : renderNoUserCart() }
    </div>
  );

  const renderCartIcon = () => (
    <div className="mini-cart">
      <div className="mini-cart__icon">
        <span className="gt-icon gt-icon-shopping-bag" />
        { loggedIn
          ? (
            <span className={`mini-cart__items ${isLoading ? 'loading' : ''}`}>
              { isLoading
                ? <Loader type="fadingCircle" />
                : contents.length
              }
            </span>
          )
          : ''
        }
      </div>
      <span className="mini-cart__text">Korpa</span>
    </div>
  );

  return (
    <div className="hu-item gt-mini-cart mini-cart-web" onMouseEnter={toggleCart} onMouseLeave={toggleCart}>
      { renderCartIcon() }
      <Transition type="collapse" customClass="mini-cart-panel" show={show} >
        { renderCart() }
      </Transition>
    </div>
  );
};

const mapStateToProps = ({ cart, order }) => ({
  contents: cart.contents,
  total: cart.total,
  loading: cart.loading,
  updating: cart.updating,
  processing: order.processing,
});

const mapDispatchToProps = dispatch => ({
  loadCart: async () => dispatch(CartActions.loadCart()),
  removeItem: async (productId) => dispatch(CartActions.removeItem(productId)),
  clear: async () => dispatch(CartActions.clear()),
});

const MiniCartWeb = connect(mapStateToProps, mapDispatchToProps)(_MiniCartWeb);

export { MiniCartWeb }
