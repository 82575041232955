import { connect } from 'react-redux';
import * as orderActions from '../../../state/order/actions';
import * as cartActions from '../../../state/cart/actions';

import { HistoryPageComponent } from './HistoryPageComponent';

const mapStateToProps = ({ order, cart }) => ({
  loading: order.loadingHistory,
  history: order.history,
  processing: cart.loading,
});

const mapDispatchToProps = dispatch => ({
  loadOrderHistory: () => dispatch(orderActions.loadOrderHistory()),
  loadCartFromOrder: (orderId) => dispatch(cartActions.loadFromOrder(orderId)),
});

const HistoryPage = connect(mapStateToProps, mapDispatchToProps)(HistoryPageComponent);

export { HistoryPage };
