import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import './Loader.scss';

const Loader = ({
  type,
  customClass,
}) => {
  const renderItems = (itemClass, itemsNo) => {
    const items = [...Array(itemsNo)];

    return items.map((item, index) => (
      <div key={`item-${index}`} className={`${itemClass} ${itemClass}-${index + 1}`} />
    ));
  };

  const renderLoader = () => {
    switch (type) {
      case 'fadingCircle':
        return renderItems('circle', 12);
      case 'bouncingDots':
        return renderItems('bounce', 3);
      case 'stretchingBars':
        return renderItems('rect', 5);
      default:
        return '';
    }
  };

  return (
    <div className={`gt-loader ${customClass}`}>
      <div className={`spinner ${type}`}>
        { renderLoader() }
      </div>
    </div>
  );
};

Loader.propTypes = {
  type: PropTypes.string,
  customClass: PropTypes.string,
};

Loader.defaultProps = {
  type: 'fadingCircle',
  customClass: '',
};

export { Loader };