import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Dialog } from '../../_reusable/Dialog/Dialog';
import { Button } from '../../_reusable/Button/Button';
import { CustomerCommunicator } from '../../../../Communicators/Communicators';
import { AccountForm } from '../../_forms/AccountForm/AccountForm';

import './NewUserDialog.scss';

const DIALOG_TITLE = {
  edit: 'Izmena podataka korisnika',
  delete: 'Brisanje korisnika',
};

const NewUserDialog = ({
  show,
  onActionDone,
  onClose,
  customers,
}) => {
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(null);

  const onCloseDialog = () => {
    if (processing) { return; }

    onClose();
    setSuccess(null);
  };

  const dialogAction = (
    <div className="dialog-action">
      <Button
        customClass="close-dialog-btn"
        label="Zatvori"
        onClick={() => onCloseDialog()}
        disabled={processing}
      />
    </div>
  );

  const renderSuccessMessage = () => (
    <Fragment>
      <span className="success">
        Uspešno ste dodali novog korisnika.
      </span>
      { dialogAction }
    </Fragment>
  );

  const renderErrorMessage = () => (
    <Fragment>
      <span className="error">
        Akcija nije uspela! Zatvorite prozor i pokušajte ponovo.
      </span>
      { dialogAction }
    </Fragment>
  );

  const renderMessage = () => (
    success ? renderSuccessMessage() : renderErrorMessage()
  );

  const onAddComplete = (status, result) => {
    if (status === 'error') {
      setSuccess(false);
    } else {
      setSuccess(true);
      onActionDone('add', result);
    }
    setProcessing(false);
  };

  return (
    <Dialog
      title="Dodavanje novog korisnika"
      show={show}
      onClose={onCloseDialog}
    >
      <div className="gt-new-user-dialog">
        { success === null
          ? (
            <AccountForm
              customers={customers}
              onSubmitClick={() => setProcessing(true)}
              onComplete={onAddComplete}
            />
          )
          : renderMessage()
        }
      </div>
    </Dialog>
  );
};

NewUserDialog.propTypes = {
  show: PropTypes.bool,
  onActionDone: PropTypes.func,
  onClose: PropTypes.func,
  customers: PropTypes.arrayOf(PropTypes.any),
};

NewUserDialog.defaultProps = {
  show: false,
  onActionDone: f => f,
  onClose: f => f,
  customers: [],
};

export { NewUserDialog };
