import React from 'react';
import PropTypes from 'prop-types';

import MuiTooltip from '@material-ui/core/Tooltip';

export const TooltipPlacements = Object.freeze({
  TOP: 'top',
  TOP_START: 'top-start',
  TOP_END: 'top-end',
  LEFT: 'left',
  LEFT_START: 'left-start',
  LEFT_END: 'left-end',
  RIGHT: 'right',
  RIGHT_START: 'right-start',
  RIGHT_END: 'right-end',
  BOTTOM: 'bottom',
  BOTTOM_START: 'bottom-start',
  BOTTOM_END: 'bottom-end',
});

export const Tooltip = ({
  title,
  placement,
  children,
}) => {
  const getValidPlacement = placement => (
    Object.keys(TooltipPlacements)
      .reduce((validPlacement, placementKey) => (
        TooltipPlacements[placementKey] === placement
          ? placement
          : validPlacement
        ), TooltipPlacements.BOTTOM)
    );

  return (
    <MuiTooltip title={title} placement={getValidPlacement(placement)} arrow>
      { children }
    </MuiTooltip>
  );
};

Tooltip.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.node,
};


Tooltip.defaultProps = {
  title: '',
  placement: 'bottom',
  children: null,
};