import { Base } from './Base';
import { http } from "../services/HttpService";

class _ProductCommunicator extends Base {
  constructor() {
    super('/product');
  }

  async getProductsBySearch({searchQuery,   limit,   categoryId,   subCategoryId,   favoriteBy,   random}) {
    const body = {searchQuery,   limit,   categoryId,   subCategoryId,   favoriteBy,   random};
    return await http.jsonPost(`${this.url}/search`, body);
  }

  async getSimilarProducts(similarTo, categoryId, subCategoryId, limit) {
    const body = {
      similarTo,
      categoryId,
      subCategoryId,
      limit,
      random: true,
    };

    return await http.jsonPost(`${this.url}/search`, body);
  }

  async getSpecialOffer() {
    const body = {
      random: true,
      special: true,
    };

    return await http.jsonPost(`${this.url}/search`, body);
  }

  async getProductsByCategoryId(id) {
    return await http.get(`${this.url}/category/${id}`);
  }

  async getProductsBySubCategoryId(id) {
    return await http.get(`${this.url}/sub-category/${id}`);
  }

  async getProduct(id) {
    return await http.get(`${this.url}/${id}`);
  }

  async getFavoriteProducts() {
    return await http.get(`${this.url}/favorites`);
  }

  async addToFavorites(productId) {
    return await http.jsonPost(`${this.url}/favorites`, {productId});
  }

  async removeFromFavorites(productId) {
    return await http.jsonDelete(`${this.url}/favorites`, {}, {productId});
  }

  async getFiles(productId) {
    return await http.get(`${this.url}/${productId}/attachment`);
  }

  async uploadFile(productId, fileObj) {
    return await http.jsonPost(`${this.url}/${productId}/attachment`, fileObj);
  }

  async removeFile(fileId) {
    return await http.delete(`${this.url}/attachment/${fileId}`);
  }

  async downloadFile(fileId) {
    return await http.downloadGet(`${this.url}/attachment/${fileId}/download`);
  }
}

export const ProductCommunicator = new _ProductCommunicator();
