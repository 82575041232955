import React from 'react';
import ScrollUp from 'react-scroll-up';

import './ScrollToTop.scss';

const style = {
  position: 'fixed',
  bottom: 15,
  right: 15,
  cursor: 'pointer',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'linear',
  transitionDelay: '0s'
};

export const ScrollToTop = () => (
  <div className="gt-scroll-to-top">
    <ScrollUp showUnder={160} style={style}>
      <span className="gt-icon gt-icon-circle-arrow-up" />
    </ScrollUp>
  </div>
);
