import * as types from './types';

const initialState = {
  loading: false,
  updating: [],
  contents: [],
  total: 0,
  totalWithoutVat: 0,
  totalVat: 0,
};

const initialStateGenerator = () => JSON.parse(JSON.stringify(initialState));

export default (state = initialStateGenerator(), action) => {
  switch (action.type) {
    case types.CART_LOAD:
      return {...state, ...action.payload};
    case types.CART_LOADING:
      return {...state, loading: action.payload};
    case types.CART_UPDATING:
      return {...state, updating: action.payload};
    case types.CART_RESET:
      return initialStateGenerator();
    default:
      return state;
  }
};