import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { Dialog } from '../../../../components/_reusable/Dialog/Dialog';
import { Button } from '../../../../components/_reusable/Button/Button';
import { Loader } from '../../../../components/_reusable/Loader/Loader';

import './FileUpload.scss';

const useStyles = makeStyles(theme => createStyles({
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
}));

const filesToUpload = files => files.map((doc) => {
  const { data, file } = doc;
  const fileBase = data.split('base64,').pop();

  return {
    title: file.name,
    mimeType: file.type,
    base64: fileBase,
  };
});

const FILE_TYPES = [
  'image/*',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.rar',
  'application/zip',
].join(', ');

export const FileUpload = ({ open, closeDialog, upload }) => {
  const [fileObjects, setFileObjects] = React.useState([]);
  const [processing, setProcessing] = React.useState(false);
  const classes = useStyles();

  const onClose = () => {
    setFileObjects([]);
    closeDialog();
  };

  const onFilesAdd = newFiles => setFileObjects(prev => [...prev, ...newFiles]);

  const onFileDelete = file => setFileObjects(prev => prev.filter(doc => doc !== file));

  const onFilesSave = async () => {
    try {
      setProcessing(true);
      await upload(filesToUpload(fileObjects));
      setProcessing(false);
      onClose();
    } catch (e) {
      throw new Error(e);
    }
  };

  const dialogAction = () => (
    <>
      <Button
        label="Odustani"
        variant="outlined"
        onClick={onClose}
        disabled={processing}
      />
      <Button
        label="Dodaj"
        onClick={onFilesSave}
        disabled={!fileObjects.length || processing}
      />
    </>
  );

  return (
    <Dialog
      title="Dodavanje novih dokumenata"
      show={open}
      onClose={onClose}
      customFooter={dialogAction}
      customClass="gt-upload-dialog"
      disableBackdropClick
    >
      <div className="upload-content">
        <DropzoneAreaBase
          dropzoneClass="gt-files-upload"
          dropzoneParagraphClass="gt-dropzone-text"
          acceptedFiles={[FILE_TYPES]}
          fileObjects={fileObjects}
          maxFileSize={5000000}
          onAdd={onFilesAdd}
          onDelete={onFileDelete}
          showPreviews={true}
          showFileNamesInPreview={true}
          showAlerts={false}
          dropzoneText="Prevucite fajlove ili kliknite da biste dodali nove dokumente."
          useChipsForPreview
          showPreviewsInDropzone={false}
          previewChipProps={{classes: { root: classes.previewChip } }}
          dropzoneProps={{ disabled: processing }}
          filesLimit={20}
        />
        {processing && (
          <div className="ongoing-upload">
            <Loader type="fadingCircle" />
            <span>Fajlovi se snimaju...</span>
          </div>
        )}
      </div>
    </Dialog>
  );
};

FileUpload.propTypes = {
  open: PropTypes.bool,
  closeDialog: PropTypes.func,
  upload: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
  open: false,
  closeDialog: f => f,
};
