import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { orderBy } from 'lodash';
import moment from 'moment';
import { User, Format } from '../../../services/Services';
import { PageHeader, DEFAULT_OPTIONS, AVAILABLE_FILTERS } from '../../sections/PageHeader/PageHeader';
import { OptionsService } from '../../sections/PageHeader/options/Option';
import { ShowMore } from '../../components/_reusable/ShowMore/ShowMore';
import { Loader } from '../../components/_reusable/Loader/Loader';
import { Button } from '../../components/_reusable/Button/Button';
import { Accordion } from '../../components/_reusable/Accordion/Accordion';
import { Empty, EMPTY_TYPES } from '../../components/_reusable/Empty/Empty';

import './HistoryPage.scss';

const NO_ITEMS_PER_PAGE = 10;

const ORDER_STATUSES = {
  0: { id: 'unknown', label: 'Nepoznato' },
  1: { id: 'created', label: 'Kreirano' },
  2: { id: 'altered', label: 'Izmenjeno' },
  3: { id: 'preparing', label: 'Priprema' },
  4: { id: 'packing', label: 'Pakuje se' },
  5: { id: 'packed', label: 'Spakovano' },
  6: { id: 'ready', label: 'Spremno' },
  7: { id: 'transport', label: 'Transport' },
  8: { id: 'delivered', label: 'Isporučeno' },
  9: { id: 'canceled', label: 'Stornirano' },
  10: { id: 'deleted', label: 'Obrisano' },
  11: { id: 'realized', label: 'Realizovano' },
};

const loggedIn = User.loggedIn();

const OUTLETS = loggedIn
  ? User.get('outlets').reduce((model, outlet) => {
      model[outlet.id] = `${outlet.name}, ${outlet.address}, ${outlet.city}`;
      return model;
    }, {})
  : null;

const HistoryPageComponent = ({
  loading, history, processing, loadOrderHistory, loadCartFromOrder,
}) => {
  const [repeating, setRepeating] = useState(null);

  const [display, setDisplay] = useState({
    ...DEFAULT_OPTIONS,
    content: history,
    offset: NO_ITEMS_PER_PAGE,
    chunk: NO_ITEMS_PER_PAGE,
  });

  useEffect(() => {
    if (!loggedIn) { return; }
    loadOrderHistory();
  }, []);

  useEffect(() => {
    setDisplay({ ...display, content: history });
  }, [history]);

  useEffect(() => {
    if (!processing) {
      setRepeating(null);
    }
  }, [processing]);

  const processedContent = OptionsService.apply(display.content, display);

  const updateDisplayedItemsNo = (itemsNo) => setDisplay({
    ...display,
    offset: itemsNo,
  });

  const renderHeaderItem = (customClass, label, value) => {
    const val = value ? value : '---';

    return (
      <div className={`heading-item ${customClass}`}>
        <span className="heading-item__label">{label}</span>
        <span className="heading-item__value">{val}</span>
      </div>
    );
  };

  const prepareHeading = order => (
    <Fragment>
      <div className="heading-data">
        { renderHeaderItem('date', 'Datum narudžbine', moment.unix(order.createdAt).format('D. M. YYYY.')) }
        { renderHeaderItem('delivery', 'Datum isporuke', moment.unix(order.deliveryDate).format('D. M. YYYY.')) }
        { renderHeaderItem('outlet', 'Objekat', OUTLETS[order.outletId]) }
      </div>
      { renderHeaderItem(`status ${ORDER_STATUSES[order.statusId].id}`, 'Status', ORDER_STATUSES[order.statusId].label) }
    </Fragment>
  );

  const renderTotals = (order) => {
    const totals = order.reduce((model, item) => {
      model.price += item.price * item.qty;
      model.priceWithVat += item.priceWithVat * item.qty;
      return model;
    }, { price: 0, priceWithVat: 0});

    return (
      <div key="order-totals" className="body-data-item totals">
        <div className="totals-item">
          <span>Osnovica:</span>
          <span>{ Format.toCurrency(totals.price) }</span>
        </div>
        <div className="totals-item">
          <span>PDV:</span>
          <span>{ Format.toCurrency(totals.priceWithVat - totals.price) }</span>
        </div>
        <div className="totals-item">
          <span>Ukupno:</span>
          <span>{ Format.toCurrency(totals.priceWithVat) }</span>
        </div>
      </div>
    );
  };

  const prepareBody = (order, orderId) => {
    const inProgress = (repeating === orderId) ? 'in-progress' : '';

    return (
      <div className={`body-data ${inProgress}`}>
        { order.map(item => (
          <div key={item.productId} className="body-data-item">
            { item.name && renderHeaderItem('title', 'Naziv', item.name) }
            <div className="body-data-item-rest">
              { item.code && renderHeaderItem('code', 'Šifra', item.code) }
              { item.price && renderHeaderItem('price', 'Cena', Format.toCurrency(item.price)) }
              { item.priceWithVat && renderHeaderItem('price-with-vat', 'Cena sa PDV', Format.toCurrency(item.priceWithVat)) }
              { item.qty && renderHeaderItem('qty', 'Količina', item.qty) }
            </div>
          </div>
        ))}
        { renderTotals(order) }

        { repeating && <Loader type="stretchingBars" customClass="repeating-loader" /> }
      </div>
    );
  };

  const prepareActions = order => (
    <div className="body-actions">
      <Button
        label="Ponovi sadržaj korpe"
        size="small"
        onClick={() => {
          if (!processing) {
            loadCartFromOrder(order.id);
            setRepeating(order.id);
          }
        }}
      />
    </div>
  );

  const prepareHistory = () => {
    const sorted = orderBy(processedContent, ['createdAt'], ['desc']);

    return sorted.slice(0, display.offset).map(order => ({
      key: order.id,
      label: prepareHeading(order),
      body: prepareBody(order.items, order.id),
      actions: prepareActions(order),
    }));
  };

  const renderContents = () => {
    const { offset, chunk } = display;

    if (!display.content.length) {
      return <Empty type={EMPTY_TYPES.HISTORY} text="Vaša istorija naručivanja je trenutno prazna" />;
    }

    return  processedContent.length
      ? (
        <Fragment>
          <Accordion
            customClass="order-history"
            content={prepareHistory()}
          />
          <ShowMore
            items={processedContent.length}
            toShow={offset}
            setToShow={updateDisplayedItemsNo}
            increaseBy={chunk}
            text="Prikaži još porudžbina"
          />
        </Fragment>
      )
      : <Empty type={EMPTY_TYPES.HISTORY} text="Nema traženih porudžbina" />;
  };

  const pageOptions = {
    items: [
      AVAILABLE_FILTERS.PAGINATION,
      AVAILABLE_FILTERS.ORDER_STATUS,
      AVAILABLE_FILTERS.DATE_RANGE,
    ],
    custom: {
      [AVAILABLE_FILTERS.PAGINATION]: 'Porudžbina po strani',
      [AVAILABLE_FILTERS.DATE_RANGE]: 'Datum porudžbine',
    },
  };

  return (
    <div className="page history-page">
      { loggedIn
        ? (
          <Fragment>
            <PageHeader
              name="history-page"
              title="Istorija kupovine"
              display={display}
              updateDisplay={setDisplay}
              disabled={loading}
              options={pageOptions}
            />
            { loading 
              ? <Loader type="bouncingDots" customClass="history-loader" />
              : renderContents()
            }
          </Fragment>
        )
        : <Empty type={EMPTY_TYPES.AUTH} text="Molimo, ulogujte se" />
      }
    </div>
  );
};

HistoryPageComponent.propTypes = {
  loading: PropTypes.bool,
  history: PropTypes.arrayOf(PropTypes.any),
  loadOrderHistory: PropTypes.func,
  loadCartFromOrder: PropTypes.func,
};

HistoryPageComponent.defaultProps = {
  loading: false,
  history: null,
  loadOrderHistory: f => f,
  loadCartFromOrder: f => f,
};

export { HistoryPageComponent };
