import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Field } from '../../../_reusable/Form/Field';
import { SelectField, FIELD_STYLE_OUTLINED } from '../../../_reusable/Input/Input';

const DEFAULT_VALUE = {
  value: 'no-first',
  label: 'Molimo, odaberite početni karakter naziva komitenta.',
};

const Customer = ({
  value, updateValue, name, customers, model,
}) => {
  const [options, setOptions] = useState([]);
  const [filtered, setFiltered] = useState([DEFAULT_VALUE]);
  const [initialLoad, setInitialLoad] = useState(true);

  const loadCustomers = () => {
    if (!customers.length) { return; }

    const toSet = customers.map(customer => ({
      value: customer.id,
      label: customer.name,
      first: customer.name.substring(0, 1).toUpperCase(),
    }));

    setOptions(toSet);
  };

  const filterCustomers = (letter) => {
    if (!model || !model.first || !customers.length) { return; }

    const trimmed = [...options].filter(opt => opt.first === letter);

    setFiltered(trimmed);

    if(initialLoad) {
      setInitialLoad(false);
    } else {
      updateValue(name, null);
    }
  };

  useEffect(() => {
    loadCustomers();
  }, []);

  useEffect(() => {
    filterCustomers(model.first);
  }, [model?.first]);

  const onChange = (val) => {
    if (val === 'no-first') { return; }

    updateValue(name, val);
  }

  const getValue = () => (
    value && filtered.length && !(filtered[0].value === 'no-first')
      ? value
      : ''
  );

  return (
    <SelectField
      fieldStyle={FIELD_STYLE_OUTLINED}
      label="Komitent *"
      value={getValue()}
      onChange={e => onChange(e.target.value)}
      options={filtered}
    />
  );
};

Customer.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateValue: PropTypes.func,
  name: PropTypes.string,
  model: PropTypes.objectOf(PropTypes.any),
  customers: PropTypes.arrayOf(PropTypes.any).isRequired,
};

Customer.defaultProps = {
  value: null,
  updateValue: f => f,
  name: '',
  model: {},
};

export const CustomerField = ({ customers }) => (
  <Field
    name="customerId"
    component={props => <Customer customers={customers} {...props} />}
    required
  />
);

CustomerField.propTypes = {
  customers: PropTypes.arrayOf(PropTypes.any).isRequired,
};
